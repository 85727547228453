import React, { Component, useState, useEffect } from "react";
import "./AddPayment.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchNextCustomerPaymentAmount,
  getManagerOtp,
  applyCustomerCashPayment,
  addPartnerInvoiceNumber,
} from "../../../actions/general";
import {
  fetchCourses,
  fetchFeeStructure,
} from "../../../actions/editFeeAction";

const style = {
  position: "absolute",
  top: "10%",
  left: "20%",
  transform: "translate(0%, -8%)",
  width: "60%",
  height: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const OPS_LEVEL_ONE = 21,
  OPS_LEVEL_TWO = 22,
  OPS_LEVEL_THREE = 23,
  OPS_LEVEL_FOUR = 24;
let AddPayment = ({ open, closeModal, ...props }) => {
  console.log({ props });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");

  useEffect(() => {
    console.log("componentDidMount");
  }, []);
  let addPartnerInvoice = async () => {
    console.log("addPartnerInvoice");
    setLoading(true);
    let { success, message, ...data } = await addPartnerInvoiceNumber({
      invoiceNumber,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Success! The payment has been recorded.");
    return closeModal();
  };

  return (
    <Modal
      open={open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="AddPayment">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row">
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Add new partner payment invoice
              </Typography>
            </div>

            <TextField
              id="outlined-basic"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              label="Invoice Number"
              variant="outlined"
            />
            <div className="buttonRow">
              <Button
                variant="contained"
                onClick={addPartnerInvoice}
                disableRipple
                color="primary"
                disabled={!invoiceNumber}
              >
                {" "}
                Add{" "}
              </Button>
              <Button
                variant="contained"
                onClick={closeModal}
                disableRipple
                color="secondary"
              >
                {" "}
                Cancel{" "}
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment);
