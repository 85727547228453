import styles from "./sendCustomPayemntLink.scss";
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { PAYMENT_ENDPOINT } from "../../constants";

const style = {
  position: "absolute",
  top: "25%",
  left: "35%",
  transform: "translate(0%, -8%)",
  maxWidth: "35%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SendCustomPaymentLink = ({ open, closeModal, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const [copied, setCopied] = useState(false);
  const [amount, setAmount] = useState();
  const [packageSelected, setPackageSelected] = useState(null);
  const [copyLink, setCopyLink] = useState(false);

  useEffect(() => {
    let link = `${PAYMENT_ENDPOINT}/pay?mode=installment&c_am=${amount}&curr=${
      packageSelected ? packageSelected.curr : ""
    }&crs_pg_id=${props.course._id}&name=${props.name}&email=${
      props.accountDetails.ep
    }${props.accountDetails.m ? `&phone=${props.accountDetails.m}` : ""}`;
    setLink(link);
  }, [amount, packageSelected]);

  //5 Sec Timeout
  const handleGenerateLink = () => {
    if (!packageSelected) {
      return alert("Please select a currency");
    }
    if (!amount || amount < (packageSelected.min_token_amount || 0)) {
      return alert(
        `Minimum amount that can be collected via token payment for ${packageSelected.curr} currency for this course is ${packageSelected.min_token_amount}`
      );
    }
    if (amount > packageSelected.price) {
      return alert(
        `Maximum amount that can be collected via token payment for ${packageSelected.curr} currency for this course is ${packageSelected.price}`
      );
    }
    setCopyLink(true);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className={
          !copyLink ? "CustomInstallment" : "CustomInstallment_copylink"
        }
      >
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!copyLink ? (
              <>
                <div className="row">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: "600" }}
                  >
                    Send Custom Instalment Payment Link
                  </Typography>
                </div>
                <div className="container">
                  <TextField
                    id="name"
                    value={props.name}
                    label="User Name"
                    disabled={true}
                    variant="outlined"
                    className="username"
                  />
                  <TextField
                    id="course"
                    value={props.course.name}
                    label="Course Name"
                    disabled={true}
                    variant="outlined"
                  />
                  <div className="amount" style={{ display: "flex" }}>
                    <Autocomplete
                      className="enteramount"
                      multiple={false}
                      disablePortal
                      disableClearable
                      sx={{ width: 150 }}
                      autoHighlight
                      value={packageSelected}
                      onChange={(ev, pkg) => {
                        setPackageSelected(pkg);
                      }}
                      getOptionLabel={(pkg) => {
                        return pkg?.curr || "";
                      }}
                      id="pkgSelector"
                      options={props.packages}
                      renderInput={(params) => {
                        return (
                          <TextField
                            label="Currency"
                            {...params}
                            variant="outlined"
                          />
                        );
                      }}
                    />
                    <TextField
                      className="curr"
                      id="amount"
                      value={amount}
                      type="number"
                      onChange={(e) => setAmount(e.target.value)}
                      label="Amount"
                      variant="outlined"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <button className="cancel" onClick={closeModal}>
                      Cancel
                    </button>
                    <button className="generate" onClick={handleGenerateLink}>
                      Generate Link
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <Typography
                    id="modal-modal-title"
                    variant="h5"
                    component="h2"
                    style={{ fontWeight: "600" }}
                  >
                    Copy Payment Link
                  </Typography>
                </div>
                <div className="container">
                  <TextField
                    id="link"
                    value={link}
                    label="Payment Link"
                    disabled={true}
                    variant="outlined"
                    className="username"
                  />
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={() => {
                        setCopyLink(false);
                        setCopied(false);
                      }}
                      className="cancel"
                    >
                      Back
                    </button>
                    <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
                      <button className="generate">Copy Link</button>
                    </CopyToClipboard>
                  </div>
                  {copied ? (
                    <span
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "18px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Copied!
                    </span>
                  ) : null}
                </div>
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default SendCustomPaymentLink;
