import React, { Component, useState, useEffect } from "react";
import "./StartMposPayment.scss";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchNextCustomerPaymentAmount,
  startMposPayment,
} from "../../../actions/general";
import {
  fetchCourses,
  fetchFeeStructure,
} from "../../../actions/editFeeAction";

const style = {
  position: "absolute",
  top: "10%",
  left: "10%",
  transform: "translate(0%, -8%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
let StartMposPayment = ({
  open,
  transaction,
  user,
  paymentMethodTypeMap,
  ...props
}) => {
  console.log({ transaction, user, props });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [expectedAmount, setExpectedAmount] = useState(0);
  const [latestPackage, setLatestPackage] = useState(null);
  const [startButtonDisabled, setStartButtonDisabled] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    console.log("componentDidMount");
    console.log({ transaction });
    props.fetchCourses();
    let packageFound = transaction.updated_package;
    // if (transaction.status != "SUCCESS") {
    let paymentTypesTemp = [...paymentTypes];
    if (
      transaction.status != "SUCCESS" &&
      !(
        transaction.full_trxn_id ||
        transaction.installmentPlanId ||
        transaction.emiPlanId
      )
    )
      paymentTypesTemp.push({ name: paymentMethodTypeMap[1], value: 1 });
    if (
      transaction.status != "SUCCESS" &&
      packageFound.reg_price &&
      !transaction.reg_trxn_id &&
      !transaction.full_trxn_id
    )
      paymentTypesTemp.push({ name: paymentMethodTypeMap[4], value: 4 });
    if (
      transaction.status != "SUCCESS" &&
      packageFound.app_fees &&
      !transaction.application_trxn_id &&
      !transaction.full_trxn_id
    )
      paymentTypesTemp.push({ name: paymentMethodTypeMap[5], value: 5 });
    if (transaction.installmentPlanId && !transaction.full_trxn_id) {
      console.log({ transaction });
      console.log({
        "transaction.updated_package": transaction.updated_package,
        "transaction.updated_package.installmentPlans":
          transaction.updated_package.installmentPlans,
      });
      let index = transaction.updated_package.installmentPlans?.findIndex(
        (plan) => plan._id == transaction.installmentPlanId
      );
      var plan;
      console.log({ index });
      if (index != -1)
        plan = transaction.updated_package.installmentPlans[index];
      if (
        transaction.status != "SUCCESS" &&
        plan &&
        transaction.installment_trxn_ids.length < plan.installments.length
      )
        paymentTypesTemp.push({ name: paymentMethodTypeMap[2], value: 2 });
    }
    if (transaction.emiPlanId && !transaction.full_trxn_id) {
      let index = transaction.updated_package.emiPlans?.findIndex(
        (plan) => plan._id == transaction.emiPlanId
      );
      var plan;
      if (index != -1) plan = transaction.updated_package.emiPlans[index];
      if (
        transaction.status != "SUCCESS" &&
        plan &&
        transaction.emi_trxn_ids.length < plan.emis.length
      )
        paymentTypesTemp.push({ name: paymentMethodTypeMap[3], value: 3 });
    }
    console.log({ paymentTypesTemp });
    setPaymentTypes(paymentTypesTemp);
    // }
  }, []);
  useEffect(() => {
    console.log({ props });
    if (props.editfee?.coursePackages) {
      let packageIndex = props.editfee?.coursePackages.findIndex(
        (el) => el._id == transaction.updated_package._id
      );
      console.log({
        latestPackage: props.editfee?.coursePackages[packageIndex],
      });
      setLatestPackage(props.editfee?.coursePackages[packageIndex]);
    }
  });
  useEffect(() => {
    if (startButtonDisabled)
      setInterval(() => {
        setStartButtonDisabled(false);
      }, 30000);
  }, [startButtonDisabled]);
  let fetchAmount = async () => {
    setLoading(true);
    let { success, message, ...data } = await fetchNextCustomerPaymentAmount({
      trxnId: transaction._id,
      aid: user._id,
      paymentType: paymentType.value,
      installmentPlanId: transaction.installmentPlanId,
      emiPlanId: transaction.emiPlanId,
    });
    setLoading(false);
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    let { amount, branch_collected } = data;
    setExpectedAmount(Number(amount));
    // setBranchCollected(branch_collected);
    // alert("Success! The payment has been applied to the invoice.");
  };
  let startPayment = async () => {
    console.log("startPayment");
    if (!selectedDevice?._id)
      return alert("Please select an Mpos Device to send prompt to.");
    setLoading(true);
    setStartButtonDisabled(true);
    let { success, message, ...data } = await startMposPayment({
      trxnId: transaction._id,
      aid: user._id,
      paymentType: paymentType.value,
      amount: expectedAmount,
      installmentPlanId: transaction.installmentPlanId,
      emiPlanId: transaction.emiPlanId,
      deviceId: selectedDevice?._id,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    let successAlertString = `Success! The payment has been recorded. The user can expect confirmation${
      branchCollected ? " and invoice" : ""
    } on his registered email Id.`;
    console.log({ successAlertString });
    alert(successAlertString);
    props.closeModal();
  };
  let refresh = async () => {
    props.refresh();
  };

  return (
    <Modal
      open={open}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="StartMposPayment">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Mark Transaction as Paid Undeposited Cash
              </Typography>

              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">User</TableCell>
                      <TableCell>{user?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Course</TableCell>
                      <TableCell>{transaction?.courseName}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell variant="head">Payment Type</TableCell> */}
                      {/* <TableCell>
                        {transaction?.installment_ids.length > 0
                          ? "Installments"
                          : "Full Payment"}
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Total Payable Amount</TableCell>
                      <TableCell>
                        {transaction?.updated_package.curr}{" "}
                        {transaction?.fullPayableAmount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Total Amount Paid</TableCell>
                      <TableCell>
                        {transaction?.updated_package.curr}{" "}
                        {transaction?.fullPaidAmount}
                      </TableCell>
                    </TableRow>
                    {expectedAmount ? (
                      <TableRow>
                        <TableCell variant="head">
                          Next Expected Amount
                        </TableCell>
                        <TableCell>
                          {transaction?.updated_package.curr} {expectedAmount}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {transaction.transactions?.length > 0 &&
                      transaction.transactions.map((trn) => {
                        return (
                          <TableRow>
                            <TableCell variant="head">
                              `{trn._id} ({trn.curr} {trn.actual_amount})`
                            </TableCell>
                            <TableCell>
                              {trn?.mposLogs[trn?.mposLogs?.length - 1]} (
                              {trn.mposRetries} retries)
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              {paymentTypes.length > 0 && (
                <>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    sx={{ width: 300 }}
                    autoHighlight
                    disabled={expectedAmount}
                    value={paymentType ? paymentType : ""}
                    onChange={(e, op) => setPaymentType(op)}
                    id="paymentTypeSelectorModal"
                    options={paymentTypes}
                    getOptionLabel={(dt) => {
                      return dt.name || dt || "";
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label="Select the Payment Type"
                          {...params}
                          // onChange={selectCcod}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </>
              )}
            </div>

            {transaction?.fullPaidAmount < transaction?.fullPayableAmount ? (
              expectedAmount ? (
                paymentTypes.length > 0 && (
                  <>
                    <TextField
                      label="Location Preference"
                      value={transaction?.location}
                      variant="outlined"
                      disabled={true}
                      type="text"
                    />
                    <Autocomplete
                      disablePortal
                      disableClearable
                      sx={{ width: 300 }}
                      autoHighlight
                      value={selectedDevice}
                      onChange={(e, d) => setSelectedDevice(d)}
                      id="devicesSelectorModal"
                      options={transaction?.devices}
                      getOptionLabel={(device) => {
                        return `${device?.name} - ${device.deviceId}` || "";
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            label="Select the Device"
                            {...params}
                            // onChange={selectCcod}
                            variant="outlined"
                          />
                        );
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={startPayment}
                      disabled={startButtonDisabled}
                    >
                      Initiate Payment on Swipe Machine
                    </Button>
                  </>
                )
              ) : (
                paymentTypes.length > 0 && (
                  <Button variant="contained" onClick={fetchAmount}>
                    Fetch Amount
                  </Button>
                )
              )
            ) : (
              <h4
                style={{
                  margin: "2rem auto auto",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Payment is Completed!
              </h4>
            )}
            <Button variant="contained" color="warning" onClick={refresh}>
              Refresh Transaction
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(StartMposPayment);
