import React, { useState, useEffect, useRef } from "react";
import "./EntryDelete.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  requestEntryDeleteOtp,
  verifyEntryDeleteOtp,
} from "../../../../actions/general";
import { Close } from "@mui/icons-material";
import currency from "../../../../currency";

let EntryDelete = (props) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [header, setHeader] = useState("");
  const [type, setType] = useState("");
  const [singleAmount, setSingleAmount] = useState();
  const [totalPaymentsDone, setTotalPaymentsDone] = useState(0);
  const [timer, setTimer] = useState("00:00");
  const [showResendOtp, setShowResendOtp] = useState(false);
  const Ref = useRef(null);

  useEffect(() => {
    setLoading(true);
    const transaction = props.transaction;
    let total = 0;
    if (transaction.emi_trxn_ids && transaction.emi_trxn_ids.length) {
      total += transaction.emi_trxn_ids.length;
    }
    if (
      transaction.installment_trxn_ids &&
      transaction.installment_trxn_ids.length
    ) {
      total += transaction.installment_trxn_ids.length;
    }
    if (transaction.full_trxn_id) {
      total++;
    }
    if (transaction.application_trxn_id) {
      total++;
    }
    if (transaction.reg_trxn_id) {
      total++;
    }
    setTotalPaymentsDone(total);

    if (transaction.emi_trxn_ids && transaction.emi_trxn_ids.length) {
      setType("EMI");
      setHeader(
        `${transaction.emi_trxn_ids.length}${pluralise(
          transaction.emi_trxn_ids.length
        )} EMI`
      );
      let planIndex = transaction.updated_package.emiPlans.findIndex(
        (plan) => plan._id == props.transaction.emiPlanId
      );

      const emiPlan =
        planIndex != -1
          ? props.transaction.updated_package.emiPlans[planIndex]
          : props.transaction.updated_package.emiPlans[0];

      setSingleAmount(emiPlan.emis[transaction.emi_trxn_ids.length - 1].amount);
    } else if (
      transaction.installment_trxn_ids &&
      transaction.installment_trxn_ids.length
    ) {
      setType("Installment");
      setHeader(
        `${transaction.installment_trxn_ids.length}${pluralise(
          transaction.installment_trxn_ids.length
        )} Installment`
      );
      let planIndex = transaction.updated_package.installmentPlans.findIndex(
        (plan) => plan._id == props.transaction.installmentPlanId
      );

      const installmentPlan =
        planIndex != -1
          ? props.transaction.updated_package.installmentPlans[planIndex]
          : props.transaction.updated_package.installmentPlans[0];

      setSingleAmount(
        installmentPlan.installments[
          transaction.installment_trxn_ids.length - 1
        ].amount
      );
    } else if (transaction.full_trxn_id) {
      setType("Full");
      setHeader(`Full`);
      setSingleAmount(transaction.updated_package.price);
    } else if (transaction.application_trxn_id) {
      setType("Application");
      setHeader(`Application`);
      setSingleAmount(transaction.updated_package.app_fees);
    } else if (transaction.reg_trxn_id) {
      setType("Registration");
      setHeader(`Registration`);
      setSingleAmount(transaction.updated_package.reg_price);
    }
    setLoading(false);
  }, []);

  let pluralise = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      setShowResendOtp(true);
      if (Ref.current) clearInterval(Ref.current);
    }
  };

  const clearTimer = (e) => {
    setTimer("2:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  const requestOTP = async () => {
    if (
      !comment ||
      comment.length <
        props.configs.ADMISSION_MODULE_INVOICE_DELETE_COMMENT_MINIMUM_LENGTH
    ) {
      return alert(
        `Please provide a valid comment of upto ${props.configs.ADMISSION_MODULE_INVOICE_DELETE_COMMENT_MINIMUM_LENGTH} characters`
      );
    }
    setLoading(true);
    const { success, message, ...data } = await requestEntryDeleteOtp({
      aid: props.transaction.aid,
      trxn_id: props.transaction._id,
      singleDelete: props.singleDelete,
      comment,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    setShowResendOtp(false);
    clearTimer(getDeadTime());
    setOtpCode("");
    setOtpSent(true);
  };

  const verifyOTP = async () => {
    setLoading(true);
    const { success, message, ...data } = await verifyEntryDeleteOtp({
      aid: props.transaction.aid,
      trxn_id: props.transaction._id,
      singleDelete: props.singleDelete,
      otp_code: otpCode,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      setShowConfirmation(false);
      return;
    }
    setOtpVerified(true);
  };

  return (
    <Modal
      open={props.open}
      // onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <div
          className={`${
            showConfirmation
              ? otpVerified
                ? "EntryDelete_3"
                : "EntryDelete_2"
              : "EntryDelete"
          }`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {!otpVerified ? (
              <Typography
                id="modal-modal-title"
                variant="h6"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#3C4852",
                }}
              >
                {showConfirmation
                  ? "Delete Confirmation"
                  : props.singleDelete
                  ? `${header} Deletion`
                  : "All Data Deletion"}
              </Typography>
            ) : (
              <div></div>
            )}
            <div>
              <Close
                style={{
                  color: "#212A39",
                  marginLeft: "4px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.closeModal();
                }}
              />
            </div>
          </div>
          {!otpVerified && <hr style={{ width: "100%", margin: "0" }} />}
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {otpVerified ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    // margin: "0 60px",
                  }}
                >
                  <div
                    style={{
                      color: "#A09FA0",
                      fontSize: "24px",
                      fontWeight: "700",
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      marginBottom: "80px",
                    }}
                  >
                    Invoice deletion successful!
                  </div>
                  <img
                    src="https://webcdn.imarticus.org/dsgdj/Ljg0Cv.tif_1_.svg"
                    alt="succes-delete"
                    style={{ marginBottom: "64px" }}
                  />
                  <div
                    style={{
                      color: "#3C4852",
                      fontSize: "16px",
                    }}
                  >
                    The deletion request is being processed. Please check back
                    in 5 minutes.
                  </div>
                </div>
              ) : showConfirmation ? (
                <div
                  style={{
                    // width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    margin: "0 60px",
                  }}
                >
                  <div
                    style={{
                      color: "#A09FA0",
                      fontSize: "24px",
                      fontWeight: "700",
                      lineHeight: "32px",
                      textTransform: "uppercase",
                      marginTop: "40px",
                    }}
                  >
                    Are you sure?
                  </div>
                  {!props.singleDelete ? (
                    <div
                      style={{
                        textAlign: "center",
                        color: "#3C4852",
                        fontSize: "16px",
                        fontWeight: "400",
                        marginTop: "40px",
                        marginBottom: "56px",
                      }}
                    >
                      All the application details, pre-assessment data (if any),
                      payments, invoices and kaching will be permanently deleted
                      and removed from the database. Are you sure you want to
                      delete all data, as this an irreversible process?
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        color: "#3C4852",
                        fontSize: "16px",
                        fontWeight: "400",
                        marginTop: "40px",
                        marginBottom: "56px",
                      }}
                    >
                      {header} fee and respective invoice will be permanently
                      deleted and removed from the database. Are you sure you
                      want to delete this, as this an irreversible process?
                    </div>
                  )}

                  <div style={{ display: "flex" }}>
                    <div
                      onClick={() => {
                        verifyOTP();
                      }}
                      style={{
                        margin: "0 24px",
                        width: "166px",
                        color: "white",
                        backgroundColor: "red",
                        borderRadius: "4px",
                        padding: "10px 36px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Yes, I am Sure
                    </div>
                    <div
                      onClick={() => {
                        setShowConfirmation(false);
                      }}
                      style={{
                        width: "166px",
                        border: "1px solid #055646",
                        color: "#055646",
                        // backgroundColor: "#6C757D",
                        borderRadius: "4px",
                        padding: "10px 36px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Back
                    </div>

                    {/* <div
                      onClick={() => {
                        props.closeModal();
                      }}
                      style={{
                        width: "166px",
                        color: "white",
                        backgroundColor: "#6C757D",
                        borderRadius: "4px",
                        padding: "10px 36px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </div> */}
                  </div>
                </div>
              ) : (
                <div style={{ margin: "0 60px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        color: "#A09FA0",
                        fontSize: "24px",
                        fontWeight: "700",
                        lineHeight: "32px",
                        textTransform: "uppercase",
                        marginTop: "28px",
                      }}
                    >
                      Are you sure?
                    </div>
                    {!props.singleDelete ? (
                      <div
                        style={{
                          textAlign: "center",
                          color: "#3C4852",
                          fontSize: "16px",
                          fontWeight: "400",
                          marginTop: "28px",
                          marginBottom: "28px",
                        }}
                      >
                        All the application details, pre-assessment data (if
                        any), payments, invoices and kaching will be permanently
                        deleted and removed from the database. Are you sure you
                        want to delete all data?
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          color: "#3C4852",
                          fontSize: "16px",
                          fontWeight: "400",
                          marginTop: "28px",
                          marginBottom: "28px",
                        }}
                      >
                        {header} fee and respective invoice will be permanently
                        deleted and removed from the database. Are you sure you
                        want to delete this?
                      </div>
                    )}
                  </div>
                  <li
                    style={{
                      color: "#212A39",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    {props.singleDelete
                      ? `You are deleting ${header} Fee for: `
                      : "You are deleting all data for: "}

                    <span style={{ color: "#055646" }}>
                      {props.user.name} ({props.user.ep})
                    </span>
                  </li>
                  <li
                    style={{
                      color: "#212A39",
                      fontSize: "20px",
                      fontWeight: "600",
                      margin: "16px 0",
                    }}
                  >
                    Real Batch:{" "}
                    <span style={{ color: "#055646" }}>
                      {props.transaction.realBatchName || "-"}
                    </span>
                  </li>
                  {props.singleDelete ? (
                    <li
                      style={{
                        color: "#212A39",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      {type} Fee: {"  "}
                      <span style={{ color: "#055646" }}>
                        {
                          currency[props.transaction.updated_package.curr]
                            .symbol
                        }{" "}
                        {singleAmount}
                      </span>
                    </li>
                  ) : (
                    <li
                      style={{
                        color: "#212A39",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Total Number of Payments Done:{" "}
                      <span style={{ color: "#055646" }}>
                        {totalPaymentsDone}
                      </span>
                    </li>
                  )}
                  <li
                    style={{
                      color: "#212A39",
                      fontSize: "20px",
                      fontWeight: "600",
                      margin: "16px 0",
                    }}
                  >
                    <span>Admin</span>:{" "}
                    <span style={{ color: "#055646" }}>
                      {props.transaction.courseCategoryData.admin_name} (
                      {props.transaction.courseCategoryData.admin_email})
                    </span>
                  </li>
                  <div style={{ display: "flex", margin: "32px 0" }}>
                    {!otpSent ? (
                      <>
                        <li
                          style={{
                            color: "#212A39",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          Enter Comment:
                        </li>
                        <input
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          style={{ marginLeft: "12px", width: "400px" }}
                        />
                      </>
                    ) : (
                      <>
                        <li
                          style={{
                            color: "#212A39",
                            fontSize: "20px",
                            fontWeight: "600",
                            marginRight: "4px",
                          }}
                        >
                          Enter OTP: {"  "}
                        </li>
                        <input
                          value={otpCode}
                          onChange={(e) => {
                            setOtpCode(e.target.value);
                          }}
                        />
                      </>
                    )}
                    {otpSent && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button
                          onClick={() => {
                            requestOTP();
                          }}
                          disabled={!showResendOtp}
                          style={{
                            width: "166px",
                            color: "white",
                            backgroundColor: "#055646",
                            borderRadius: "4px",
                            padding: "6px 20px",
                            textAlign: "center",
                            marginLeft: "16px",
                          }}
                        >
                          Resend OTP
                        </button>
                        {!showResendOtp && (
                          <div style={{ marginLeft: "12px" }}>
                            Remaining Time: {timer}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {otpSent && (
                    <div style={{ textAlign: "center", color: "green" }}>
                      OTP Sent Successfully!!
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      margin: "20px 0",
                    }}
                  >
                    {otpSent ? (
                      <button
                        disabled={!otpCode || otpCode.length != 6}
                        style={{
                          width: "166px",
                          color: "white",
                          backgroundColor: "red",
                          borderRadius: "4px",
                          padding: "10px 36px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          if (!otpCode) {
                            return alert("Please enter OTP");
                          }
                          setShowConfirmation(true);
                        }}
                      >
                        Delete
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          requestOTP();
                        }}
                        style={{
                          width: "166px",
                          color: "white",
                          backgroundColor: "#055646",
                          borderRadius: "4px",
                          padding: "10px 36px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Send OTP
                      </div>
                    )}
                    <div
                      onClick={() => {
                        props.closeModal();
                      }}
                      style={{
                        marginLeft: "24px",
                        width: "166px",
                        color: "white",
                        backgroundColor: "#6C757D",
                        borderRadius: "4px",
                        padding: "10px 36px",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    </Modal>
  );
};

export default EntryDelete;
