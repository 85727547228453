import React, { useEffect } from "react";
import { Card, Breadcrumb } from "antd";
import { connect } from "react-redux";

let Home = (props) => {
  console.log(props);

  return (
    <div>
      <Breadcrumb style={{ margin: "12px" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>

      <div className="pr-padding-main-cont" style={{ background: "#FFFFFF" }}>
        <center>
          <Card
            className="promocode-home-card"
            title="Sales Ops"
            style={{ maxWidth: 600 }}
            bodyStyle={{ display: "none" }}
          ></Card>
        </center>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    promo: state.promo.data,
    lp_loading: state.promo.lp_loading,
    prmsn: state.promo,
    permissions: state.login.permissions,
    resources: state.login.resources,
  };
};
export default connect(mapStateToProps)(Home);
// export default PromocodeClass;
