import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "antd";
import Loader from "./Loader";
import {
  fetchApplicationDetail,
  deleteUserInfoData,
  applyScholarship,
  requestScholarshipOtp,
  verifyScholarshipOtp,
  fetchApplications,
} from "../../actions/applicationFormAction";
// import Loader from './Loader';
import CustomisedTextModal from "./CustomisedTextModal";
import { APPLICATION_STATUS } from "../../constants";
import callicon from "../../images/call.svg";
import envelopeicon from "../../images/envelope.svg";
import { Select } from "antd";
const { Option } = Select;

import currency from "../../currency";

class ViewScholarshipModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCustomisedTextModal: false,
      customisedText: "",
      showOtp: false,
      scholarshipAmount: `${
        currency[
          this.props.appreview.crs_pkgs[
            this.props.appreview.crs_pkgs.findIndex(
              (crs_pkg) => crs_pkg.curr == this.props.currencySelected
            )
          ].curr
        ].symbol
      }${
        this.props.appreview.userinfo.scholarship_amount[
          this.props.appreview.crs_pkgs[
            this.props.appreview.crs_pkgs.findIndex(
              (crs_pkg) => crs_pkg.curr == this.props.currencySelected
            )
          ]._id
        ]
          ? this.props.appreview.userinfo.scholarship_amount[
              this.props.appreview.crs_pkgs[
                this.props.appreview.crs_pkgs.findIndex(
                  (crs_pkg) => crs_pkg.curr == this.props.currencySelected
                )
              ]._id
            ].amount
          : 0
      }`,
      comment: this.props.appreview.userinfo.scholarship_amount[
        this.props.appreview.crs_pkgs[
          this.props.appreview.crs_pkgs.findIndex(
            (crs_pkg) => crs_pkg.curr == this.props.currencySelected
          )
        ]._id
      ]
        ? this.props.appreview.userinfo.scholarship_amount[
            this.props.appreview.crs_pkgs[
              this.props.appreview.crs_pkgs.findIndex(
                (crs_pkg) => crs_pkg.curr == this.props.currencySelected
              )
            ]._id
          ].comment
        : "",
      package_index: this.props.appreview.crs_pkgs.findIndex(
        (crs_pkg) => crs_pkg.curr == this.props.currencySelected
      ),
      head_email: null,
    };
  }

  closeModal = () => {
    this.props.handleViewScholarshipModalToggle(false);
  };

  handleScholarshipAmountChange = (currIndex, amt) => {
    let scholarshipAmount = amt;
    let amount = scholarshipAmount.replace(/[^0-9.]/g, "");

    if (
      parseFloat(amount) >
      this.props.appreview.crs_pkgs[currIndex].max_scholarship_amount
    )
      return;

    this.setState({
      scholarshipAmount:
        currency[this.props.appreview.crs_pkgs[currIndex].curr].symbol + amount,
    });
  };

  handleCustomisedText = (e) => {
    this.setState({
      customisedText: e.target.value,
    });
  };

  handleOTPChange = (e) => {
    let otp = e.target.value || "";
    otp = otp.replace(/[^0-9]/g, "").slice(0, 6);
    this.setState({
      otp: otp,
    });
  };

  // handleCustomisedTextModalToggle = (val) => {
  //   return this.setState({
  //     openCustomisedTextModal: val,
  //   });
  // };

  // handlModalToggle = (val) => {
  //   this.props.handleViewScholarshipModalToggle(false);
  //   return this.setState({
  //     openCustomisedTextModal: val,
  //   });
  // };

  toggleSchemaObject = (idx) => {
    let tempSchema = this.state.schemaObjects;
    tempSchema[idx].isOpen = !tempSchema[idx].isOpen;
    this.setState({ schemaObjects: tempSchema });
  };

  fileUpload = (props) => {
    const { value, onChange } = props;
    console.log({ value });

    return (
      <div>
        {value && (
          <a href={value} className="fileName">
            {new URL(value).pathname.replace("/", "")}
          </a>
        )}
      </div>
    );
  };

  approvalAllowed = () => {
    let amount = this.state.scholarshipAmount || "0";
    amount = parseFloat(amount.slice(1).length == 0 ? "0" : amount.slice(1));
    return (
      amount <=
      this.props.appreview.crs_pkgs[this.state.package_index]
        .scholarship_tiers[0].end
    );
  };

  approveScholarship = () => {
    let amount = this.state.scholarshipAmount || "0";
    amount = parseFloat(amount.slice(1).length == 0 ? "0" : amount.slice(1));
    if (!this.state.comment)
      return alert("Please provide a comment for the scholarship request.");

    this.props.applyScholarship(
      {
        app_id: this.props.applicationDetail.user_app_id,
        crs_pkg_id: this.props.appreview.crs_pkgs[this.state.package_index]._id,
        amount: amount,
        comment: this.state.comment,
      },
      () => {
        // this.props.fetchApplications({
        //   crs_pg_id: this.props.appreview.crs_pg_id._id,
        //   search_val: this.props.appreview.searchVal,
        //   search_type: this.props.appreview.searchType,
        //   app_status: this.props.appreview.app_status
        //     ? this.props.appreview.app_status.status
        //     : undefined,
        //   appcurrent_page: 1,
        // });
        // this.props.fetchApplicationDetail({
        //   a_id: this.props.applicationDetail.a_id,
        //   crs_id: this.props.applicationDetail.crs_id,
        //   app_id: this.props.applicationDetail.user_app_id,
        // });
        this.closeModal();
        this.props.handlModalToggle(false);
        console.log({ appreview: this.props.appreview });
      }
    );
  };

  OtpBlockVisibilityToggle = (val) => {
    this.setState({
      showOtp: val,
    });
  };

  approvalNames = ["Advisor", "Sales Head", "Business Head", "Finance Head"];

  getApprovalText = () => {
    let amount = this.state.scholarshipAmount || "0";
    amount = parseFloat(amount.slice(1).length == 0 ? "0" : amount.slice(1));
    let tiers =
      this.props.appreview.crs_pkgs[this.state.package_index].scholarship_tiers;
    for (let i = 0; i < tiers.length; i++) {
      let tier = tiers[i];
      if (amount >= tier.start && amount <= tier.end) {
        return `${this.approvalNames[i]}`;
      }
    }
  };

  getApprovalEmails = () => {
    let amount = this.state.scholarshipAmount || "0";
    amount = parseFloat(amount.slice(1).length == 0 ? "0" : amount.slice(1));
    let tiers =
      this.props.appreview.crs_pkgs[this.state.package_index].scholarship_tiers;
    for (let i = 0; i < tiers.length; i++) {
      let tier = tiers[i];
      if (amount >= tier.start && amount <= tier.end) {
        return tier.email;
      }
    }
  };

  requestScholarshipOTP = () => {
    let amount = this.state.scholarshipAmount || "0";
    amount = parseFloat(amount.slice(1).length == 0 ? "0" : amount.slice(1));
    if (!this.state.comment)
      return alert("Please provide a comment for the scholarship request.");

    if (!this.state.head_email) {
      return window.alert("Please select your head email for approval");
    }

    if (!this.state.comment) {
      return window.alert("Please enter a comment for the approval");
    }

    this.props.requestScholarshipOtp({
      app_id: this.props.applicationDetail.user_app_id,
      crs_pkg_id: this.props.appreview.crs_pkgs[this.state.package_index]._id,
      amount: amount,
      head_email: this.state.head_email,
      comment: this.state.comment,
    });

    this.OtpBlockVisibilityToggle(true);
  };

  verifyScholarshipOTP = () => {
    if (!this.state.otp || this.state.otp.length != 6) {
      return;
    }

    this.props.verifyScholarshipOtp(
      {
        app_id: this.props.applicationDetail.user_app_id,
        crs_pkg_id: this.props.appreview.crs_pkgs[this.state.package_index]._id,
        otp_code: this.state.otp,
      },
      () => {
        // this.props.fetchApplications({
        //   crs_pg_id: this.props.appreview.crs_pg_id._id,
        //   search_val: this.props.appreview.searchVal,
        //   search_type: this.props.appreview.searchType,
        //   app_status: this.props.appreview.app_status
        //     ? this.props.appreview.app_status.status
        //     : undefined,
        //   appcurrent_page: 1,
        // });
        // this.props.fetchApplicationDetail({
        //   a_id: this.props.applicationDetail.a_id,
        //   crs_id: this.props.applicationDetail.crs_id,
        //   app_id: this.props.applicationDetail.user_app_id,
        // });
        this.closeModal();
        this.props.handlModalToggle(false);
      }
    );
  };
  render() {
    if (this.props.appreview.arloading) {
      return <Loader loading={this.props.appreview.arloading} />;
    }

    return (
      <div>
        {/* {this.state.openCustomisedTextModal ? (
          <CustomisedTextModal
            open={this.state.openCustomisedTextModal}
            handleCustomisedTextModalToggle={
              this.handleCustomisedTextModalToggle
            }
            handlModalToggle={this.handlModalToggle}
            user_data={this.state.user_data}
          />
        ) : null} */}
        <Modal
          title="Application Review"
          visible={this.props.open}
          onCancel={this.closeModal}
          width="700px"
          className="applicationmodal"
        >
          <div>
            <div className="row no-margin" style={{ padding: 24 }}>
              <div className="col-12 col-md-8 no-margin no-padding ">
                <div
                  className="ar-modal-main-head"
                  style={{ padding: "0px 0px 18px 0px", margin: 0 }}
                >
                  {this.props.appreview.userinfo.user_name}
                </div>
                <div className="crs-box">
                  {this.props.appreview.userinfo.crs_name}
                </div>
                <div style={{ marginTop: "16px" }}>
                  <span style={{ padding: "0px 16px 0px 0px" }}>
                    UID : {this.props.appreview.userinfo.uid}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <img src={callicon} />
                  <span style={{ padding: "0px 16px 0px 8px" }}>
                    {this.props.appreview.userinfo.phone_no}
                  </span>
                </div>
                <div
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <img src={envelopeicon} />
                  <span style={{ padding: "0px 16px 0px 8px" }}>
                    {this.props.appreview.userinfo.email[0]} {`(Pegasus Email)`}
                  </span>
                </div>
                {this.props.appreview.userinfo.applicationEmail && (
                  <div
                    style={{
                      marginTop: "8px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <img src={envelopeicon} />
                    <span
                      style={{ padding: "0px 16px 0px 8px", color: "#055646" }}
                    >
                      {this.props.appreview.userinfo.applicationEmail}{" "}
                      {`(Application Email)`}
                    </span>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-4 no-margin no-padding">
                <div
                  className={
                    this.props.appreview.userinfo.app_status == 3
                      ? "approved-clr"
                      : this.props.appreview.userinfo.app_status == 4
                      ? "reject-clr"
                      : this.props.appreview.userinfo.app_status == 2
                      ? "shortlist-clr"
                      : "pending-clr"
                  }
                >
                  {APPLICATION_STATUS[this.props.appreview.userinfo.app_status]}
                </div>
                <div style={{ fontSize: 12 }} className="sub_date">
                  Submitted: {this.props.appreview.userinfo.sub_date}
                </div>
              </div>
            </div>
            <Row
              className="row no-margin"
              style={{
                borderTop: "2px solid rgba(33, 42, 57, 0.25)",
                padding: "0px 24px",
              }}
            >
              <div
                className="ar-modal-main-head"
                style={{
                  padding: "18px 0px 18px 0px",
                  width: "100%",
                  margin: 0,
                }}
              >
                {`Give Scholarship (Max. Allowed ${
                  currency[
                    this.props.appreview.crs_pkgs[this.state.package_index].curr
                  ].symbol
                }${
                  this.props.appreview.crs_pkgs[this.state.package_index]
                    .max_scholarship_amount
                })`}
                <div
                  className="ar-modal-text-input"
                  style={{
                    width: "fit-content",
                    float: "right",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Select
                    onChange={(value) => {
                      this.setState({ package_index: value });
                      this.handleScholarshipAmountChange(
                        value,
                        this.state.scholarshipAmount
                      );
                    }}
                    value={this.state.package_index}
                    style={{ width: "100%" }}
                    className="app-dropdown"
                  >
                    {this.props.appreview.crs_pkgs &&
                      this.props.appreview.crs_pkgs.map((crs_pkg, index) => (
                        <Option key={index} value={index}>
                          {currency[crs_pkg.curr].symbol}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>

              <input
                className="ar-modal-text-input"
                value={this.state.scholarshipAmount}
                onChange={(e) => {
                  this.handleScholarshipAmountChange(
                    this.state.package_index,
                    e.target.value
                  );
                }}
                placeholder="Enter Scholarship Amount"
              />
              <input
                className="ar-modal-text-input"
                value={this.state.comment}
                onChange={(e) => {
                  this.setState({ comment: e.target.value });
                }}
                placeholder="Comment"
              />
              {!this.approvalAllowed() && (
                <>
                  <p style={{ color: "rgba(255, 0, 0, 0.9)", width: "100%" }}>
                    You can give scholarship upto{" "}
                    {`${
                      currency[
                        this.props.appreview.crs_pkgs[this.state.package_index]
                          .curr
                      ].symbol
                    }${
                      this.props.appreview.crs_pkgs[this.state.package_index]
                        .scholarship_tiers[0].end
                    }`}{" "}
                    only. You need to get approval from {this.getApprovalText()}
                    . Please select your {this.getApprovalText()} below.
                  </p>
                  {!this.approvalAllowed() && (
                    <Select
                      style={{ width: "100%" }}
                      className="app-dropdown"
                      value={this.state.head_email}
                      onChange={(value) => {
                        this.setState({
                          head_email: value,
                        });
                      }}
                      placeholder="Select head"
                    >
                      {this.getApprovalEmails()?.map((email) => {
                        return (
                          <Option key={email} value={email}>
                            {email}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </>
              )}
              <Button
                style={{
                  backgroundColor: "#035642",
                  color: "#FFFFFF",
                  float: "right",
                  padding: "0px 24px",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
                onClick={() => {
                  console.log("button pressed");
                  if (this.approvalAllowed()) {
                    this.approveScholarship();
                  } else {
                    this.requestScholarshipOTP();
                  }
                }}
              >
                {this.approvalAllowed() ? "Approve" : "Request Approval"}
              </Button>
              {/* <textarea className='ar-modal-text-input' value={this.state.customisedText} onChange={this.handleCustomisedText} style={{height: '10rem'}} placeholder='Add Customized Text'/> */}
            </Row>
            {this.state.showOtp && (
              <Row
                className="row no-margin"
                style={{
                  borderTop: "2px solid rgba(33, 42, 57, 0.25)",
                  padding: "0px 24px",
                }}
              >
                <input
                  className="ar-modal-text-input"
                  value={this.state.otp}
                  onChange={this.handleOTPChange}
                  placeholder="Enter OTP"
                />
                <Button
                  style={{
                    backgroundColor: "#035642",
                    color: "#FFFFFF",
                    padding: "0px 24px",
                    marginBottom: 16,
                  }}
                  onClick={this.verifyScholarshipOTP}
                >
                  Verify
                </Button>
              </Row>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
  };
};

const mapDispatchToProps = {
  fetchApplicationDetail,
  deleteUserInfoData,
  applyScholarship,
  requestScholarshipOtp,
  verifyScholarshipOtp,
  fetchApplications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewScholarshipModal);
