import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";
import { renewAccessToken } from "./loginActions";

const cookies = new Cookies();

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

const initiateLoading = (dispatch) => {
  return dispatch({ type: "INITITATE_AR_LOADING" });
};

const stopLoading = (dispatch) => {
  return dispatch({ type: "STOP_AR_LOADING" });
};

export const deleteUserInfoData = () => (dispatch) => {
  dispatch({
    type: "DELETE_USERINFO_DATA",
    payload: {},
  });
};

export const selectedCourse = (crs_pg_id) => (dispatch) => {
  dispatch({
    type: "SELECTED_COURSE",
    payload: {
      crs_pg_id: crs_pg_id,
    },
  });
};

export const resetApplication = () => (dispatch) => {
  dispatch({
    type: "FETCH_APPLICATION_FORMS",
    payload: {
      applications: null,
      crs_pkgs: [],
    },
  });
};

export const selectedApplicationStatus = (app_status) => (dispatch) => {
  dispatch({
    type: "SELECTED_APPLICATION_STATUS",
    payload: {
      app_status: app_status,
    },
  });
};

export const hideAlert = () => (dispatch) => {
  dispatch({
    type: "HIDE_NOTIFY",
    payload: {},
  });
};

export const hideOtpField = () => (dispatch) => {
  dispatch({
    type: "HIDE_OTP_FIELD",
    payload: {},
  });
};

export const fetchCourses = () => (dispatch) => {
  var configs = {
    method: "GET",
    url: constants.API_ENDPOINT + "/applicationreview/get_all_courses",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  initiateLoading(dispatch);
  axios(configs)
    .then((response) => {
      console.log(response);
      stopLoading(dispatch);
      dispatch({
        type: "UPDATE_COURSES",
        payload: {
          courses: response.data.data.courses,
        },
      });
    })
    .catch((err) => {
      stopLoading(dispatch);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const fetchApplications = (data) => (dispatch) => {
  dispatch({
    type: "UPDATE_APPCURRENT_PAGE",
    payload: {
      appcurrent_page: data.appcurrent_page,
    },
  });
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/ft_app_frms",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      crspg_id: data.crspg_id || data.crs_pg_id,
      ops_aid: cookies.get("aid"),
      search_val: data.search_val,
      search_type: data.search_type,
      stts: data.app_status,
      res_lmt: 10,
      p_no: data.appcurrent_page,
    },
  };
  initiateLoading(dispatch);
  axios(configs)
    .then((response) => {
      // console.log(response);
      stopLoading(dispatch);
      if (data.appcurrent_page == 1) {
        dispatch({
          type: "FETCH_APPLICATION_FORMS",
          payload: {
            applications: response.data.data.applications,
            crs_pkgs: response.data.data.crs_pkgs,
            total_app: response.data.data.total_app,
            isLead: response.data.data.isLead,
            lead: response.data.data.lead,
          },
        });
      } else {
        dispatch({
          type: "ADD_MORE_APPLICATION_FORMS",
          payload: {
            applications: response.data.data.applications,
            total_app: response.data.data.total_app,
            isLead: response.data.data.isLead,
            lead: response.data.data.lead,
          },
        });
      }
    })
    .catch((err) => {
      stopLoading(dispatch);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const initiateApplication = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/initiate_application",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      email: data.email,
      phone: data.phone,
      crs_pg_id: data.crs_pg_id,
      ops_aid: cookies.get("aid"),
    },
  };
  console.log({ configs });
  axios(configs)
    .then((response) => {
      console.log({ response });
      if (data.fetch_data) {
        dispatch(fetchApplications(data.fetch_data));
      }
      if (response.data.success) {
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "success",
            message: "Application Initiated Successfully!",
            dispatch: dispatch,
          },
        });
      } else {
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "error",
            message: response.data.message,
            dispatch: dispatch,
          },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const applyScholarship = (data, cb) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/apply_scholarship",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      app_id: data.app_id,
      amount: data.amount,
      crs_pkg_id: data.crs_pkg_id,
      ops_aid: cookies.get("aid"),
      comment: data.comment,
    },
  };
  initiateLoading(dispatch);
  console.log({ configs });
  axios(configs)
    .then((response) => {
      console.log({ response });
      if (data.fetch_data) {
        dispatch(fetchApplications(data.fetch_data));
      }
      if (response.data.success) {
        stopLoading(dispatch);
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "success",
            message: "Scholarship applied successfully!",
            dispatch: dispatch,
          },
        });
        cb();
      } else {
        stopLoading(dispatch);
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "error",
            message: response.data.message,
            dispatch: dispatch,
          },
        });
        cb();
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
      cb();
    });
};

export const requestScholarshipOtp = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/request_scholarship_otp",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      app_id: data.app_id,
      amount: data.amount,
      crs_pkg_id: data.crs_pkg_id,
      ops_aid: cookies.get("aid"),
      head_email: data.head_email,
      comment: data.comment,
    },
  };
  axios(configs)
    .then((response) => {
      console.log({ response });
      if (!response.data.success) {
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "error",
            message: response.data.message,
            dispatch: dispatch,
          },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const verifyScholarshipOtp = (data, cb) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/verify_scholarship_otp",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      app_id: data.app_id,
      otp_code: data.otp_code,
      crs_pkg_id: data.crs_pkg_id,
      ops_aid: cookies.get("aid"),
    },
  };
  axios(configs)
    .then((response) => {
      if (data.fetch_data) {
        dispatch(fetchApplications(data.fetch_data));
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "success",
            message: "Scholarship applied successfully!",
            dispatch: dispatch,
          },
        });
      }
      cb();
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      cb();
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const fetchApplicationDetail = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/ft_app",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      crspg_id: data.crs_id,
      student_aid: data.a_id,
      app_id: data.app_id,
    },
  };
  initiateLoading(dispatch);
  axios(configs)
    .then((response) => {
      // console.log(response);
      stopLoading(dispatch);
      dispatch({
        type: "INDIVIDUAL_APPLICATION_DETAIL",
        payload: {
          userinfo: response.data.data.userinfo,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      stopLoading(dispatch);
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const updateApplicationSattus = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/up_app_stts",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      crspg_id: data.crs_id,
      student_aid: data.a_id,
      app_id: data.app_id,
      c_txt: data.customised_text,
      n_stts: data.stts,
      o_stts: data.o_stts,
      otp_code: data.otp_code,
      ops_aid: cookies.get("aid"),
    },
  };
  initiateLoading(dispatch);
  return axios(configs)
    .then((response) => {
      // console.log(response);
      stopLoading(dispatch);
      if (data.stts == 5) {
        var msg = "Interview Reminder sent to user";
        console.log(msg);
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "success",
            message: msg,
            dispatch: dispatch,
          },
        });
        return response;
      } else {
        var msg =
          "Application status updated to " +
          constants.APPLICATION_STATUS[data.stts];
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "success",
            message: msg,
            dispatch: dispatch,
          },
        });
        return response;
      }
    })
    .catch((err) => {
      stopLoading(dispatch);
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const requestApplicationApproveOTP = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url:
      constants.API_ENDPOINT +
      "/applicationreview/request_application_approve_otp",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      crs_pkg_id: data.crs_pkg_id,
      app_id: data.app_id,
      head_email: data.head_email,
      comment: data.comment,
    },
  };
  // initiateLoading(dispatch);
  return axios(configs)
    .then((response) => {
      // stopLoading(dispatch);
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "success",
          message: "Successfully sent OTP",
          dispatch: dispatch,
        },
      });
      return response;
    })
    .catch((err) => {
      // stopLoading(dispatch);
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const requestApplicationRevertOTP = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url:
      constants.API_ENDPOINT +
      "/applicationreview/request_application_revert_otp",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      crs_pkg_id: data.crs_pkg_id,
      app_id: data.app_id,
      head_email: data.head_email,
      comment: data.comment,
    },
  };
  // initiateLoading(dispatch);
  return axios(configs)
    .then((response) => {
      // stopLoading(dispatch);
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "success",
          message: "Successfully sent OTP",
          dispatch: dispatch,
        },
      });
      return response;
    })
    .catch((err) => {
      // stopLoading(dispatch);
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
      return;
    });
};

export const verifyApplicationRevertOTP = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/revert_application",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      app_id: data.app_id,
      crs_pkg_id: data.crs_pkg_id,
      otp_code: data.otp_code ? data.otp_code : undefined,
    },
  };
  initiateLoading(dispatch);
  return axios(configs)
    .then((response) => {
      // console.log(response);
      stopLoading(dispatch);
      console.log({ response });
      if (response.data.success) {
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "success",
            message: response.data.message,
            dispatch: dispatch,
          },
        });
      }
      return response;
    })
    .catch((err) => {
      stopLoading(dispatch);
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const updateAssessmentAmount = (data) => (dispatch) => {
  var configs = {
    method: "POST",
    url: constants.API_ENDPOINT + "/applicationreview/updt_asmnt_atmpt",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      app_id: data.app_id,
      stage_slug: data.stage_slug,
      quiz_id: data.quiz_id,
    },
  };
  axios(configs)
    .then((response) => {
      // console.log(response);
      if (response.data.success) {
        dispatch(fetchApplicationDetail(data.fetch_data));
        dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "success",
            message: "Pre Assessment Attempt successfully increased",
            dispatch: dispatch,
          },
        });
      } else {
        var err_msg = "Something went wrong, Please try again";
        if (response && response.data && response.data.message) {
          err_msg = response.data.message;
        }
        return dispatch({
          type: "SHOW_NOTIFY",
          payload: {
            type: "error",
            message: err_msg,
            dispatch: dispatch,
          },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};
