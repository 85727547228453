import styles from "./cashPaymentsCollected.scss";
import React, { useState, useEffect } from "react";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import { fetchAdminAdjustedPayments } from "../../../actions/general";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "10%",
  left: "1%",
  transform: "translate(0%, -8%)",
  width: "98%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CashPaymentsCollected = (props) => {
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [payments, setPayments] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);

  useEffect(() => {
    fetchPayments();
  }, []);

  const handleChangePage = async (event, newPage) => {
    setLoading(true);
    setPage(newPage);
    let { success, message, ...data } = await fetchAdminAdjustedPayments({
      pageNo: newPage,
      pageLimit: rowsPerPage,
    });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({
      totalPayments: data.payments,
      payments: data.totalPayments,
    });
    setTotalPayments(data.totalPayments);
    setPayments(data.payments);
    setLoading(false);
  };

  const handleChangeRowsPerPage = async (event) => {
    setLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    let { success, message, ...data } = await fetchAdminAdjustedPayments({
      pageNo: 0,
      pageLimit: parseInt(event.target.value, 10),
    });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({
      totalPayments: data.payments,
      payments: data.totalPayments,
    });
    setTotalPayments(data.totalPayments);
    setPayments(data.payments);
    setLoading(false);
  };

  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };

  const fetchPayments = async () => {
    setLoading(true);
    let { success, message, ...data } = await fetchAdminAdjustedPayments({
      pageNo: page,
      pageLimit: rowsPerPage,
    });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }
    console.log({
      totalPayments: data.payments,
      payments: data.totalPayments,
    });
    setTotalPayments(data.totalPayments);
    setPayments(data.payments);
    setLoading(false);
  };

  const getType = (row) => {
    if (row.type == 1) {
      return "Full";
    }
    if (row.type == 2) {
      return `${row.installmentIndex + 1}${pluralise(
        row.installmentIndex + 1
      )} Installment`;
    }
    if (row.type == 3) {
      return `${row.emiIndex + 1}${pluralise(row.emiIndex + 1)} EMI`;
    }
    if (row.type == 4) {
      return "Registration";
    }
    if (row.type == 5) {
      return "Application";
    }
    if (row.type == 7) {
      return "Registration and Application";
    }
  };

  let pluralise = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="CashPaymentsCollected">
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <div
                style={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                }}
                className="closeIcon"
                onClick={props.closeModal}
              >
                <CloseIcon />
              </div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                My Cash Collected Payments
              </Typography>

              {payments.length ? (
                <TableContainer className="mainTable" component={Paper}>
                  <Table aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "20px" }}
                        >
                          S. No.
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Student Name
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Mobile
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Payment Type
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Total Amount
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Subvention Amount
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Payment Mode
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Reference ID
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "150px" }}
                        >
                          Comment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.courseName}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.studentName}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.mobile}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {getDate(row.createdAt)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {getType(row)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.currency} {row.actual_amount}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.currency} {row.subventionAmount}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.paymentMode}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.referenceId || "-"}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ width: "150px" }}
                          >
                            {row.comment || "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={totalPayments}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    colSpan={8}
                  />
                </TableContainer>
              ) : (
                <div>No Payments found</div>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CashPaymentsCollected;
