export default function reducer(
  state = {
    user: {
      logged: false,
      logging: true,
      verifying: false,
      token: null,
      logout: false,
    },
    fetching: false,
    fetched: false,
    error: null,
    permissions: {},
    resources: {},
  },
  action
) {
  switch (action.type) {
    case "LOGIN_USER": {
      return {
        ...state,
        user: { ...state.user, logging: true, verifying: false },
        fetching: true,
      };
    }
    case "LOGIN_USER_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "LOGIN_USER_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: {
          ...state.user,
          logging: true,
          verifying: true,
          token: action.payload.data,
        },
      };
    }
    case "RENEW_AT": {
      return { ...state, fetching: true };
    }
    case "RENEW_AT_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: {
          ...state.user,
          logging: true,
          verifying: true,
          logged: true,
          logout: false,
          data: action.payload.data,
        },
      };
    }
    case "RENEW_AT_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: {
          ...state.user,
          logging: true,
          verifying: true,
          error: action.payload,
        },
      };
    }
    case "VERIFY_OTP": {
      return { ...state, fetching: true };
    }
    case "VERIFY_OTP_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: {
          ...state.user,
          logging: true,
          verifying: true,
          logged: true,
          logout: false,
          data: action.payload.data,
        },
      };
    }
    case "VERIFY_OTP_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        user: {
          ...state.user,
          logging: true,
          verifying: true,
          error: action.payload,
        },
      };
    }
    case "LOGOUT": {
      console.log("logging out");
      return {
        ...state,
        user: {
          ...state.user,
          logging: true,
          verifying: false,
          logged: false,
          token: null,
          logout: true,
        },
      };
    }
    case "SET_PERMISSIONS_AND_RESOURCES": {
      console.log("SET_PERMISSIONS_AND_RESOURCES");
      return {
        ...state,
        permissions: action.payload.permissions,
        resources: action.payload.resources,
      };
    }
  }

  return state;
}
