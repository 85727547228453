import React, { Component, useState, useEffect } from "react";
import "./InstallmentEdit.scss";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import moment from "moment";
import {
  Layout,
  Popconfirm,
  Menu,
  Breadcrumb,
  // Icon,
} from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  TextField,
  Autocomplete,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import TablePagination from "@mui/material/TablePagination";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { logout } from "../../actions/loginActions";
import { countryCode, country_code_with_ISO } from "../../constants";
import countryCodes from "../../countryCodes";
import ApplyPayment from "./applypayment/ApplyPayment";
const { SubMenu } = Menu;
import {
  LogoutOutlined,
  UserOutlined,
  SolutionOutlined,
  NotificationOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";
import {
  fetchUserbyMobile,
  installmentPlanOverride,
} from "../../actions/general";
import {
  fetchCourses,
  selectedCourse,
  selectedCoursePackage,
  fetchFeeStructure,
  showErrorNotify,
} from "../../actions/editFeeAction";

const cookies = new Cookies();

let InstallmentEdit = (props) => {
  console.log({ props });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ccod, setCcod] = useState({
    code: "IN",
    value: "91",
    display: "India (+91)",
  });
  const [warningText, setWarningText] = useState("");
  const [coursePackages, setCoursePackages] = useState();
  const [mobileNumber, setMobileNumber] = useState("");
  const [course, setCourse] = useState(null);
  const [coursePackage, setCoursePackage] = useState(null);
  const [overrideFound, setOverrideFound] = useState({});
  const [loading, setLoading] = useState(false);
  const [userFound, setUserFound] = useState(null);
  const [installmentPlan, setInstallmentPlan] = useState(null);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otpCode, setOtpCode] = useState();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [applyPaymentModal, setApplyPaymentModal] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => props.fetchCourses(), []);
  useEffect(() => {
    if (selectedTransaction && !selectedTransaction.installmentPlanId) {
      return alert(
        "This Transaction is not of type Pay Via Installment! If no amount is paid by the user till now, then the user preferences may be changed."
      );
    }
  }, [selectedTransaction]);

  const handleCourseChange = (e, val) => {
    console.log({ crs: val });
    // props.selectedCourse(val._id);
    setCourse(val);
    let tempPackages = props.editfee.coursePackages
      ? props.editfee.coursePackages.filter((pkg) => pkg.crs_pg_id == val._id)
      : [];
    setCoursePackages(tempPackages);
    setOtpRequested(false);
    handleOtpChange("");
  };
  const handleCoursePackageChange = (e, val) => {
    console.log({ crs_pkg: val });
    props.selectedCoursePackage(val._id);
    setCoursePackage(val);
    setOtpRequested(false);
    handleOtpChange("");
  };
  const handleOtpChange = (otp) => {
    if (!otp) otp = "";
    console.log(otp, otp.replace(/[^0-9]/g, ""));
    otp = otp.replace(/[^0-9]/g, "");
    otp = otp.slice(0, 6);
    setOtpCode(otp);
  };
  const isValidMobile = (num) => {
    num = mobileNumber || num;
    return num && num.length >= 7 && num.length <= 15;
  };
  const validateMobileNum = (num) => {
    const isValid = isValidMobile(num);
    if (!isValid) props.showErrorNotify("Invalid Mobile Number Please Verify");
    return isValid;
  };

  const isValidCourse = (crs) => {
    crs = course || crs;
    return crs != undefined;
  };

  const validateCourse = (crs) => {
    const isValid = isValidCourse(crs);
    if (!isValid) props.showErrorNotify("Please Select a Valid Course");
    return isValid;
  };

  const isValidCoursePackage = (crs_pkg) => {
    crs_pkg = coursePackage || crs_pkg;
    return crs_pkg != undefined;
  };

  const validateCoursePackage = (crs_pkg) => {
    const isValid = isValidCoursePackage(crs_pkg);
    if (!isValid) props.showErrorNotify("Please Select a Valid Currency");
    return isValid;
  };
  let fetchUser = async () => {
    setUserFound(null);
    // setTransactions([]);
    if (
      !validateMobileNum(mobileNumber) ||
      !validateCourse(course) ||
      !validateCoursePackage(coursePackage)
    )
      return;
    setLoading(true);
    console.log({ crs_pkg_id: coursePackage, mobileNumber });
    let { success, message, ...data } = await fetchFeeStructure(
      coursePackage?._id,
      mobileNumber,
      ccod.value
    );
    setLoading(false);
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message,
          dispatch: dispatch,
        },
      });
      return;
    }
    setCoursePackage(data.feestructure.packageFound);
    setSelectedTransaction(data.feestructure.transaction);
    if (
      data.feestructure.amountoverride &&
      !data.feestructure.transaction?.refund?.amount
    ) {
      console.log(" amountoverride found");
      setOverrideFound(data.feestructure.amountoverride);
      if (data.feestructure.amountoverride?.overrides.installmentPlan) {
        console.log(" amountoverride installment plan found");
        setInstallmentPlan(
          data.feestructure.amountoverride.overrides.installmentPlan
        );
      }
    } else {
      if (data.feestructure.transaction.installmentPlanId) {
        console.log("installmentPlanId found");
        let planIndex =
          data.feestructure.transaction.updated_package.installmentPlans.findIndex(
            (plan) =>
              plan._id == data.feestructure.transaction.installmentPlanId
          );
        console.log({
          planIndex,
          "data.feestructure.transaction.updated_package.installmentPlans[planIndex]":
            data.feestructure.transaction.updated_package.installmentPlans[
              planIndex
            ],
        });
        if (planIndex >= 0) {
          let plan =
            data.feestructure.transaction.updated_package.installmentPlans[
              planIndex
            ];
          setInstallmentPlan(plan);
        }
      }
    }
    setProfile(data.prof);
    console.log({ data });
    navigate(
      `?${new URLSearchParams({
        m: mobileNumber,
        ccod: ccod.value,
        crs_pg_id: course._id,
        crs_pkg_id: coursePackage._id,
      })}`
    );
  };
  let overrideInstallmentPlan = async () => {
    if (
      !window.confirm(
        "Are you sure you want to save the edits to the installment plan?"
      )
    )
      return;
    let { valid, message: message1 } = isValidPlan();
    if (!valid) {
      return alert(message1 || "Invalid Installment Plan");
    }

    setLoading(true);
    console.log({ crs_pkg_id: coursePackage, mobileNumber });
    let { success, message, ...data } = await installmentPlanOverride({
      trxn_id: selectedTransaction._id,
      installmentPlan,
    });
    setLoading(false);
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message,
          dispatch: dispatch,
        },
      });
      return;
    }
    alert("Success!. The user's installment plan is overridden");
    setCoursePackage(data.feestructure.packageFound);
    setSelectedTransaction(data.feestructure.transaction);
    if (data.feestructure.amountoverride) {
      console.log(" amountoverride found");
      setOverrideFound(data.feestructure.amountoverride);
      if (data.feestructure.amountoverride?.overrides.installmentPlan) {
        console.log(" amountoverride installment plan found");
        setInstallmentPlan(
          data.feestructure.amountoverride.overrides.installmentPlan
        );
      }
    } else {
      if (data.feestructure.transaction.installmentPlanId) {
        console.log("installmentPlanId found");
        let planIndex =
          data.feestructure.transaction.updated_package.installmentPlans.findIndex(
            (plan) =>
              plan._id == data.feestructure.transaction.installmentPlanId
          );
        if (planIndex >= 0) {
          let plan =
            data.feestructure.transaction.updated_package.installmentPlans[
              planIndex
            ];
          setInstallmentPlan(plan);
        }
      }
    }
    setProfile(data.prof);
    console.log({ data });
    navigate(
      `?${new URLSearchParams({
        m: mobileNumber,
        ccod: ccod.value,
        crs_pg_id: course._id,
        crs_pkg_id: coursePackage._id,
      })}`
    );
  };
  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };
  let applyPaymentToggle = () => {
    console.log("applyPaymentToggle invoked::::;");
    setApplyPaymentModal((open) => !open);
    if (applyPaymentModal) fetchUser();
  };
  let pluralise = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };
  let isValidPlan = () => {
    let total = installmentPlan.installments.reduce(
      (total, installment) => total + installment.amount,
      0
    );
    if (total != installmentPlan.amount)
      return {
        valid: false,
        message: `Sum of Installments should be equal to ${installmentPlan.amount}`,
      };
    let empty = false;
    installmentPlan.installments.forEach((el) => {
      if (!el.amount) empty = true;
    });
    if (empty)
      return {
        valid: false,
        message: `Remove Any installments with amount 0`,
      };
    let set = new Set();
    installmentPlan.installments.forEach((el) => {
      set.add(el.period);
    });
    if (set.size != installmentPlan.installments.length) {
      return {
        valid: false,
        message: `Remove installments with duplicate period`,
      };
    }
    return {
      valid: true,
      message: "",
    };
  };
  // useEffect(() => console.log({ transactions }), [transactions]);
  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="installmentedit">
      <h2>Override User's Installment Plan</h2>
      <hr style={{ width: "100%", margin: "-1rem 0 2rem" }} />
      <h5>Enter the user's mobile Number</h5>
      <div className="row">
        <Autocomplete
          disablePortal
          disableClearable
          multiple={false}
          sx={{ width: 150 }}
          autoHighlight
          value={ccod}
          onChange={(event, ccode) => {
            setCcod({ ...ccode });
          }}
          getOptionLabel={(ccode) => {
            return ccode?.display || "";
          }}
          id="ccodeSelector"
          options={countryCodes}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a country code"
                {...params}
                // onChange={(event, ccode) => {
                //   setCcod({ ...ccode });
                // }}
                variant="outlined"
              />
            );
          }}
        />
        <TextField
          id="mobile"
          value={mobileNumber}
          onChange={(e) =>
            e.target.value.length < 15 ? setMobileNumber(e.target.value) : null
          }
          label="Mobile Number"
          variant="outlined"
        />
        {warningText && <p className="warning">{warningText}</p>}
      </div>
      <div className="row">
        {mobileNumber && (
          <>
            {/* <div className="input-label">Course Name</div> */}
            <Autocomplete
              disablePortal
              disableClearable
              autoHighlight
              onChange={handleCourseChange}
              value={course}
              options={props.editfee.courses || []}
              getOptionLabel={(crs) => {
                console.log({ crs });
                return crs.name;
              }}
              style={{ flexBasis: "50%" }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="input-field"
                    style={{ padding: 0 }}
                    label="Course Name"
                  />
                );
              }}
            />
          </>
        )}
        {course && (
          <>
            {/* <div className="input-label">Currency</div> */}
            <Autocomplete
              disablePortal
              disableClearable
              autoHighlight
              onChange={handleCoursePackageChange}
              value={coursePackage}
              options={coursePackages || []}
              getOptionLabel={(crs) => {
                return crs.curr;
              }}
              style={{ flexBasis: "20%" }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="input-field"
                    style={{ padding: 0 }}
                    label="Currency"
                  />
                );
              }}
            />
          </>
        )}
      </div>
      <Button variant="contained" onClick={fetchUser}>
        Fetch User
      </Button>
      {profile ? (
        <>
          <TextField
            id="username"
            value={profile?.nam}
            disabled
            placeholder="User's Name"
            variant="outlined"
          />
        </>
      ) : (
        <p className="warning">
          Please find an existing user to apply payment manually
        </p>
      )}
      {selectedTransaction && (
        <>
          <TableContainer className="mainTable" component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {/* <TableCell component="th" scope="row">
                    Course Name
                  </TableCell> */}
                  <TableCell component="th" scope="row">
                    Transaction Id
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Type
                  </TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <React.Fragment>
                <TableBody>
                  <TableRow key={selectedTransaction._id}>
                    <TableCell component="th" scope="row">
                      {selectedTransaction._id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {selectedTransaction.installmentPlanId
                        ? "Installments"
                        : selectedTransaction.emiPlanId
                        ? "Loan"
                        : "Full Payment"}
                    </TableCell>
                    <TableCell>
                      {getDate(selectedTransaction.createdAt)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </React.Fragment>
            </Table>
          </TableContainer>

          {selectedTransaction.installmentPlanId && installmentPlan ? (
            <>
              <div className="row">
                <TextField
                  variant="filled"
                  className="input-field"
                  style={{ padding: 0 }}
                  label="Total Installment Plan Amount"
                  value={installmentPlan.amount}
                  disabled
                />
                <TextField
                  variant="filled"
                  className="input-field"
                  style={{ padding: 0 }}
                  label="Current Installment Plan Amount"
                  value={installmentPlan.installments.reduce(
                    (total, plan) => total + plan.amount,
                    0
                  )}
                  disabled
                />
                <Button
                  variant="contained"
                  disabled={!installmentPlan}
                  onClick={() => {
                    if (installmentPlan) {
                      setInstallmentPlan((plan) => {
                        return {
                          ...plan,
                          installments: [
                            ...plan.installments,
                            {
                              period: plan.installments.at(-1).period + 1,
                              periodType: plan.installments.at(-1).periodType,
                              amount: 0,
                            },
                          ],
                        };
                      });
                    }
                  }}
                >
                  <SaveAsIcon /> Add New Installment
                </Button>
              </div>
              <TableContainer className="mainTable" component={Paper}>
                <Table>
                  <TableBody>
                    {installmentPlan?.installments.map((installment, index) => {
                      return (
                        <TableRow>
                          <TableCell variant="head">
                            {installment.alias || (
                              <>{`${index + 1}${pluralise(
                                index + 1
                              )} Installment`}</>
                            )}
                          </TableCell>
                          <TableCell>
                            <FormControl
                              variant="standard"
                              disabled={
                                selectedTransaction?.installment_trxn_ids[
                                  index
                                ] ||
                                selectedTransaction.enachInitiatedIndex ===
                                  index
                              }
                            >
                              <InputLabel htmlFor="period">Period</InputLabel>
                              <Input
                                type="number"
                                id="period"
                                // label="Installment Amount"
                                // variant="filled"
                                sx={{ m: 1, width: "25ch" }}
                                value={installment.period}
                                onChange={(e) => {
                                  if (
                                    selectedTransaction?.installment_trxn_ids[
                                      index
                                    ]
                                  ) {
                                    return alert(
                                      "This installment is already paid. Cannot edit this installment."
                                    );
                                  } else if (
                                    selectedTransaction?.enachInitiatedIndex ===
                                    index
                                  ) {
                                    return alert(
                                      "Enach Payment for this installment is already initiated. Cannot edit this installment."
                                    );
                                  }
                                  setInstallmentPlan((plan) => {
                                    let installments = plan.installments;
                                    installments[index].period = Number(
                                      e.target.value
                                    );
                                    return { ...plan, installments };
                                  });
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {installment.periodType}
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            {/* {selectedTransaction?.updated_package.curr}{" "}
                            {installment?.amount} */}
                            <FormControl
                              variant="standard"
                              disabled={
                                selectedTransaction?.installment_trxn_ids[
                                  index
                                ] ||
                                selectedTransaction.enachInitiatedIndex ===
                                  index
                              }
                            >
                              <InputLabel htmlFor="amount">
                                Installment Amount
                              </InputLabel>
                              <Input
                                type="number"
                                id="amount"
                                // label="Installment Amount"
                                // variant="filled"
                                sx={{ m: 1, width: "25ch" }}
                                value={installment.amount}
                                onChange={(e) => {
                                  if (
                                    selectedTransaction?.installment_trxn_ids[
                                      index
                                    ]
                                  ) {
                                    return alert(
                                      "This installment is already paid. Cannot edit this installment."
                                    );
                                  } else if (
                                    selectedTransaction?.enachInitiatedIndex ===
                                    index
                                  ) {
                                    return alert(
                                      "Enach Payment for this installment is already initiated. Cannot edit this installment."
                                    );
                                  }
                                  setInstallmentPlan((plan) => {
                                    let installments = plan.installments;
                                    installments[index].amount = Number(
                                      e.target.value
                                    );
                                    return { ...plan, installments };
                                  });
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    {selectedTransaction?.updated_package.curr}
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              disabled={
                                selectedTransaction?.installment_trxn_ids[index]
                              }
                              onClick={() => {
                                setInstallmentPlan((plan) => {
                                  if (
                                    !window.confirm(
                                      "Are you sure you want to remove this installment?"
                                    )
                                  )
                                    return;
                                  if (
                                    selectedTransaction?.installment_trxn_ids[
                                      index
                                    ]
                                  )
                                    return alert(
                                      "This installment is already paid. Cannot remove this installment."
                                    );
                                  let installments = [...plan.installments];
                                  installments.splice(index, 1);
                                  console.log({ installments });
                                  return { ...plan, installments };
                                });
                              }}
                            >
                              <DeleteIcon /> Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button variant="contained" onClick={overrideInstallmentPlan}>
                <SaveAsIcon /> Save
              </Button>
            </>
          ) : (
            <p>This Transaction is not of type Installments.</p>
          )}
        </>
      )}

      {/* {applyPaymentModal && (
        <ApplyPayment
          open={applyPaymentModal}
          closeModal={applyPaymentToggle}
          transaction={selectedTransaction}
          user={userFound}
        />
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
  selectedCourse,
  selectedCoursePackage,
  showErrorNotify,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentEdit);
