import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  Input,
  Layout,
  Tooltip,
  Breadcrumb,
  Icon,
  DatePicker,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
} from "antd";
import {
  Autocomplete,
  TextField,
  Stack,
  Switch,
  Typography,
  InputAdornment,
} from "@mui/material";
import { countryCode } from "../../../constants";
import {
  fetchCourseFeeStructures,
  getCourseAndAccount,
} from "../../../actions/general";
import {
  fetchCourses,
  hideOtpField,
} from "../../../actions/applicationFormAction";
const FormItem = Form.Item;
const { Option } = Select;
import currency from "../../../currency";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const approvalNames = [
  "Advisor", //21
  "Sales Head", //22
  "Business Head", //23
  "Finance Head", //24
];

let AddSpecialPromocodeForm = (props) => {
  const [form] = Form.useForm();
  const [showCourseInput, setShowCourseInput] = useState(false);
  const [promo, setPromo] = useState({});
  const [d_type, setD_type] = useState(false);
  const [package_index, set_package_index] = useState(0);
  const [packages, setPackages] = useState([]);
  const [uTag, setUTag] = useState("");
  const [applyType, setApplyType] = useState(0);
  const [installmentPlanId, setInstallmentPlanId] = useState(
    props.promo.installmentPlanId || ""
  );
  const [discountAmount, setDiscountAmount] = useState(null);
  const [sales_tier_index, set_sales_tier_index] = useState(0);
  const [otp, setOtp] = useState(null);
  const [course, setCourse] = useState(null);
  const [head_email, set_head_email] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    if (props.promo.code) {
      initializePromo(props);
    }
    props.fetchCourses();
  }, []);

  useEffect(() => {
    if (props.savedSpecialPromo) {
      setShowCourseInput(false);
      setPromo({});
      setD_type(false);
      setPackages([]);
      set_package_index(0);
      setUTag("");
      setApplyType(0);
      setDiscountAmount(null);
      setOtp(null);
    }
  }, [props]);

  let initializePromo = (props) => {
    setPromo({});
    setShowCourseInput(false);
  };

  const checkVisibility = (resource) => {
    if (!props.permissions) {
      return true;
    } else {
      if (!props.permissions[resource]) {
        return false;
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleOTPChange = (e) => {
    let otp = e.target.value || "";
    otp = otp.replace(/[^0-9]/g, "").slice(0, 6);
    setOtp(otp);
  };

  const setSalesTierIndex = () => {
    if (checkVisibility("21")) {
      set_sales_tier_index(0);
    }
    if (checkVisibility("22")) {
      set_sales_tier_index(1);
    }
    if (checkVisibility("23")) {
      set_sales_tier_index(2);
    }
    if (checkVisibility("24")) {
      set_sales_tier_index(3);
    }
  };

  let handleSubmit = (values) => {
    let time = new Date().getTime();
    if (values.exp.$d) {
      time = new Date(values.exp.$d).getTime();
    }
    values = { ...values, exp: Number(time) };

    if (applyType == 2) {
      values = { ...values, crs_pkg_id: packages[package_index]._id };
    }

    if (course) {
      values = { ...values, crs_pg_id: course._id };
    }
    values = { ...values, d_typ: d_type ? 2 : 1 };
    if (d_type) {
      values = {
        ...values,
        curr: packages[package_index]?.curr,
      };
    }
    if (applyType == 2 && installmentPlanId) {
      values = {
        ...values,
        curr: packages[package_index]?.curr,
        pkg_id: packages[package_index]?._id,
        installmentPlanId,
      };
    }

    console.log({ values });

    if (
      applyType == 2 &&
      validDiscountAmount() &&
      !approvalAllowed(discountAmount, d_type)
    ) {
      props.callback(values, form, false);
    } else {
      props.callback(values, form, true);
    }
  };

  let verifyDiscountOTP = () => {
    const values = {
      ops_aid: cookies.get("aid"),
      crs_pkg_id: packages[package_index]._id,
      otp_code: otp,
      pno: form.getFieldValue("pno"),
      c_code: form.getFieldValue("c_code"),
    };

    props.verifyDiscountOTP(values, form);
  };

  let approvalAllowed = (value, type) => {
    let amount = value || 0;
    if (type) {
      console.log(
        amount,
        (parseFloat(
          packages[package_index].discount_tiers[sales_tier_index].end
        ) *
          packages[package_index].price) /
          100
      );
      return (
        amount <=
        (parseFloat(
          packages[package_index].discount_tiers[sales_tier_index].end
        ) *
          packages[package_index].price) /
          100
      );
    } else {
      console.log(
        amount,
        parseFloat(packages[package_index].discount_tiers[sales_tier_index].end)
      );
      return (
        amount <=
        parseFloat(packages[package_index].discount_tiers[sales_tier_index].end)
      );
    }
  };

  let getApprovalText = (value, type) => {
    let amount = value || 0;
    let tiers;
    if (type) {
      tiers = packages[package_index].discount_tiers.map((tier) => {
        return {
          start: (tier.start * packages[package_index].price) / 100,
          end: (tier.end * packages[package_index].price) / 100,
        };
      });
    } else {
      tiers = packages[package_index].discount_tiers;
    }
    for (let i = 0; i < tiers.length; i++) {
      let tier = tiers[i];
      if (amount >= tier.start && amount <= tier.end) {
        return `${approvalNames[i]}`;
      }
    }
  };

  let getApprovalEmails = (value, type) => {
    let amount = value || 0;
    let tiers;
    if (type) {
      tiers = packages[package_index].discount_tiers.map((tier) => {
        return {
          start: (tier.start * packages[package_index].price) / 100,
          end: (tier.end * packages[package_index].price) / 100,
          email: tier.email,
        };
      });
    } else {
      tiers = packages[package_index].discount_tiers;
    }
    for (let i = 0; i < tiers.length; i++) {
      let tier = tiers[i];
      if (amount >= tier.start && amount <= tier.end) {
        return tier.email;
      }
    }
  };

  let getHeadTier = (value, type) => {
    let amount = value || 0;
    let tiers;
    if (type) {
      tiers = packages[package_index].discount_tiers.map((tier) => {
        return {
          start: (tier.start * packages[package_index].price) / 100,
          end: (tier.end * packages[package_index].price) / 100,
          email: tier.email,
        };
      });
    } else {
      tiers = packages[package_index].discount_tiers;
    }
    for (let i = 0; i < tiers.length; i++) {
      let tier = tiers[i];
      if (amount >= tier.start && amount <= tier.end) {
        console.log({ i });
        return i;
      }
    }
    return -1;
  };

  let checkDiscountAmount = (rule, value, callback) => {
    if (!value) {
      return callback();
    }

    value = parseFloat(value);
    if (
      discountAmount &&
      getHeadTier(discountAmount, d_type) != getHeadTier(value, d_type)
    ) {
      set_head_email("");
      form.setFieldValue("head_email", null);
    }
    setDiscountAmount(value);

    if (d_type) {
      if (packages[package_index] && value > packages[package_index].price)
        return callback(
          `Discount amount can not be more than ${
            currency[packages[package_index].curr].symbol
          } ${packages[package_index].price}`
        );
    } else {
      if (value <= 0 || value > 100) {
        return callback("Discount should be between 1 to 100.");
      }
    }
    callback();
  };

  let validDiscountAmount = () => {
    if (d_type) {
      console.log(
        discountAmount > 0 && discountAmount < packages[package_index].price
      );
      return (
        discountAmount > 0 && discountAmount < packages[package_index].price
      );
    } else {
      console.log(discountAmount > 0 && discountAmount < 100);
      return discountAmount > 0 && discountAmount < 100;
    }
  };

  let handleTypeChange = (e) => {
    console.log(e);
    setSalesTierIndex();
    setApplyType(parseInt(e));
    setShowCourseInput(e == 2);
  };

  let handleCourseChange = (id) => {
    if (id) {
      fetchCourseFeeStructures(id).then(
        ({ status, success, message, ...data }) => {
          {
            status, success, message;
          }
          if (!success) {
            if (message) {
              window.alert(message);
            } else {
              window.alert("Something went wrong. Please try again.");
            }
            return;
          } else {
            setPackages(data.coursePackages);
          }
        }
      );
    } else {
      window.alert("Please enter a Course U-Tag");
    }
  };

  let checkPhoneNumber = (rule, value, callback) => {
    if (isNaN(value) == true) {
      callback("Invalid phone number");
    } else if (value.toString().length == 10) {
      callback();
    } else if (value.toString().trim().length != 0) {
      callback("Phone number must be of 10 digits");
    }
  };

  let validPhoneNumber = (e) => {
    if (e && e.target && e.target.value) {
      e.target.value = Math.floor(e.target.value);
      e.target.value = Math.abs(e.target.value);
    }
  };

  let validDiscount = (e) => {
    if (e && e.target && e.target.value) {
      e.target.value = Math.floor(e.target.value);
      e.target.value = Math.abs(e.target.value);
    }
  };
  let onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const countryCodesOption = countryCode.map((c, key) => {
    return (
      <Option value={c} key={key}>
        +{c}
      </Option>
    );
  });
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 6,
      },
    },
  };
  return (
    <div>
      {packages.length > 0 && (
        <Select
          onChange={(value) => {
            set_package_index(value);
          }}
          value={package_index}
          style={{ width: "122px", position: "absolute", right: "10%" }}
          className="app-dropdown"
        >
          {packages.map((crs_pkg, index) => (
            <Option key={index} value={index}>
              {currency[crs_pkg.curr].symbol} ({crs_pkg.curr})
            </Option>
          ))}
        </Select>
      )}
      <Form
        layout="horizontal"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={{
          c_code: 91,
          d_typ: 1,
          installmentPlanId: props.promo.installmentPlanId
            ? props.promo.installmentPlanId
            : 0,
        }}
      >
        <FormItem
          {...formItemLayout}
          label="Type of Promocode"
          name="app_typ"
          rules={[
            {
              required: true,
              message: "Please select apply type of promocode",
            },
          ]}
        >
          <Select
            onChange={handleTypeChange}
            name="typ"
            placeholder="Please select apply type"
          >
            {checkVisibility("30") && <Option value="1">All Course</Option>}
            <Option value="2">Specific Course</Option>
          </Select>
        </FormItem>
        {showCourseInput && (
          <>
            <FormItem
              {...formItemLayout}
              label="Course"
              name="course"
              rules={[{ required: true, message: "Please select course" }]}
            >
              <Autocomplete
                disablePortal
                fullWidth
                autoHighlight
                value={props.appreview.crs_pg_id}
                onChange={(event, selectedCourse) => {
                  setCourse(selectedCourse);
                  handleCourseChange(selectedCourse._id);
                }}
                getOptionLabel={(selectedCourse) => {
                  return selectedCourse?.name ? `${selectedCourse.name}` : "";
                }}
                id="courseSelectorModal"
                options={props.appreview.courses}
                renderInput={(params) => {
                  return (
                    <TextField
                      label="Select Course"
                      {...params}
                      variant="outlined"
                    />
                  );
                }}
              />
            </FormItem>
          </>
        )}

        {(applyType == 1 || (applyType == 2 && packages.length > 0)) && (
          <FormItem
            {...formItemLayout}
            label="Enter Phone Number"
            name="pno"
            rules={[
              { required: true, message: "Please enter user phone number" },
              {
                validator: checkPhoneNumber,
              },
            ]}
          >
            <Input
              addonBefore={
                <FormItem
                  name="c_code"
                  rules={[
                    { required: true, message: "Please enter country code" },
                  ]}
                  style={{ margin: 0 }}
                >
                  <Select style={{ width: 80 }}>{countryCodesOption}</Select>
                </FormItem>
              }
              type="number"
              onChange={validPhoneNumber}
              placeholder="Enter user phone number"
            />
          </FormItem>
        )}

        {(applyType == 1 || (applyType == 2 && packages.length > 0)) && (
          <FormItem
            {...formItemLayout}
            label="Description"
            name="desc"
            rules={[
              {
                required: true,
                message: "Please enter promocode description",
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Promocode Description"
              onChange={(e) => console.log({ e })}
            />
          </FormItem>
        )}

        {(applyType == 1 || (applyType == 2 && packages.length > 0)) && (
          <FormItem
            {...formItemLayout}
            label="Type of Discount"
            name="d_typ"
            rules={[
              { required: true, message: "Please select type of discount" },
            ]}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Percentage Discount</Typography>
              <Switch
                disabled={!showCourseInput}
                defaultChecked={d_type}
                onClick={(ev) => {
                  console.log({ ev: ev?.target?.value });
                  setD_type((el) => !el);
                  form.setFieldValue("val", null);
                  setDiscountAmount(null);
                  props.hideOtpField();
                }}
              />
              <Typography>Net Discount</Typography>
            </Stack>
          </FormItem>
        )}

        {(applyType == 1 || (applyType == 2 && packages.length > 0)) && (
          <>
            {d_type ? (
              <FormItem
                {...formItemLayout}
                label="Discount Amount"
                name="val"
                rules={[
                  { required: true, message: "Please input Discount Amount" },
                  {
                    validator: checkDiscountAmount,
                  },
                ]}
              >
                <TextField
                  type="number"
                  label="Discount Amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currency[packages[package_index]?.curr].symbol}
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{ m: 1, width: "25ch" }}
                  onChange={validDiscount}
                />
              </FormItem>
            ) : (
              <FormItem
                {...formItemLayout}
                label="Discount Percentage"
                name="val"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid discount percentage",
                  },
                  {
                    validator: checkDiscountAmount,
                  },
                ]}
              >
                <Input
                  type="number"
                  onChange={validDiscount}
                  placeholder="Discount Percentage"
                />
              </FormItem>
            )}
            {d_type != undefined &&
            applyType == 2 &&
            validDiscountAmount() &&
            !approvalAllowed(discountAmount, d_type) ? (
              <>
                <p
                  style={{
                    color: "rgba(255, 0, 0, 0.9)",
                    width: "100%",
                    marginLeft: "25%",
                  }}
                >
                  You can give discount upto{" "}
                  {d_type
                    ? currency[packages[package_index].curr].symbol
                    : null}
                  {`${
                    d_type
                      ? (packages[package_index].price *
                          packages[package_index].discount_tiers[
                            sales_tier_index
                          ].end) /
                        100
                      : packages[package_index].discount_tiers[sales_tier_index]
                          .end
                  }`}{" "}
                  {d_type ? null : "%"} only. You need to get approval from{" "}
                  {getApprovalText(discountAmount, d_type)}.
                </p>
              </>
            ) : null}
          </>
        )}
        {d_type != undefined &&
          applyType == 2 &&
          validDiscountAmount() &&
          !approvalAllowed(discountAmount, d_type) && (
            <FormItem
              {...formItemLayout}
              label="Head Email"
              name="head_email"
              rules={[{ required: true, message: "Please select head" }]}
            >
              <Select
                style={{ width: "100%" }}
                className="app-dropdown"
                value={head_email}
                onChange={(value) => {
                  set_head_email(value || "");
                }}
                placeholder="Select head"
              >
                {getApprovalEmails(discountAmount, d_type).map((email) => {
                  return (
                    <Option key={email} value={email}>
                      {email}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          )}
        {d_type != undefined &&
          applyType == 2 &&
          validDiscountAmount() &&
          !approvalAllowed(discountAmount, d_type) && (
            <FormItem
              {...formItemLayout}
              label="Comment"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please enter promocode comment",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Promocode Comment"
                onChange={(e) => console.log({ e })}
              />
            </FormItem>
          )}
        {(applyType == 1 || (applyType == 2 && packages.length > 0)) && (
          <FormItem
            {...formItemLayout}
            label="Payment Type"
            name="paymentType"
            rules={[
              {
                required: true,
                message: "Please Select Payment Type Constraint",
              },
            ]}
          >
            <Select
              name="paymentType"
              placeholder="Please select payment type Constraint"
              options={[
                {
                  value: 0,
                  label: "All Payment Type (Default)",
                },
                {
                  value: 1,
                  label: "Full Payment Only",
                },
                {
                  value: 2,
                  label: "Payment by Installments Only",
                },
                {
                  value: 3,
                  label: "Payment by Loan Only",
                },
              ]}
            />
          </FormItem>
        )}
        {applyType == 2 &&
        packages[package_index] &&
        packages[package_index]?.installmentPlans.length > 0 ? (
          <FormItem
            {...formItemLayout}
            label="Installment Plan"
            name="installmentPlanId"
            rules={[
              {
                required: true,
                message: "Please Select Installment Plan Constraint",
              },
            ]}
          >
            <Select
              onChange={(val) => setInstallmentPlanId(val)}
              name="paymentType"
              placeholder="Please select payment plan Constraint"
              // defaultValue="2"
              value={installmentPlanId}
              options={
                packages[package_index]?.installmentPlans.map((plan) => {
                  return {
                    value: plan._id,
                    label: `${plan.period} ${plan.periodType}s, ${plan.installments.length} installments, amount: ${plan.amount}`,
                  };
                }) || []
              }
            />
          </FormItem>
        ) : null}

        {(applyType == 1 || (applyType == 2 && packages.length > 0)) && (
          <FormItem
            {...formItemLayout}
            label="Expiry Date"
            name="exp"
            rules={[
              {
                type: "object",
                required: true,
                message: "Please select time!",
              },
            ]}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Select Time"
            />
          </FormItem>
        )}

        {(applyType == 1 || (applyType == 2 && packages.length > 0)) && (
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={false}>
              {applyType == 1 && "Create Promocode"}
              {applyType == 2
                ? validDiscountAmount()
                  ? approvalAllowed(discountAmount, d_type)
                    ? "Create Promocode"
                    : "Request Promocode Approval"
                  : "Create Promocode"
                : null}
            </Button>
          </FormItem>
        )}
        {props.showOtp && (
          <Row
            className="row no-margin"
            style={{
              padding: "0px 24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input
              className="ar-modal-text-input"
              value={otp}
              onChange={handleOTPChange}
              placeholder="Enter OTP"
              style={{ marginLeft: "24%", width: "43%" }}
            />
            <Button
              style={{
                backgroundColor: "#035642",
                color: "#FFFFFF",
                padding: "0px 24px",
                marginBottom: 16,
                marginLeft: "24%",
                width: "100px",
              }}
              onClick={verifyDiscountOTP}
            >
              Verify
            </Button>
          </Row>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    permissions: state.login.permissions,
    resources: state.login.resources,
    showOtp: state.promo.showOtp,
    savedSpecialPromo: state.promo.savedSpecialPromo,
    appreview: state.appreview,
  };
};

const mapDispatchToProps = {
  fetchCourses,
  hideOtpField,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSpecialPromocodeForm);
