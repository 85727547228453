  import { CREATE_CAMPAIGN,   
         CREATE_CAMPAIGN_FULFILLED,   
         CREATE_CAMPAIGN_COMPLETED,   
         CREATE_CAMPAIGN_REJECTED,
         OPEN_CAMPAIGN,
         OPEN_CAMPAIGN_FULFILLED,
         OPEN_CAMPAIGN_REJECTED,
         FETCH_CAMPAIGNS,   
         FETCH_CAMPAIGNS_FULFILLED,   
         FETCH_CAMPAIGNS_REJECTED,   
         LAUNCH_CAMPAIGN,   
         LAUNCH_CAMPAIGN_FULFILLED,   
         LAUNCH_CAMPAIGN_REJECTED, 
         RELAUNCH_CAMPAIGN,   
         RELAUNCH_CAMPAIGN_FULFILLED,   
         RELAUNCH_CAMPAIGN_REJECTED, 
         CANCEL_CAMPAIGN,   
         CANCEL_CAMPAIGN_FULFILLED,   
         CANCEL_CAMPAIGN_REJECTED,  
         DELETE_CAMPAIGN,   
         DELETE_CAMPAIGN_FULFILLED,   
         DELETE_CAMPAIGN_REJECTED,   
        } from "../actions/campaignActions"
export default function reducer(state={
    campaign : {
      success  : false,
      loading : false,
      data : {}
    },
    create : {
      success : false,
      loading : false,
      completed : false,
      data : {}
    },
    open : {
      success : false,
      loading : false,
      message : "",
      data : {}
    },
    cancel : {
      success : false,
      message : ""
    },
    delete : {
      success : false,
      message : ""
    },
    list : {},
    logout : false,
  }, action) {
    switch (action.type) {

      case CREATE_CAMPAIGN: {
        return {
          ...state,
          create : {
            ...state.create,
            loading : true
          }
        }
      }
      case CREATE_CAMPAIGN_FULFILLED: {
        return {
          ...state,
          create : {
            ...state.create,
            loading : false,
            success : true,
            data : action.payload.data
          }
        }
      }
      case CREATE_CAMPAIGN_COMPLETED: {
        return state
      }
      case CREATE_CAMPAIGN_REJECTED: {
        console.log(action.payload);
        let {data} = action.payload;
        let message;
        if(data){
          message = data.message;
        } else {
          message = "Please try again later";
        }
        return {
          ...state,
          create : {
            ...state.campaign,
            loading : false,
            success : false,
            message
          }
        }
      }
      case FETCH_CAMPAIGNS : {
        return state
      }
      case FETCH_CAMPAIGNS_FULFILLED : {
        console.log(action.payload);
        return {
          ...state,
          list : action.payload.data
        }
      }
      case FETCH_CAMPAIGNS_REJECTED : {
        return state
      }
      case OPEN_CAMPAIGN : {
        return state
      }
      case OPEN_CAMPAIGN_FULFILLED : {
        console.log(action.payload);
        return {
          ...state,
          open : {
            ...state.open,
            data : action.payload.data,
            success : true
          }
        }
      }
      case OPEN_CAMPAIGN_REJECTED : {
        console.log(action.payload);
        let message,statusCode;
        if(!action.payload){
          message = "Please try again later";
          statusCode = action.payload.status;
        } else {
          console.log(action.payload);
          let {data} = action.payload;
          if(data){
            message = data.message;
            statusCode = data.code;
          } else {
            message = "Please try again later";
            statusCode = action.payload.status;
          }
        }
        return {
          ...state,
          open : {
            data : {},
            success : false,
            statusCode, 
            message
          }
        }
      }
      case LAUNCH_CAMPAIGN : {
        return state
      }
      case LAUNCH_CAMPAIGN_FULFILLED : {
        console.log(action.payload)
        return {
          ...state,
          campaign : {
            success : true,
            loading : false,
            data : action.payload
          }
        }
      }
      case LAUNCH_CAMPAIGN_REJECTED : {
        let message;
        if(!action.payload){
          message = "Please try again later";
        } else {
          console.log(action.payload);
          let {data} = action.payload;
          if(data){
            message = data.message;
          } else {
            message = "Please try again later";
          }
        }
        return {
          ...state,
          campaign : {
            success : false,
            loading : false,
            message
          }
        }
      }
      case RELAUNCH_CAMPAIGN : {
        return state
      }
      case RELAUNCH_CAMPAIGN_FULFILLED : {
        console.log(action.payload)
        return {
          ...state,
          campaign : {
            success : true,
            loading : false,
            data : action.payload
          }
        }
      }
      case RELAUNCH_CAMPAIGN_REJECTED : {
        let message;
        if(!action.payload){
          message = "Please try again later";
        } else {
          console.log(action.payload);
          let {data} = action.payload;
          if(data){
            message = data.message;
          } else {
            message = "Please try again later";
          }
        }
        return {
          ...state,
          campaign : {
            success : false,
            loading : false,
            message
          }
        }
      }
      case CANCEL_CAMPAIGN : {
        return state
      }
      case CANCEL_CAMPAIGN_FULFILLED : {
        console.log(action.payload)
        let {campaign_id} = action.payload.data
        return {
          ...state,
          list : state.list.length > 0 && state.list.filter(campaign =>  (campaign.basic._id != campaign_id)),
          cancel : {
            success : true,
            message : "Successfully cancelled the campaign"
          } 
        }
      }
      case CANCEL_CAMPAIGN_REJECTED : {
        console.log(action.payload);
        let message;
        if(!action.payload){
            message = "Please try again later";
        } else { 
          let {data} = action.payload;
          if(data){
            message = data.message;
          } else {
            message = "Please try again later";
          }
        }
        return {
          ...state,
          cancel : {
            success : false,
            message 
          }
        }
      }
      case DELETE_CAMPAIGN : {
        return state
      }
      case DELETE_CAMPAIGN_FULFILLED : {
        console.log(action.payload)
        let {campaign_id} = action.payload.data
        return {
          ...state,
          list : state.list.length > 0 && state.list.filter(campaign =>  (campaign.basic._id != campaign_id)),
          delete : {
            success : true,
            message : "Successfully deleted the campaign"
          } 
        }
      }
      case DELETE_CAMPAIGN_REJECTED : {
        console.log(action.payload);
        let message;
        if(!action.payload){
            message = "Please try again later";
        } else { 
          let {data} = action.payload;
          if(data){
            message = data.message;
          } else {
            message = "Please try again later";
          }
        }
        return {
          ...state,
          delete : {
            success : false,
            message 
          }
        }
      }
      case "LOGOUT":{
        return{...state,
          data: {
            group : {},
            plugins : {},
            ready : false,
            success : false,
            promos : [],
            currentPromo : {}
          },
          fgd_loading: false,
          lp_loading : false,
          loading : false,
        }
      }
    }
    return state
}
