import React, { Component, useEffect } from "react";
import {
  Form,
  Divider,
  Input,
  Spin,
  Layout,
  Select,
  Collapse,
  Breadcrumb,
  Menu,
  // Icon,
  Button,
  Statistic,
  Popconfirm,
  Card,
  notification,
} from "antd";
// import { Link } from "react-router"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  resetGenralPromo,
  getGenralPromoCodes,
  deletePromocode,
  getPermissionsUpdate,
  getPermissionsView,
  getPermissionsCreate,
  getPermissionsDelete,
  getPermissionsCreatePromo,
  getPermissionsDeletePromo,
  getPermissionsUpdatePromo,
  getPermissionsViewPromo,
} from "../../actions/promoActions";
import moment from "moment";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Countdown = Statistic.Countdown;
const FormItem = Form.Item;
const Panel = Collapse.Panel;
const { Option } = Select;

// @connect((store) => {
//   console.log(store);
//   return {
//     promo: store.promo.data,
//     lp_loading : store.promo.lp_loading,
//     prmsn:store.promo
//   };
// })

let GeneralPromocodeClass = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  console.log(props);

  let add = () => {
    console.log(props);
    navigate("/promocode/generalpromocode/add");
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  useEffect(() => {
    console.log(props.prmsn);
    if (props.prmsn.isCreatePromo == null) {
      props.dispatch(getPermissionsUpdatePromo());
      props.dispatch(getPermissionsCreatePromo());
      props.dispatch(getPermissionsDeletePromo());
    }
    props.dispatch(getGenralPromoCodes());
    console.log("GrandChild did mount.");
  }, []);
  useEffect(() => {
    props.promo.promos = null;
    props.dispatch(resetGenralPromo());
  }, [location]);

  let checkSearchInput = (rule, value, callback) => {
    callback();
  };

  let copyToClipboard = (e) => {
    var textField = document.createElement("textarea");
    var code = e.target.innerHTML;
    textField.innerText = code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    e.target.focus();
    copiedToClipboardNotify(code);
  };

  let copiedToClipboardNotify = (code) => {
    var msg = code + " Successfully Copied to your Clipboard";
    notification["success"]({
      message: "Successfully Copied",
      description: msg,
    });
  };

  let deletePromocodeFunc = (_promo_id) => {
    props.dispatch(deletePromocode(_promo_id, 1));
  };

  const { Content } = Layout;
  var specialpromo_arr =
    props && props.promo && props.promo.promos ? props.promo.promos : [];
  const promos = specialpromo_arr.map((promo, id) => {
    var deletePromoMsg = "Do you really want to delete this promocode?";
    var CardTitle = (
      <div className="promocode-head-elem-container">
        <Countdown
          className="CountDownBadge"
          value={promo.expirydate}
          format="HH:mm:ss"
        />
        {props.prmsn.isDeletePromo ? (
          <Popconfirm
            placement="leftTop"
            title={deletePromoMsg}
            onConfirm={() => deletePromocodeFunc(promo._id)}
            okText="Yes"
            cancelText="No"
          >
            {/* <Icon className="remove-icon" type="close" /> */}
            <CloseCircleOutlined />
          </Popconfirm>
        ) : (
          <div></div>
        )}
      </div>
    );
    return (
      <div
        className={["col-md-12", "col-lg-4", "col-sm-12", "col-xs-12"].join(
          " "
        )}
        key={id}
      >
        <div className="special-promocode-container">
          <Card
            className="special-promocode-show-card"
            title={CardTitle}
            bordered={true}
          >
            <div className="center">
              <p className={["specialpromocode-text", promo.status].join(" ")}>
                <span onClick={copyToClipboard}>{promo.code}</span>
              </p>
            </div>
            <p className="special-promocode-detail">
              Discount:{" "}
              <strong>
                {promo.value}{" "}
                {promo.valuetype == 1 ? "%" : `${promo.curr || ""}`}
              </strong>
            </p>
            <p className="special-promocode-detail">
              Status: <strong>{promo.status}</strong>
            </p>
            {(promo.type == 5 || promo.type == "5") && promo.course_name ? (
              <div>
                <p className="special-promocode-detail">
                  Type: <strong>Course Specific</strong>
                </p>
                <p className="special-promocode-detail specialpromo-course-name">
                  Course Name: <strong>{promo.course_name}</strong>
                </p>
              </div>
            ) : (
              <p className="special-promocode-detail">
                Type: <strong>{promo.type_msg}</strong>
              </p>
            )}
            <p className="special-promocode-detail">
              Payment Type:{" "}
              <strong>
                {(promo.payment_type == 0 || promo.payment_type == "0") &&
                  "All Payments"}
                {(promo.payment_type == 1 || promo.payment_type == "1") &&
                  "Full Payment"}
                {(promo.payment_type == 2 || promo.payment_type == "2") &&
                  "Installments"}
                {(promo.payment_type == 3 || promo.payment_type == "3") &&
                  "Loan"}
              </strong>
            </p>
            {props.prmsn.isUpdatePromo ? (
              <strong>
                <Link to={"/promocode/generalpromocode/edit/" + promo._id}>
                  Edit
                </Link>
              </strong>
            ) : (
              <strong></strong>
            )}
          </Card>
        </div>
      </div>
    );
  });

  return (
    <div>
      <Breadcrumb style={{ margin: "12px" }}>
        <Breadcrumb.Item onClick={() => navigate("/")}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/promocode")}>
          Promocode
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate("/promocode/generalpromocode")}
        >
          General Promocode
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content className="content-contriner">
        {props.prmsn.isCreatePromo ? (
          <Button className="add-promocode-btn" onClick={add}>
            {/* <Icon type="plus" />  */}
            <PlusOutlined />
            Add Promocode
          </Button>
        ) : (
          <Button className="add-promocode-btn">
            No Permission to Create Promocode
          </Button>
        )}
        <Divider />
        <div className="promos">
          <Spin size="large" spinning={props.lp_loading}>
            <div className="row">{promos}</div>
          </Spin>
        </div>
      </Content>
    </div>
  );
};

// const GeneralPromocode = Form.create()(GeneralPromocodeClass)

const mapStateToProps = (state) => {
  console.log(state);
  return {
    promo: state.promo.data,
    lp_loading: state.promo.lp_loading,
    prmsn: state.promo,
  };
};
export default connect(mapStateToProps)(GeneralPromocodeClass);
// export default GeneralPromocode;
