import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "./fingerprint.js";
import { BrowserRouter } from "react-router-dom";
import Dashboard from "./dashboard";
import Axios from "axios";

Axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Dashboard />
    </BrowserRouter>
  </Provider>
);

registerServiceWorker();
