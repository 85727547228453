import moment from "moment";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import {
  getGroupDetails,
  createGeneralPromo,
  resetPromoState,
} from "../../actions/promoActions";
import AddForm from "./components/addForm_general";
import {
  Form,
  Input,
  Spin,
  Layout,
  Tooltip,
  Breadcrumb,
  Icon,
  DatePicker,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const FormItem = Form.Item;
const Option = Select.Option;

// @connect((store) => {
//   console.log(store);
//   return {
//     promo: store.promo.data,
//     loading : store.promo.loading
//   };
// })

let AddSpecialPromocodeForm = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  console.log(props);
  useEffect(() => {
    if (props.promo.success) {
      props.dispatch(resetPromoState());
      navigate("/promocode/generalpromocode");
    }
  }, [props]);

  let handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        props.dispatch(getGroupDetails(values));
      }
    });
  };

  let checkGroupCode = (rule, value, callback) => {
    if (value.toString().length == 6) {
      callback();
    } else {
      callback("Group code must be 6 digits long.");
    }
  };

  let submitPromoCode = (data, genralPromForm) => {
    console.log("submitting promo code", data);
    data.exp = moment(data.exp).format("x");
    props.dispatch(createGeneralPromo(data, genralPromForm));
  };

  useEffect(() => {
    props.promo.promos = null;
    props.dispatch(resetPromoState());
  }, [location]);

  const { Content } = Layout;
  // const { getFieldDecorator } = props.form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  return (
    // Only show error after a field is touched.
    <div>
      <Breadcrumb style={{ margin: "12px" }}>
        <Breadcrumb.Item onClick={() => navigate("/")}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/promocode")}>
          Promocode
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate("/promocode/generalpromocode")}
        >
          General Promocode
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 400 }}
      >
        <Spin size="large" spinning={props.loading}>
          <div>
            <AddForm promo={{}} data={props.promo} callback={submitPromoCode} />
          </div>
        </Spin>
      </Content>
    </div>
  );
};

// const AddSpecialPromocode = Form.create()(AddSpecialPromocodeForm);

const mapStateToProps = (state) => {
  console.log({ state });
  return {
    promo: state.promo.data,
    loading: state.promo.loading,
  };
};
export default connect(mapStateToProps)(AddSpecialPromocodeForm);
// export default AddSpecialPromocode;
