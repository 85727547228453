import React, { Component, useState, useEffect } from "react";
import "./ApplyPayment.scss";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete, Chip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import { Info } from "@mui/icons-material";
import {
  fetchNextCustomerPaymentAmount,
  getManagerOtp,
  applyCustomerCashPayment,
} from "../../../actions/general";
import {
  fetchCourses,
  fetchFeeStructure,
} from "../../../actions/editFeeAction";

const style = {
  position: "absolute",
  top: "10%",
  left: "10%",
  transform: "translate(0%, -8%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const OPS_LEVEL_ONE = 21,
  OPS_LEVEL_TWO = 22,
  OPS_LEVEL_THREE = 23,
  OPS_LEVEL_FOUR = 24;
let ApplyPayment = ({
  open,
  transaction,
  user,
  paymentMethodTypeMap,
  ...props
}) => {
  let paymentModes = [
    // "PayU", //api verification
    "Cash Payment", //limit center wise //upload attachments
    "Cheque", //upload attachments
    "Loan", //upload attachments
    "UPI", //upload attachments
    "Swipe Machine", //upload attachments
    // "Trade Advance",
    "Credit/Debit Card", //remove
    "NEFT/RTGS", //remove
  ];
  console.log({ transaction, user, props, paymentMethodTypeMap });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentType, setPaymentType] = useState({});
  const [advisorPermissions, setAdvisorPermissions] = useState(
    props.permissions[OPS_LEVEL_ONE] || []
  );
  const [salesHeadPermissions, setSalesHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_TWO] || []
  );
  const [businessHeadPermissions, setBusinessHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_THREE] || []
  );
  const [financesHeadPermissions, setFinanceHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_FOUR] || []
  );
  const [expectedAmount, setExpectedAmount] = useState(0);
  const [latestPackage, setLatestPackage] = useState(null);
  // const [managerOtpSent, setManagerOtpSent] = useState(false);
  // const [otp, setOtp] = useState("");
  const [branchCollected, setBranchCollected] = useState(true);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
  const [trxnNumber, setTrxnNumber] = useState("");
  const [managerEmail, setmanagerEmail] = useState(null);
  const [emis, setEmis] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [subventionAmount, setSubventionAmount] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    console.log("componentDidMount");
    console.log({ transaction });
    props.fetchCourses();
    let packageFound = transaction.updated_package;
    // if (transaction.status != "SUCCESS") {
    let paymentTypesTemp = [...paymentTypes];
    if (
      transaction.status != "SUCCESS" &&
      !(
        transaction.full_trxn_id ||
        transaction.installmentPlanId ||
        transaction.emiPlanId
      )
    )
      paymentTypesTemp.push({ name: paymentMethodTypeMap[1], value: 1 });
    if (
      transaction.status != "SUCCESS" &&
      packageFound.reg_price &&
      !transaction.reg_trxn_id &&
      !transaction.full_trxn_id
    )
      paymentTypesTemp.push({ name: paymentMethodTypeMap[4], value: 4 });
    if (
      transaction.status != "SUCCESS" &&
      packageFound.app_fees &&
      !transaction.application_trxn_id &&
      !transaction.full_trxn_id
    )
      paymentTypesTemp.push({ name: paymentMethodTypeMap[5], value: 5 });
    if (transaction.installmentPlanId && !transaction.full_trxn_id) {
      console.log({ transaction });
      console.log({
        "transaction.updated_package": transaction.updated_package,
        "transaction.updated_package.installmentPlans":
          transaction.updated_package.installmentPlans,
      });
      let index = transaction.updated_package.installmentPlans?.findIndex(
        (plan) => plan._id == transaction.installmentPlanId
      );
      var plan;
      console.log({ index });
      if (index != -1)
        plan = transaction.updated_package.installmentPlans[index];
      console.log("settingINstallments");
      console.log({
        "transaction.updated_package.installmentPlans[index].installments":
          transaction.updated_package.installmentPlans[index].installments,
      });
      if (
        plan._id == transaction?.override?.overrides?.installmentPlan?._id &&
        plan.amountOverrideId != transaction?.override?._id
      )
        plan = transaction?.override?.overrides?.installmentPlan;
      setInstallments(plan.installments);
      if (
        transaction.status != "SUCCESS" &&
        plan &&
        transaction.installment_trxn_ids.length < plan.installments.length
      )
        paymentTypesTemp.push({ name: paymentMethodTypeMap[2], value: 2 });
    }
    if (transaction.emiPlanId && !transaction.full_trxn_id) {
      let index = transaction.updated_package.emiPlans?.findIndex(
        (plan) => plan._id == transaction.emiPlanId
      );
      var plan;
      if (index != -1) {
        plan = transaction.updated_package.emiPlans[index];
        setEmis(transaction.updated_package.emiPlans[index].emis);
      }
      if (
        transaction.status != "SUCCESS" &&
        plan &&
        transaction.emi_trxn_ids.length < plan.emis.length
      )
        paymentTypesTemp.push({ name: paymentMethodTypeMap[3], value: 3 });
    }
    console.log({ paymentTypesTemp });
    setPaymentTypes(paymentTypesTemp);
    // }
  }, []);
  useEffect(() => {
    console.log({ props });
    if (props.editfee?.coursePackages) {
      let packageIndex = props.editfee?.coursePackages.findIndex(
        (el) => el._id == transaction.updated_package._id
      );
      console.log({
        latestPackage: props.editfee?.coursePackages[packageIndex],
      });
      setLatestPackage(props.editfee?.coursePackages[packageIndex]);
    }
  });
  let markPaymentAsCompleted = async () => {
    console.log("markPaymentAsCompleted");
    if (!selectedPaymentMode)
      return alert("Please select the mode of payment collection.");
    if (!comment?.trim()) return alert("Please provide relevant Comments.");
    if (
      (selectedPaymentMode == "Cheque" ||
        selectedPaymentMode == "PayU" ||
        selectedPaymentMode == "NEFT/RTGS" ||
        selectedPaymentMode == "Credit/Debit Card" ||
        selectedPaymentMode == "Loan" ||
        selectedPaymentMode == "Trade Advance" ||
        selectedPaymentMode == "UPI" ||
        selectedPaymentMode == "Swipe Machine") &&
      !trxnNumber
    ) {
      return alert("Please input the appropriate reference number");
    }
    console.log({
      // managerOtpSent,
      salesHeadPermissions,
      businessHeadPermissions,
      financesHeadPermissions,
    });
    if (
      // !managerOtpSent &&
      salesHeadPermissions.length == 0 &&
      businessHeadPermissions.length == 0 &&
      financesHeadPermissions.length == 0
    ) {
      //advisor or lower permission
      console.log("advisor or lower permission");
      console.log({ latestPackage });
      let courseIndex = props.editFee?.courses.findIndex(
        (el) => el._id == latestPackage?.crs_pg_id
      );
      if (
        !(
          latestPackage &&
          latestPackage.scholarship_tiers.length > 0 &&
          latestPackage.scholarship_tiers[2].email
        )
      ) {
        return alert(
          `Please get the approval matrix set for the currency ${latestPackage.curr} for the course ${props.editFee?.courses[courseIndex].name}`
        );
      }
      // setLoading(true);
      // let { success, message, ...data } = await getManagerOtp({
      //   email: managerEmail,
      //   trxn_id: transaction._id,
      //   paymentType,
      //   expectedAmount,
      //   package_id: latestPackage._id,
      // });
      // setLoading(false);
      // console.log({ success, message });
      // if (!success) {
      //   if (message) {
      //     alert(message);
      //   } else {
      //     alert("Something went wrong. Please try again.");
      //   }
      //   return;
      // }
      // dispatch({
      //   type: "SHOW_NOTIFY",
      //   payload: {
      //     type: "success",
      //     message: "OTP Requested Successfully",
      //     dispatch: dispatch,
      //   },
      // });
      // return setManagerOtpSent(true);
    }
    // if (managerOtpSent && !otp)
    //   return alert("Please enter a valid 6-digit OTP");
    if (
      !window.confirm(
        `Do you confirm the user has deposited ${latestPackage.curr} ${expectedAmount}?`
      )
    ) {
      return;
    }
    setLoading(true);
    let { success, message, ...data } = await applyCustomerCashPayment({
      trxnId: transaction._id,
      aid: user._id,
      paymentType: paymentType.value,
      amount: expectedAmount,
      installmentPlanId: transaction.installmentPlanId,
      emiPlanId: transaction.emiPlanId,
      // otp,
      email: managerEmail,
      selectedPaymentMode,
      trxnNumber,
      subventionAmount,
      comment,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    let successAlertString = `Success! The payment has been recorded. The user can expect confirmation${
      branchCollected ? " and invoice" : ""
    } on his registered email Id.`;
    console.log({ successAlertString });
    alert(successAlertString);
    props.closeModal();
  };
  let fetchAmount = async () => {
    setLoading(true);
    let { success, message, ...data } = await fetchNextCustomerPaymentAmount({
      trxnId: transaction._id,
      aid: user._id,
      paymentType: paymentType.value,
      installmentPlanId: transaction.installmentPlanId,
      emiPlanId: transaction.emiPlanId,
    });
    setLoading(false);
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    let { amount, branch_collected } = data;
    setExpectedAmount(Number(amount));
    setBranchCollected(branch_collected);
    // alert("Success! The payment has been applied to the invoice.");
  };
  let convertPriceToLocalString = function (amount) {
    // console.log({amount})
    return parseFloat(String(amount)).toLocaleString("hi");
  };
  let pluralise = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };
  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };

  return (
    <Modal
      open={open}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="ApplyPayment">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Mark Transaction as Paid Undeposited Cash
              </Typography>

              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">User</TableCell>
                      <TableCell>{user?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Course</TableCell>
                      <TableCell>{transaction?.courseName}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell variant="head">Payment Type</TableCell> */}
                      {/* <TableCell>
                        {transaction?.installment_ids.length > 0
                          ? "Installments"
                          : "Full Payment"}
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Total Payable Amount</TableCell>
                      <TableCell>
                        {transaction?.updated_package.curr}{" "}
                        {transaction?.fullPayableAmount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Total Amount Paid</TableCell>
                      <TableCell>
                        {transaction?.updated_package.curr}{" "}
                        {transaction?.fullPaidAmount}
                      </TableCell>
                    </TableRow>
                    {expectedAmount ? (
                      <TableRow>
                        <TableCell variant="head">
                          Next Expected Amount
                        </TableCell>
                        <TableCell>
                          {transaction?.updated_package.curr} {expectedAmount}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {transaction?.updated_package.reg_price ? (
                      <TableRow>
                        <TableCell variant="head">
                          Registration Amount
                        </TableCell>
                        <TableCell>
                          {transaction?.updated_package.curr}{" "}
                          {convertPriceToLocalString(
                            transaction?.override?.overrides?.reg_price ||
                              transaction?.updated_package.reg_price
                          )}
                          {(transaction.installment_trxn_ids?.length ||
                            transaction.emi_trxn_ids?.length ||
                            transaction.reg_trxn_id) && (
                            <>
                              <Chip
                                label="Paid"
                                color="success"
                                avatar={<DoneIcon style={{ color: "#000" }} />}
                              />
                              {transaction.reg_trxn_id &&
                                transaction.datesMap[
                                  transaction.reg_trxn_id
                                ] && (
                                  <Chip
                                    variant="outlined"
                                    label={getDate(
                                      transaction.datesMap[
                                        transaction.reg_trxn_id
                                      ]
                                    )}
                                    color="info"
                                  />
                                )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {transaction?.updated_package.app_fees ? (
                      <TableRow>
                        <TableCell variant="head">Application Amount</TableCell>
                        <TableCell>
                          {transaction?.updated_package.curr}{" "}
                          {convertPriceToLocalString(
                            transaction?.override?.overrides?.app_fees ||
                              transaction?.updated_package.app_fees
                          )}
                          {(transaction.installment_trxn_ids?.length ||
                            transaction.emi_trxn_ids?.length ||
                            transaction.application_trxn_id) && (
                            <>
                              <Chip
                                label="Paid"
                                color="success"
                                avatar={<DoneIcon style={{ color: "#000" }} />}
                              />
                              {transaction.application_trxn_id &&
                                transaction.datesMap[
                                  transaction.application_trxn_id
                                ] && (
                                  <Chip
                                    variant="outlined"
                                    label={getDate(
                                      transaction.datesMap[
                                        transaction.application_trxn_id
                                      ]
                                    )}
                                    color="info"
                                  />
                                )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {installments && installments.length
                      ? installments.map((installment, index) => {
                          return (
                            <TableRow>
                              <TableCell variant="head">
                                {installment.alias || (
                                  <>
                                    {`${index + 1}${pluralise(index + 1)} 
                                    installment`}
                                  </>
                                )}
                              </TableCell>
                              <TableCell>
                                {transaction?.updated_package.curr}{" "}
                                {convertPriceToLocalString(installment?.amount)}
                                {transaction.installment_trxn_ids[index] && (
                                  <>
                                    <Chip
                                      label="Paid"
                                      color="success"
                                      avatar={
                                        <DoneIcon style={{ color: "#000" }} />
                                      }
                                    />
                                    {transaction.datesMap[
                                      transaction.installment_trxn_ids[index]
                                    ] && (
                                      <Chip
                                        variant="outlined"
                                        label={getDate(
                                          transaction.datesMap[
                                            transaction.installment_trxn_ids[
                                              index
                                            ]
                                          ]
                                        )}
                                        color="info"
                                      />
                                    )}
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                    {emis && emis.length
                      ? emis.map((emi, index) => {
                          return (
                            <TableRow>
                              <TableCell variant="head">{`${
                                index + 1
                              }${pluralise(index + 1)} emi`}</TableCell>
                              <TableCell>
                                {transaction?.updated_package.curr}{" "}
                                {convertPriceToLocalString(emi?.amount)}
                                {transaction.emi_trxn_ids[index] && (
                                  <>
                                    <Chip
                                      label="Paid"
                                      color="success"
                                      avatar={
                                        <DoneIcon style={{ color: "#000" }} />
                                      }
                                    />
                                    {transaction.datesMap[
                                      transaction.emi_trxn_ids[index]
                                    ] && (
                                      <Chip
                                        variant="outlined"
                                        label={getDate(
                                          transaction.datesMap[
                                            transaction.emi_trxn_ids[index]
                                          ]
                                        )}
                                        color="info"
                                      />
                                    )}
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>

              {paymentTypes.length > 0 && (
                <>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    sx={{ width: 300 }}
                    autoHighlight
                    disabled={expectedAmount}
                    value={paymentType ? paymentType.name : ""}
                    onChange={(e, op) => setPaymentType(op)}
                    id="paymentTypeSelectorModal"
                    getOptionLabel={(dt) => {
                      // console.log({ dt })
                      return dt.name || dt || "";
                    }}
                    options={paymentTypes}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label="Select the Payment Type"
                          {...params}
                          // onChange={selectCcod}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </>
              )}
              {transaction?.fullPaidAmount < transaction?.fullPayableAmount &&
              expectedAmount ? (
                <>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    multiple={false}
                    sx={{ width: 350 }}
                    autoHighlight
                    value={selectedPaymentMode}
                    onChange={(event, mode) => {
                      // console.log({mode})
                      setSelectedPaymentMode(mode);
                    }}
                    getOptionLabel={(mode) => {
                      // console.log({mode})
                      return mode || "";
                    }}
                    id="paymentModeSelector"
                    options={paymentModes}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label="Select the mode of payment"
                          {...params}
                          // onChange={(event, ccode) => {
                          //   setCcod({ ...ccode });
                          // }}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                  {(selectedPaymentMode == "Cheque" ||
                    selectedPaymentMode == "PayU" ||
                    selectedPaymentMode == "NEFT/RTGS" ||
                    selectedPaymentMode == "Credit/Debit Card" ||
                    selectedPaymentMode == "Loan" ||
                    selectedPaymentMode == "Trade Advance" ||
                    selectedPaymentMode == "Swipe Machine" ||
                    selectedPaymentMode == "UPI") && (
                    <TextField
                      label="Reference Number"
                      value={trxnNumber}
                      onChange={(e) => setTrxnNumber(e.target.value)}
                      variant="outlined"
                      type="text"
                    />
                  )}
                  {selectedPaymentMode && (
                    <>
                      <TextField
                        label="Adjustment Amount"
                        value={subventionAmount}
                        onChange={(e) => {
                          if (Number(e.target.value) < 0) {
                            return alert(
                              "Adjustment amount cannot be less than Zero."
                            );
                          }
                          if (Number(e.target.value) > expectedAmount / 5) {
                            return alert(
                              `Adjustment amount cannot be greater than ${
                                transaction?.updated_package.curr
                              } ${expectedAmount / 5}.`
                            );
                          }
                          setSubventionAmount(e.target.value);
                        }}
                        variant="outlined"
                        type="number"
                      />
                      <div
                        style={{
                          position: "relative",
                          marginLeft: "-24px",
                        }}
                      >
                        <div className="tooltipabcd">
                          <Info
                            style={{
                              color: "#1976d2",
                              marginLeft: "4px",
                              cursor: "pointer",
                              height: "24px",
                              width: "24px",
                              marginTop: "-8px",
                            }}
                          />
                          <div
                            className="tooltiptext"
                            style={{ bottom: "120 %" }}
                          >
                            <ul style={{ padding: "16px" }}>
                              <li>
                                Convenience Fees / Gateway Charges / Service
                                Fees charged by external payment services.
                              </li>
                              <li style={{ marginTop: "4px" }}>
                                Maximum of 20% of the total amount being paid
                                can be adjusted.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <Autocomplete
                    disablePortal
                    disableClearable
                    sx={{ width: 300 }}
                    autoHighlight
                    value={managerEmail}
                    onChange={(e, em) => setmanagerEmail(em)}
                    id="managerEmailSelectorModal"
                    options={latestPackage.scholarship_tiers[2].email}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label="Select the Manager Email"
                          {...params}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                  <TextField
                    label="Comments"
                    value={comment}
                    sx={{ width: 300 }}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    variant="outlined"
                    type="text"
                    multiline
                    rows={4}
                  />
                </>
              ) : null}
            </div>

            {/* {managerOtpSent && (
              <TextField
                label="OTP shared by the manager"
                value={otp}
                onChange={(e) => setOtp(Number(e.target.value))}
                variant="outlined"
                type="number"
              />
            )} */}

            {transaction?.fullPaidAmount < transaction?.fullPayableAmount ? (
              expectedAmount ? (
                paymentTypes.length > 0 &&
                managerEmail && (
                  <Button variant="contained" onClick={markPaymentAsCompleted}>
                    Apply
                  </Button>
                )
              ) : (
                paymentTypes.length > 0 && (
                  <Button variant="contained" onClick={fetchAmount}>
                    Fetch Amount
                  </Button>
                )
              )
            ) : (
              <h4
                style={{
                  margin: "2rem auto auto",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Payment is Completed!
              </h4>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyPayment);
