import React, { Component, useState, useEffect } from "react";
import "./PosConfigure.scss";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchNextCustomerPaymentAmount,
  getAllPossibleLocations,
  applyCustomerCashPayment,
  saveMposId,
} from "../../../actions/general";
import {
  fetchCourses,
  fetchFeeStructure,
} from "../../../actions/editFeeAction";

const style = {
  position: "absolute",
  top: "10%",
  left: "20%",
  transform: "translate(0%, -8%)",
  width: "60%",
  height: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const OPS_LEVEL_ONE = 21,
  OPS_LEVEL_TWO = 22,
  OPS_LEVEL_THREE = 23,
  OPS_LEVEL_FOUR = 24;
let PosConfigure = ({ open, ...props }) => {
  console.log({ props });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [advisorPermissions, setAdvisorPermissions] = useState(
    props.permissions[OPS_LEVEL_ONE] || []
  );
  const [salesHeadPermissions, setSalesHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_TWO] || []
  );
  const [businessHeadPermissions, setBusinessHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_THREE] || []
  );
  const [financesHeadPermissions, setFinanceHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_FOUR] || []
  );
  const [managerOtpSent, setManagerOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    console.log("componentDidMount");
    fetchLocations();
  }, []);
  let fetchLocations = async () => {
    setLoading(true);
    let { success, message, ...data } = await getAllPossibleLocations();
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    setLocations(data.data.allLocations);
  };
  let saveDeviceId = async () => {
    setLoading(true);
    let { success, message, ...data } = await saveMposId({
      loc_id: selectedLocation?._id,
      deviceId,
      deviceName,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Success! Mpos Device Id is set.");
    props.closeModal();
    // dispatch({
    //   type: "SHOW_NOTIFY",
    //   payload: {
    //     type: "success",
    //     message: "DeviceId saved Successfully",
    //     dispatch: dispatch,
    //   },
    // });
  };

  return (
    <Modal
      open={open}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="PosConfigure">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row" style={{ flexDirection: "column" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Set Location Wise Mpos ID
              </Typography>

              {locations?.length > 0 && (
                <>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    sx={{ width: 300 }}
                    autoHighlight
                    value={selectedLocation}
                    onChange={(e, loc) => {
                      setSelectedLocation(loc);
                      if (loc.deviceId) setDeviceId(loc.deviceId);
                    }}
                    getOptionLabel={(location) => {
                      return (
                        location.name +
                          ", " +
                          location.city +
                          ", " +
                          location.country || ""
                      );
                    }}
                    id="locationSelectorModal"
                    options={locations}
                    renderInput={(params) => {
                      return (
                        <TextField
                          label="Select the Location"
                          {...params}
                          // onChange={selectCcod}
                          variant="outlined"
                        />
                      );
                    }}
                  />

                  <TextField
                    label="Device Id of the swipe Machine"
                    variant="outlined"
                    value={deviceId}
                    onChange={(e) => setDeviceId(e.target.value)}
                    disabled={!selectedLocation?._id}
                  />
                  <TextField
                    label="Device Name of the swipe Machine"
                    variant="outlined"
                    value={deviceName}
                    onChange={(e) => setDeviceName(e.target.value)}
                    disabled={!selectedLocation?._id}
                  />
                </>
              )}
              {managerOtpSent && (
                <TextField
                  label="OTP shared by the manager"
                  value={otp}
                  onChange={(e) => setOtp(Number(e.target.value))}
                  variant="outlined"
                  type="number"
                />
              )}
            </div>

            {selectedLocation && deviceId && (
              <Button variant="contained" onClick={saveDeviceId}>
                Apply
              </Button>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(PosConfigure);
