import React, { useState, useEffect } from "react";
import "./PseudoBatchEdit.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  getPseudoBatches,
  updatePseudoBatch,
} from "../../../../actions/general";
import { Close } from "@mui/icons-material";

let PseudoBatchEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const [proposedBatches, setProposedBatches] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [launchDates, setLaunchDates] = useState([]);
  const [selectedLaunchDate, setSelectedLaunchDate] = useState("");
  const [displayLocations, setDisplayLocations] = useState({});

  useEffect(() => {
    getBatches();
  }, []);

  const getBatches = async () => {
    setLoading(true);
    const { success, message, ...data } = await getPseudoBatches({
      trxn_id: props.transaction._id,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    setProposedBatches(data.proposedBatches);
    let websiteNameByLocation = {};
    let citySlugByLocation = {};
    data.proposedBatches.forEach((batch) => {
      websiteNameByLocation[batch.location] = batch.websiteLocation;
    });
    data.proposedBatches.forEach((batch) => {
      citySlugByLocation[batch.location] = batch.citySlug;
    });
    let locationstemp = data.proposedBatches.map((batch) => batch.location);
    locationstemp = locationstemp.filter(
      (el, ind) => locationstemp.indexOf(el) == ind
    );
    let locationDisplayTempByLocation = {};
    console.log({ locationstemp, websiteNameByLocation, citySlugByLocation });
    locationstemp.forEach((lc) => {
      let locArr = lc.split(",");
      if (websiteNameByLocation[lc]) {
        locArr[0] = websiteNameByLocation[lc];
      }
      let finalArr = locArr.join(",");

      if (citySlugByLocation[lc]) {
        finalArr = citySlugByLocation[lc];
      }
      locationDisplayTempByLocation[lc] = finalArr;
    });
    console.log({ locationDisplayTempByLocation });
    setDisplayLocations(locationDisplayTempByLocation);
    let websiteLocationstemp = data.proposedBatches.map((batch) => {
      if (batch.citySlug) {
        return batch.citySlug;
      } else if (batch.websiteLocation) {
        return batch.websiteLocation;
      } else {
        return "null";
      }
    });
    setLocations(locationstemp);
    console.log({ locationstemp });
    filterLaunchDates(selectedLocation, data.proposedBatches);

    let idx = -1;

    if (props.transaction?.proposed_batch_id) {
      idx = data.proposedBatches.findIndex(
        (batch) => batch._id == props.transaction?.proposed_batch_id
      );
    }

    console.log({ idx });

    if (idx != -1) {
      setSelectedLocation(data.proposedBatches[idx].location);
      const filteredDates = filterLaunchDates(
        data.proposedBatches[idx].location,
        data.proposedBatches
      );
      var dateIdx = 0;
      if (filteredDates) {
        dateIdx = filteredDates.findIndex(
          (dt) => data.proposedBatches[idx].launchDate == dt
        );
      }
      setSelectedLaunchDate(filteredDates.length ? filteredDates[dateIdx] : "");
    } else {
      // let loc = props.basicLocation || null;
      // if (loc) {
      //   loc = loc.trim().toLowerCase();
      // }
      // const websiteNameIndex = websiteLocationstemp.findIndex((loct) => {
      //   return loct.toLowerCase() == loc;
      // });
      // let index = -1;
      // if (websiteNameIndex != -1) {
      //   locationstemp.forEach((loc_tmp, temp_index) => {
      //     let idx = props.proposedBatches.findIndex((btch) => {
      //       return (
      //         btch.location == loc_tmp &&
      //         (btch.citySlug
      //           ? btch.citySlug == websiteLocationstemp[websiteNameIndex]
      //           : btch.websiteLocation ==
      //             websiteLocationstemp[websiteNameIndex])
      //       );
      //     });
      //     if (idx != -1) {
      //       index = temp_index;
      //     }
      //   });
      // }
      // console.log({
      //   batches: props.proposedBatches,
      //   websiteLocationstemp,
      //   loc,
      //   websiteNameIndex,
      //   index,
      // });
      // if (index != -1) {
      //   setSelectedLocation(locationstemp[index]);
      //   const filteredDates = filterLaunchDates(locationstemp[index]);
      //   setSelectedLaunchDate(filteredDates.length ? filteredDates[0] : "");
      // } else {
      //   if (locationstemp.length == 1) {
      //     setSelectedLocation(locationstemp[0]);
      //     const filteredDates = filterLaunchDates(locationstemp[0]);
      //     setSelectedLaunchDate(filteredDates.length ? filteredDates[0] : "");
      //   }
      // }
    }
  };

  let filterLaunchDates = (location, proposedBatches) => {
    console.log("filterLaunchDates::");
    console.log({ location, proposedBatches });
    let filteredBatches = proposedBatches.filter(
      (el) => !location || el.location == location
    );
    console.log({ filteredBatches });
    let launchDatestemp = filteredBatches.map((batcht) => batcht.launchDate);
    launchDatestemp = launchDatestemp.filter(
      (el, ind) => launchDatestemp.indexOf(el) == ind
    );
    setLaunchDates(launchDatestemp);
    console.log({ launchDatestemp });
    return launchDatestemp;
  };

  const updateBatch = async () => {
    if (!selectedLocation || !selectedLaunchDate) {
      return alert("Please select a Pseudo Batch");
    }
    let idx = proposedBatches.findIndex(
      (dt) =>
        dt.location == selectedLocation && dt.launchDate == selectedLaunchDate
    );
    if (idx == -1) {
      return alert("Please select a valid Pseudo Batch");
    }
    if (!window.confirm("Are you sure, you want to update the Pseudo Batch")) {
      return;
    }
    setLoading(true);
    const { success, message, ...data } = await updatePseudoBatch({
      trxn_id: props.transaction._id,
      proposed_batch_id: proposedBatches[idx]._id,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Pseudo Batch Updated Successfully");
    props.refresh();
  };

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="PseudoBatchEdit">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#3C4852",
            }}
          >
            Edit Pseudo Batch
          </Typography>

          <div>
            <Close
              style={{
                color: "#212A39",
                marginLeft: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.closeModal();
              }}
            />
          </div>
        </div>
        <hr style={{ width: "100%", margin: "0" }} />
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ margin: "0 24px" }}>
            <div
              style={{
                fontWeight: "600",
                fontSize: "18px",
                marginBottom: "12px",
              }}
            >
              Select Location And Date
            </div>
            <div>
              <select
                autoComplete="on"
                name="location_selector"
                id="location_selector"
                onChange={(e) => {
                  let entry = e.target.value;
                  setSelectedLocation(entry);
                  console.log("clearing selectedLaunchDate");
                  const filteredDates = filterLaunchDates(
                    entry,
                    proposedBatches
                  );
                  setSelectedLaunchDate(
                    filteredDates.length ? filteredDates[0] : ""
                  );
                }}
                value={selectedLocation}
                style={{
                  padding: "4px 8px",
                  height: "40px",
                  textTransform: "capitalize",
                }}
              >
                <option value="" disabled>
                  Select location
                </option>
                {locations &&
                  locations.map((entry, index) => {
                    return (
                      <option
                        key={entry}
                        value={entry}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {displayLocations[entry]}
                      </option>
                    );
                  })}
              </select>
              <select
                autoComplete="on"
                name="date_selector"
                id="date_selector"
                onChange={(e) => {
                  let entry = e.target.value;
                  setSelectedLaunchDate(entry);
                }}
                value={selectedLaunchDate}
                disabled={selectedLocation ? false : true}
                style={{
                  padding: "4px 8px",
                  height: "40px",
                  marginLeft: "24px",
                  width: "140px ",
                }}
              >
                <option value="" disabled>
                  Batch date
                </option>
                {launchDates &&
                  launchDates.map((entry, index) => {
                    return (
                      <option
                        key={entry}
                        value={entry}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {entry}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div style={{ margin: "24px 0" }}>
              Note: If Payment Type is Pay Via Installments, changing the Pseudo
              Batch will affect the due dates of the unpaid installments as
              well. If not needed please override the dates accordingly.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <button
                onClick={updateBatch}
                style={{
                  width: "166px",
                  color: "white",
                  backgroundColor: "#055646",
                  borderRadius: "4px",
                  padding: "6px 20px",
                  textAlign: "center",
                  marginLeft: "16px",
                }}
              >
                Update
              </button>
              <button
                onClick={() => {
                  props.closeModal();
                }}
                style={{
                  width: "166px",
                  color: "white",
                  backgroundColor: "#6C757D",
                  borderRadius: "4px",
                  padding: "6px 20px",
                  textAlign: "center",
                  marginLeft: "16px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PseudoBatchEdit;
