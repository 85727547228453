import React from "react";
import "../../index.css";

export default function Loader(_p) {
  console.log(_p);
  return _p && (_p.loading === true || _p.loading === "true") ? (
    <div>
      <div className="visible-loader">
        <div className="loading-icon"></div>
        <div className="loading-rotate"></div>
      </div>
    </div>
  ) : null;
}
