import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  Input,
  Tooltip,
  Breadcrumb,
  Icon,
  DatePicker,
  Select,
  Button,
} from "antd";
import {
  Autocomplete,
  TextField,
  Stack,
  Switch,
  Typography,
  InputAdornment,
} from "@mui/material";
const FormItem = Form.Item;
const Option = Select.Option;
import { fetchCourses } from "../../../actions/applicationFormAction";
import { getCourseAndAccount } from "../../../actions/general";

let AddPromocodeForm = (props) => {
  const [form] = Form.useForm();
  console.log(props);
  const [showCourseUTagField, setShowCourseUTagField] = useState(false);
  const [d_type, setD_type] = useState(props.promo.valuetype || false);
  const [promo, setPromo] = useState({});
  const [paymentType, setPaymentType] = useState(
    parseInt(props.promo.payment_type) || 0
  );
  const [installmentPlanId, setInstallmentPlanId] = useState(
    props.promo.installmentPlanId || ""
  );
  const [courses, setCourses] = useState(props.appreview.courses || []);
  const [course, setCourse] = useState(null);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    if (props.promo.code) {
      initializePromo(props);
    }
    props.fetchCourses();
  }, []);
  useEffect(() => {
    (async function () {
      if (course?._id) {
        // setLoading(true);
        let { success, message, ...data } = await getCourseAndAccount({
          cid: course._id,
        });
        // setLoading(false);
        console.log({ success, message });
        if (success == false) {
          if (message) {
            window.alert(message);
          } else {
            window.alert("Something went wrong. Please try again.");
          }
        } else {
          console.log({ data });
          console.log({ plans: data.packages });
          setPackages(data.packages);
          let packageFound = data.packages.find(
            (el) => el.curr == props.promo.curr
          );
          console.log({ packageFound });
          setSelectedPackage(packageFound);
        }
      }
    })();
  }, [course]);
  useEffect(() => {
    console.log({ paymentType });
  }, [paymentType]);
  useEffect(() => {
    console.log({
      "selectedPackage?.installmentPlans": selectedPackage?.installmentPlans,
    });
  }, [selectedPackage]);

  let initializePromo = (props) => {
    setPromo(props.promo);
    setShowCourseUTagField(props.promo.type == 5 || props.promo.type == "5");
    let courseFound = props.appreview.courses.find(
      (el) => el.u_tag == props.promo.u_tag
    );
    console.log({ courseFound });
    setCourse(courseFound);
  };

  let handleSubmit = (values) => {
    // e.preventDefault();
    console.log({ values, d_type });
    let time = new Date().getTime();

    if (course) {
      values = { ...values, crs_pg_id: course._id };
    }

    if (props.edit) {
      if (values.exp.$d) {
        time = new Date(values.exp.$d).getTime();
        console.log({ time });
      } else {
        time = props.promo.expirydate;
      }
    } else {
      if (values.exp.$d) {
        time = new Date(values.exp.$d).getTime();
        console.log({ time });
      }
    }
    values = { ...values, exp: time };
    console.log({ props: props });
    values = { ...values, d_typ: d_type ? 2 : 1 };
    if (d_type) {
      values = {
        ...values,
        curr: selectedPackage?.curr,
        pkg_id: selectedPackage?._id,
      };
    }
    if (paymentType == 2 && installmentPlanId) {
      values = {
        ...values,
        curr: selectedPackage?.curr,
        pkg_id: selectedPackage?._id,
        installmentPlanId,
      };
    }
    console.log({ values });
    props.callback(values, form);
    // props.form.validateFields((err, values) => {
    //   if (!err) {
    //   }
    // });
  };

  let checkPromoCode = (rule, value, callback) => {
    if (value && value.toString().length == 11) {
      callback();
    } else {
      callback("Promocode must be 11 digits long");
    }
  };

  let promoCodeUpper = (e) => {
    if (e.target.value) {
      e.target.value = e.target.value.toUpperCase();
    } else {
      e.target.value = "";
    }
  };

  let checkDiscountAmount = (rule, value, callback) => {
    if (!value) {
      return;
    }
    if (d_type) {
      if (selectedPackage && value > selectedPackage.price)
        callback(
          `Discount amount can not be more than ${selectedPackage.curr} ${selectedPackage.price}`
        );
    } else {
      if (value > 60) callback("Percentage amount can not be more than 60");
    }
    callback();
  };

  let handleTypeChange = (e) => {
    setShowCourseUTagField(e == 5);
  };

  let handleDiscountTypeChange = (e) => {
    setD_type(e);
  };

  let validDiscount = (e) => {
    if (e && e.target && e.target.value) {
      e.target.value = Math.floor(e.target.value);
      e.target.value = Math.abs(e.target.value);
    }
  };

  let onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const { plugins } = props.data;
  // const { getFieldDecorator} = props.form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 6,
      },
    },
  };

  return (
    <div>
      <Form
        layout="horizontal"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        form={form}
        initialValues={{
          typ: props.promo.type ? String(props.promo.type) : undefined,
          code: props.promo.code,
          u_tag: props.promo.u_tag,
          desc: props.promo.desc,
          val: props.promo.value,
          d_typ: JSON.stringify(props.promo.valuetype),
          exp: props.promo.expirydate
            ? moment(props.promo.expirydate)
            : undefined,
          paymentType: props.promo.payment_type
            ? parseInt(props.promo.payment_type)
            : null,
          installmentPlanId: props.promo.installmentPlanId
            ? props.promo.installmentPlanId
            : 0,
        }}
      >
        <FormItem
          {...formItemLayout}
          label="Type of Promocode"
          name="typ"
          rules={[
            { required: true, message: "Please select type of promocode" },
          ]}
        >
          <Select
            onChange={handleTypeChange.bind(this)}
            name="typ"
            placeholder="Please select type"
          >
            <Option value="1">Generic</Option>
            <Option value="5">Course Specific</Option>
            <Option value="6">All Course Specific</Option>
          </Select>
        </FormItem>

        {showCourseUTagField && (
          <FormItem
            {...formItemLayout}
            label="Course"
            name="course"
            rules={[{ required: true, message: "Select course" }]}
          >
            <Autocomplete
              disablePortal
              fullWidth
              autoHighlight
              value={course}
              multiple={false}
              onChange={(event, selectedCourse) => {
                setCourse(selectedCourse);
              }}
              getOptionLabel={(selectedCourse) => {
                return selectedCourse?.name ? `${selectedCourse.name}` : "";
              }}
              id="courseSelectorModal"
              options={props.appreview.courses}
              renderInput={(params) => {
                return (
                  <TextField
                    label="Select Course"
                    {...params}
                    variant="outlined"
                  />
                );
              }}
            />
          </FormItem>
        )}

        <FormItem
          {...formItemLayout}
          label="Promo Code"
          name="code"
          rules={[
            { required: true, message: "Please input promo code" },
            {
              validator: checkPromoCode,
            },
          ]}
        >
          <Input
            type="text"
            placeholder="ex-IL000XXXXXX"
            onChange={promoCodeUpper.bind(this)}
          />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Description"
          name="desc"
          rules={[
            { required: true, message: "Please enter promocode description" },
          ]}
        >
          <Input type="textarea" placeholder="Promocode description" />
        </FormItem>

        <FormItem
          {...formItemLayout}
          label="Type of Discount"
          name="d_typ"
          // rules={[
          //   { required: true, message: "Please select type of discount" },
          // ]}
        >
          {/* <Select
            onChange={handleDiscountTypeChange.bind(this)}
            placeholder="Please select Discount type"
          >
            <Option value="1">Percentage Discount</Option>
            <Option value="2">Net Discount</Option>
          </Select> */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Percentage Discount</Typography>
            <Switch
              disabled={!showCourseUTagField}
              defaultChecked={d_type}
              onClick={(ev) => {
                console.log({ ev: ev?.target?.value });
                setD_type((el) => !el);
              }}
            />
            <Typography>Net Discount</Typography>
          </Stack>
        </FormItem>
        {showCourseUTagField && (paymentType == 2 || d_type) && (
          <>
            <Autocomplete
              disablePortal
              fullWidth
              autoHighlight
              multiple={false}
              value={selectedPackage}
              onChange={(event, coursePackage) => {
                setSelectedPackage(coursePackage);
              }}
              getOptionLabel={(coursePackage) => {
                return coursePackage?.curr ? `${coursePackage.curr}` : "";
              }}
              sx={{ m: 1, width: "25ch" }}
              style={{ marginLeft: "25%" }}
              id="packageSelectorModal"
              options={packages}
              renderInput={(params) => {
                return (
                  <TextField
                    label="Select a Currency"
                    {...params}
                    variant="outlined"
                  />
                );
              }}
            />
          </>
        )}
        {showCourseUTagField && d_type && selectedPackage && (
          <FormItem
            {...formItemLayout}
            label="Discount Amount"
            name="val"
            rules={[
              { required: true, message: "Please input Discount Amount" },
              {
                validator: checkDiscountAmount,
              },
            ]}
          >
            {/* <Input
                  type="number"
                  onChange={validDiscount}
                  placeholder="Discount Amount"
                /> */}
            <TextField
              type="number"
              label="Discount Amount"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {selectedPackage?.currSymbol}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              // disabled={!coursePackage?.reg_price}
              sx={{ m: 1, width: "25ch" }}
              onChange={validDiscount}
              // value={
              //   (coursePackage &&
              //     formatAmount(coursePackage?.min_token_amount)) ||
              //   0
              // }
              // error={
              //   coursePackage?.min_token_amount < 0 ||
              //   coursePackage?.min_token_amount > coursePackage?.reg_price
              // }
              // helperText={coursePackage?.min_token_amount < 0? 'Amount should be a positive non-zero amount.': coursePackage?.min_token_amount > coursePackage?.reg_price? `Amount should be a less than the registration amount (${selectedCurrency?.symbol} ${coursePackage?.reg_price}).`:''}
            />
          </FormItem>
        )}
        {!d_type && (
          <FormItem
            {...formItemLayout}
            label="Discount Percentage"
            name="val"
            rules={[
              { required: true, message: "Please input Discount Percentage" },
              {
                validator: checkDiscountAmount,
              },
            ]}
          >
            <Input
              type="number"
              onChange={validDiscount}
              placeholder="Discount Percentage"
            />
          </FormItem>
        )}

        <FormItem
          {...formItemLayout}
          label="Payment Type"
          name="paymentType"
          rules={[
            {
              required: true,
              message: "Please Select Payment Type Constraint",
            },
          ]}
        >
          <Select
            onChange={(val) => {
              console.log("settingVal: ", { val });
              setPaymentType(val);
            }}
            name="paymentType"
            placeholder="Please select payment type Constraint"
            // defaultValue="2"
            value={paymentType}
            options={[
              {
                value: 0,
                label: "All Payment Type (Default)",
              },
              {
                value: 1,
                label: "Full Payment Only",
              },
              {
                value: 2,
                label: "Payment by Installments Only",
              },
              {
                value: 3,
                label: "Payment by Loan Only",
              },
            ]}
          />
        </FormItem>
        {paymentType == 2 &&
        selectedPackage &&
        selectedPackage?.installmentPlans.length > 0 ? (
          <FormItem
            {...formItemLayout}
            label="Installment Plan"
            name="installmentPlanId"
            rules={[
              {
                required: true,
                message: "Please Select Installment Plan Constraint",
              },
            ]}
          >
            <Select
              onChange={(val) => setInstallmentPlanId(val)}
              name="paymentType"
              placeholder="Please select payment plan Constraint"
              // defaultValue="2"
              value={installmentPlanId}
              options={
                selectedPackage?.installmentPlans.map((plan) => {
                  return {
                    value: plan._id,
                    label: `${plan.period} ${plan.periodType}s, ${plan.installments.length} installments, amount: ${plan.amount}`,
                  };
                }) || []
              }
            />
          </FormItem>
        ) : null}

        <FormItem
          {...formItemLayout}
          label="Expiry Date"
          name="exp"
          rules={[
            {
              type: "object",
              required: true,
              message: "Please select time!",
            },
          ]}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="Select Time"
            onChange={(val) => console.log({ val })}
          />
        </FormItem>

        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={false}>
            {props.edit == true ? "Edit" : "Submit"} Promocode
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

// const AddForm = Form.create()(AddPromocodeForm);

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
  };
};

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPromocodeForm);
