import React from "react";
import { connect } from "react-redux";
import ApplicationSideBar from "./ApplicationSideBar";
import ApplicationTable from "./ApplicationTable";
import Alert from "./alert";
import Loader from "./Loader";

class ApplicationsReview extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
  }

  render() {
    console.log(this.props);
    return (
      <div
        className="ar-contanier"
        style={{ position: "fixed", left: "0", right: "0" }}
      >
        <Alert />
        <Loader loading={this.props.appreview.arloading} />

        <div className="ar-header">Applications Review</div>
        <div className="row">
          <div className=" col-sm-12  col-md-12 col-lg-2   ar-sidebar">
            <ApplicationSideBar />
          </div>
          <div className="col-md-12  col-lg-10 col-xl-10 col-sm-12 no-padding">
            <ApplicationTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appreview: state.appreview,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsReview);
