import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  getSubSalesGroup,
  getAdvisors,
  getSubSalesGroupTargetRevenue,
} from "../helper";

function ViewProgramAllocation(props) {
  const [loading, setLoading] = useState(false);
  const [subSalesGroupArray, setSubSalesGroupArray] = useState();
  const [subSalesGroup, setSubSalesGroup] = useState();
  const [month, setMonth] = useState(null);
  const [targetData, setTargetData] = useState(null);
  const [advisor, setAdvisor] = useState();

  useEffect(() => {
    SalesGroup().then((data) => {
      setSubSalesGroupArray(data.data);
      setLoading(false);
    });

    async function SalesGroup() {
      setLoading(true);
      return await getSubSalesGroup(props.businessHead);
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    let subSalesGroupId = JSON.parse(subSalesGroup)._id;
    setLoading(true);
    let res = await getSubSalesGroupTargetRevenue(subSalesGroupId, month);
    console.log(res);
    if (res.success == true) {
      if (res.data.length > 0) {
        let advisors = await getAdvisors(subSalesGroupId, props.businessHead);
        setTargetData(res.data);
        setAdvisor(advisors.data);
      } else {
        alert(
          "Selected Sub Sales Group with respect to Date does not have any Program Allocation"
        );
        setTargetData(null);
      }
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}

      <div>
        <form
          onSubmit={submitHandler}
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <div
            className="addprogram2container"
            style={{
              flexDirection: "row",
              paddingBottom: "0px",
              paddingTop: "16px",
            }}
          >
            <div>
              <p className="addadmissiontargetmonth">Sub Sales Group</p>
              <div>
                <select
                  className="timeleadtabledrop"
                  value={subSalesGroup && subSalesGroup.subSalesGroupName}
                  onChange={(e) => {
                    setSubSalesGroup(e.target.value);
                  }}
                  name="leadsource"
                  id="leadsource"
                  required
                >
                  <option disabled selected value="">
                    Sub Sales Group
                  </option>
                  {subSalesGroupArray &&
                    subSalesGroupArray.map((data, ind) => {
                      return (
                        <option key={ind} value={JSON.stringify(data)}>
                          {data.email}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div>
              <p className="addadmissiontargetmonth">Sales Head</p>
              <div>
                <input
                  className="addadmissiontargetmonthdropdown"
                  type="text"
                  disabled
                  id="month"
                  name="month"
                  defaultValue={
                    subSalesGroup && JSON.parse(subSalesGroup).email
                  }
                  value={subSalesGroup && JSON.parse(subSalesGroup).email}
                />
              </div>
            </div>
            <div>
              <p className="addadmissiontargetmonth">Select Month</p>
              <div>
                <input
                  className="addadmissiontargetmonthdropdown"
                  type="month"
                  id="month"
                  name="month"
                  value={month}
                  required
                  onChange={(e) => setMonth(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ marginRight: "20px" }}>
            <button
              onClick={submitHandler}
              style={{ background: "#035642" }}
              className="addprogramsubmitbtn"
            >
              {" "}
              View{" "}
            </button>
          </div>
        </form>

        <div>
          {targetData ? (
            <>
              <div
                style={{
                  maxHeight: "500px",
                  margin: "22px 16px",
                  overflow: "scroll",
                }}
              >
                <div
                  className=""
                  style={{ overflow: "scroll", width: "100%", height: "100%" }}
                >
                  <div style={{ width: "1200px" }}>
                    <table className="table table-striped groupleadtable">
                      <thead>
                        <tr className="">
                          <th
                            scope="col"
                            style={{
                              width: "300px",
                              padding: "0px 24px",
                              height: "60px",
                              textAlign: "initial",
                            }}
                          >
                            Advisors
                          </th>
                          {targetData &&
                            targetData.map((data, ind) => {
                              return (
                                <th
                                  scope="col"
                                  key={ind}
                                  style={{ width: "184px" }}
                                >
                                  {data.programLSKey}
                                </th>
                              );
                            })}
                        </tr>

                        {advisor &&
                          advisor
                            .sort((a, b) => a.email.localeCompare(b.email))
                            .map((data2, ind2) => {
                              return (
                                <tr
                                  key={ind2}
                                  style={{ backgroundColor: "#ffff" }}
                                >
                                  <th
                                    style={{
                                      height: "60px",
                                      textAlign: "initial",
                                    }}
                                  >
                                    {data2.email}
                                  </th>

                                  {targetData &&
                                    targetData.map((data, ind) => {
                                      return (
                                        <th
                                          scope="col"
                                          key={ind}
                                          style={{ width: "184px" }}
                                        >
                                          {data.allocaterAdvisorsArray.includes(
                                            data2._id
                                          )
                                            ? "Yes"
                                            : "-"}
                                        </th>
                                      );
                                    })}
                                </tr>
                              );
                            })}
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                marginTop: "24px",
                textAlign: "center",
                marginBottom: "24px",
              }}
            >
              <h5>Please Select a Sub Sales Group and Month</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewProgramAllocation;
