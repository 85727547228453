import React, { useState, useEffect } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  fetchApplicationDocuments,
  getCaptchaSiteKey,
} from "../../actions/general";
import { connect } from "react-redux";
import Loader from "../../scenes/applicationform/Loader";

const ApplicationDocumentDownload = (props) => {
  const [appId, setAppId] = useState();
  const [captchaSiteKey, setCaptchaSiteKey] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const [displayText, setDisplayText] = useState("");

  const captcha = React.createRef();

  useEffect(() => {
    const windowUrl = window.location.pathname;
    console.log({ windowUrl });
    setAppId(windowUrl.split("/").pop());

    getCaptchaSiteKey().then((data) => {
      console.log({ data });
      setCaptchaSiteKey(data.siteKey);
    });
  }, []);

  const downloadDocuments = () => {
    if (!appId) {
      return alert("Application Id not found");
    }
    if (!captchaToken) {
      return alert("Please verify captcha first");
    }
    setDisplayText("Downloading Documents...");
    fetchApplicationDocuments({ appId, captchaToken })
      .then((res) => {
        const contentDisposition = res.headers["content-disposition"];
        let fileName = contentDisposition.split(";")[1].split("=")[1];

        console.log("File name : ", fileName);
        fileName = fileName.replaceAll('"', "");
        console.log("File name : ", fileName);

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setDisplayText(
          "Documents Downloaded Successfully. Please Check Your Downloads Folder"
        );
      })
      .catch((err) => {
        const reader = new FileReader();
        reader.readAsText(err.data);
        reader.addEventListener("loadend", (e) => {
          const data = JSON.parse(e.target.result);
          console.log({ data });
          if (data.message) {
            setDisplayText(data.message);
          } else {
            setDisplayText("Something Went Wrong. Please Try Again Later.");
          }
        });
      });
  };

  const checkVisibility = (resource) => {
    if (!props.permissions) {
      return true;
    } else {
      if (!props.permissions[resource]) {
        return false;
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleVerification = (token) => {
    setCaptchaToken(token, () => {
      console.log("Captcha token set successfully ", token);
    });
  };

  return checkVisibility("5") ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      {captchaSiteKey ? (
        <>
          <button
            onClick={downloadDocuments}
            className="ar-vw-app"
            style={{ width: "fit-content", fontSize: "28px", padding: "12px" }}
          >
            Download Documents
          </button>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "6%",
            }}
          >
            <HCaptcha
              ref={captcha}
              sitekey={captchaSiteKey}
              type="text"
              onVerify={handleVerification}
            />
          </div>
        </>
      ) : (
        <Loader loading />
      )}

      <div style={{ marginTop: "28px", fontSize: "16px" }}>{displayText}</div>
    </div>
  ) : (
    <div>You are not authorized to access this page</div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.login.permissions,
  resources: state.login.resources,
});

export default connect(mapStateToProps)(ApplicationDocumentDownload);
