import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import {
  updateApplicationSattus,
  fetchApplications,
  requestApplicationApproveOTP,
  fetchApplicationDetail,
} from "../../actions/applicationFormAction";
import { BTN_APPLICATION_STATUS } from "../../constants";
import callicon from "../../images/call.svg";
import envelopeicon from "../../images/envelope.svg";
import Loader from "../applicationform/Loader";
import { Select } from "antd";
const { Option } = Select;

class CustomisedTextModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeadEmails: false,
      currentPackage: this.props.packages.filter(
        (pkg) => pkg._id == this.props.packageId
      )[0],
      head_email: "",
      comment: "",
      otpSent: false,
      otpCode: null,
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({});
  }

  closeModal = () => {
    this.props.handleCustomisedTextModalToggle(false);
  };

  changeApplicationSattus = () => {
    this.props
      .updateApplicationSattus({
        a_id: this.props.user_data.a_id,
        crs_id: this.props.user_data.crs_id,
        app_id: this.props.user_data.app_id,
        stts:
          this.props.user_data.new_status == 6
            ? 3
            : this.props.user_data.new_status,
        o_stts: this.props.user_data.o_stts,
        otp_code: this.state.otpCode,
      })
      .then((res) => {
        if (res !== undefined && res.data && res.data.success) {
          this.props.handlModalToggle(false);
          // this.props.fetchApplications({
          //   crs_pg_id: this.props.appreview.crs_pg_id._id,
          //   search_val: this.props.appreview.searchVal,
          //   search_type: this.props.appreview.searchType,
          //   app_status: this.props.appreview.app_status
          //     ? this.props.appreview.app_status.status
          //     : undefined,
          //   appcurrent_page: 1,
          // });
          // this.props.fetchApplicationDetail({
          //   a_id: this.props.applicationDetail.a_id,
          //   crs_id: this.props.applicationDetail.crs_id,
          //   app_id: this.props.applicationDetail.user_app_id,
          // });
        } else {
          alert("Invalid OTP, Please try again");
        }
      });
  };

  requestApplicationAppOTP = () => {
    if (!this.state.head_email) {
      return alert("Please select a Head Email");
    }
    if (!this.state.comment) {
      return alert("Please enter remarks");
    }
    this.setState({
      loading: true,
    });
    this.props
      .requestApplicationApproveOTP({
        app_id: this.props.user_data.app_id,
        crs_pkg_id: this.props.packageId,
        head_email: this.state.head_email,
        comment: this.state.comment,
      })
      .then((res) => {
        if (res !== undefined && res.data && res.data.success) {
        }
      });
    this.setState({
      otpSent: true,
      loading: false,
    });
  };

  handleOtpChange = (otp) => {
    if (!otp) otp = "";
    console.log(otp, otp.replace(/[^0-9]/g, ""));
    otp = otp.replace(/[^0-9]/g, "");
    otp = otp.slice(0, 6);
    this.setState({
      otpCode: otp,
    });
  };

  render() {
    console.log(this.props);

    return (
      <div>
        <Modal
          title="Are you sure?"
          visible={this.props.open}
          onCancel={this.closeModal}
          width="38vw"
          className="applicationmodal"
        >
          <div style={{ padding: 24 }}>
            <Loader loading={this.state.loading} />
            <div className="ar-sm-u-name">
              {this.props.user_data && this.props.user_data.u_name
                ? this.props.user_data.u_name
                : null}
            </div>
            <div style={{ marginTop: "8px", color: "#055646" }}>
              <span style={{ padding: "0px 16px 0px 0px" }}>
                UID : {this.props.appreview.userinfo.uid}
              </span>
            </div>
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <img src={callicon} />
              <span style={{ padding: "0px 16px 0px 8px", color: "#055646" }}>
                {this.props.appreview.userinfo.phone_no}
              </span>
            </div>
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <img src={envelopeicon} />
              <span style={{ padding: "0px 16px 0px 8px", color: "#055646" }}>
                {this.props.appreview.userinfo.email[0]} {`(Pegasus Email)`}
              </span>
            </div>
            {this.props.appreview.userinfo.applicationEmail && (
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <img src={envelopeicon} />
                <span style={{ padding: "0px 16px 0px 8px", color: "#055646" }}>
                  {this.props.appreview.userinfo.applicationEmail}{" "}
                  {`(Application Email)`}
                </span>
              </div>
            )}
            {this.props.user_data.new_status == 6 && !this.state.otpSent ? (
              <div style={{ marginTop: "20px" }}>
                <div className="error-msg">
                  You need to get approval from the Bussiness Head
                </div>
                <Select
                  style={{ width: "100%" }}
                  className="app-dropdown"
                  value={this.state.head_email}
                  onChange={(value) => {
                    this.setState({
                      head_email: value,
                    });
                  }}
                  placeholder="Select head"
                >
                  {this.state.currentPackage.scholarship_tiers
                    .at(2)
                    .email.map((email) => {
                      return (
                        <Option key={email} value={email}>
                          {email}
                        </Option>
                      );
                    })}
                </Select>
                <div style={{ marginTop: "16px" }}>Remarks</div>
                <textarea
                  style={{
                    border: "1px solid rgba(33, 42, 57, 0.25)",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    color: "#3c4852",
                    opacity: "0.9",
                    marginBottom: "16px",
                    width: "100%",
                  }}
                  value={this.state.comment}
                  onChange={(e) => {
                    this.setState({
                      comment: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            ) : null}
            {this.state.otpSent ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    minWidth: "150px",
                    color: "#3c4852",
                    opacity: "0.9",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  Enter OTP:
                </div>
                <div
                  style={{
                    color: "#3c4852",
                    opacity: "0.9",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "16px",
                  }}
                >
                  <input
                    style={{
                      border: "1px solid rgba(33, 42, 57, 0.25)",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      color: "#3c4852",
                      opacity: "0.9",
                      marginBottom: "16px",
                    }}
                    value={this.state.otpCode}
                    onChange={(e) => {
                      this.handleOtpChange(e.target.value);
                    }}
                  />
                  <div>
                    An OTP is sent to the Bussiness Head (
                    {this.state.head_email}). OTP is valid upto 30 minutes.
                  </div>
                  <div>
                    Didn't recieve any code?
                    <button
                      style={{
                        color: "#035642",
                        background: "transparent",
                        borderRadius: "8px",
                        fontWeight: "600",
                        border: "none",
                        cursor: "pointer",
                        height: "fit-content",
                      }}
                      onClick={this.requestApplicationAppOTP}
                    >
                      Resend OTP
                    </button>
                  </div>
                  <button
                    className="btn-approve-cand"
                    onClick={this.changeApplicationSattus}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="center-all"
                style={{
                  alignItems: "flex-end",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <button
                  style={{ marginTop: 0, float: "none" }}
                  className={
                    this.props.user_data.new_status == 3 ||
                    this.props.user_data.new_status == 6
                      ? "btn-approve-cand"
                      : this.props.user_data.new_status == 4
                      ? "btn-reject-cand"
                      : "hide"
                  }
                  onClick={() => {
                    let note = "";
                    if (
                      (this.props.user_data.new_status == 3 ||
                        this.props.user_data.new_status == 6) &&
                      this.props.appreview.userinfo.enableScholarshipDiscount
                    ) {
                      note =
                        this.props.scholarship &&
                        Object.keys(this.props.scholarship).length &&
                        this.props.scholarship[this.props.packageId] &&
                        this.props.scholarship[this.props.packageId].amount
                          ? `You have applied ${this.props.currency}${
                              this.props.scholarship[this.props.packageId]
                                .amount
                            }/- scholarship amount. Are you sure you want to continue?`
                          : "You have not applied the Scholarship amount. Are you sure you want to continue?";
                    } else {
                      note = "Are you sure you want to continue?";
                    }
                    if (!note || window.confirm(note)) {
                      if (this.props.user_data.new_status == 6) {
                        this.requestApplicationAppOTP();
                      } else {
                        this.changeApplicationSattus();
                      }
                    }
                  }}
                >
                  {BTN_APPLICATION_STATUS[this.props.user_data.new_status]}
                </button>
              </div>
            )}
            <div></div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
  };
};

const mapDispatchToProps = {
  updateApplicationSattus,
  fetchApplications,
  requestApplicationApproveOTP,
  fetchApplicationDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomisedTextModal);
