import styles from "./legacyInstallment.scss";
import React, { Component, useEffect, useState } from "react";
import momentjs from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import countryCodes from "../../countryCodes";
import { getCourses, fetchTokenTransactions } from "../../actions/general";
import SendCustomPaymentLink from "../sendCustomPayemntLink/sendCustomPayemntLink";

const SearchOptions = ["Mobile", "Email", "UID"];

let LegacyInstallmentCollection = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState("Mobile");
  const [ccod, setCcod] = useState({
    code: "IN",
    value: "91",
    display: "India (+91)",
  });
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState("");
  const [warningText, setWarningText] = useState("");
  const [name, setName] = useState("");
  const [aid, setAid] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [accountDetails, setAccountDetails] = useState({});
  const [paidCourses, setPaidCourses] = useState([]);
  const [nameFetched, setNameFetched] = useState(false);
  const [applicationInitiated, setApplicationInitiated] = useState(false);
  const [packages, setPackages] = useState([]);
  const [tokenTransactions, setTokenTransactions] = useState([]);
  const [showSendLink, setShowSendLink] = useState(false);

  useEffect(() => {
    (async function () {
      var { success, message, ...data } = await getCourses(true);
      if (!success) {
        if (message) {
          console.log({ message });
        } else {
          console.log("Something Went Wrong. Please try again later.");
        }
      } else {
        console.log({ ...data });
        setPaidCourses(data["courses"]);
      }
    })();
  }, []);

  let fetchUser = async () => {
    if (!(ccod.value && mobile) && !email && !uid) {
      return alert("Please enter user details");
    }
    if (!selectedCourse) {
      return alert("Please select a course");
    }
    let search_val;
    if (searchType == "Mobile") {
      search_val = `${ccod.value}-${mobile}`;
    }
    if (searchType == "Email") {
      search_val = email;
    }
    if (searchType == "UID") {
      search_val = uid;
    }
    setName("");
    setNameFetched(false);
    setLoading(true);
    setWarningText("");
    let { success, message, ...data } = await fetchTokenTransactions({
      search_val,
      search_type: searchType,
      crs_pg_id: selectedCourse._id,
    });
    setLoading(false);
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
        setWarningText(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    let {
      tokenTransactions,
      profile,
      coursePackages,
      account,
      applicationInitiated,
    } = data;
    if (profile) {
      setName(profile.nam);
      setNameFetched(true);
    }
    if (account) {
      setAccountDetails(account);
    }
    if (coursePackages) {
      setPackages(coursePackages);
    }
    if (tokenTransactions) {
      setTokenTransactions(tokenTransactions);
    }
    setApplicationInitiated(applicationInitiated);
  };

  let selectCcod = (ev, ccod) => {
    console.log({ ev, ccod });
    setCcod({ ...ccod });
  };

  let closeModal = () => {
    console.log;
    setShowSendLink(false);
  };

  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };

  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="mainContainer">
      {showSendLink && (
        <SendCustomPaymentLink
          open={showSendLink}
          closeModal={closeModal}
          name={name}
          course={selectedCourse}
          packages={packages}
          accountDetails={accountDetails}
        />
      )}
      <h1>Legacy Installment Collection System</h1>
      <span>Please provide the user details:</span>
      <div className="row">
        <Autocomplete
          multiple={false}
          disablePortal
          disableClearable
          sx={{ width: 150 }}
          autoHighlight
          value={searchType}
          onChange={(ev, type) => {
            setName("");
            setNameFetched(false);
            setAccountDetails({});
            setPackages([]);
            setTokenTransactions([]);
            setApplicationInitiated(false);
            setSelectedCourse(null);
            setCcod({
              code: "IN",
              value: "91",
              display: "India (+91)",
            });
            setMobile("");
            setEmail("");
            setUid("");
            setSearchType(type);
          }}
          getOptionLabel={(type) => {
            return type || "";
          }}
          id="searchType"
          options={SearchOptions}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a search type"
                {...params}
                variant="outlined"
              />
            );
          }}
        />
        {searchType == "Mobile" && (
          <>
            <Autocomplete
              multiple={false}
              disablePortal
              disableClearable
              sx={{ width: 150 }}
              autoHighlight
              value={ccod}
              onChange={selectCcod}
              getOptionLabel={(ccode) => {
                return ccode?.display || "";
              }}
              id="ccodeSelector"
              options={countryCodes}
              renderInput={(params) => {
                return (
                  <TextField
                    label="Select a country code"
                    {...params}
                    variant="outlined"
                  />
                );
              }}
            />
            <TextField
              id="mobile"
              value={mobile}
              onChange={(e) =>
                e.target.value.length < 15 ? setMobile(e.target.value) : null
              }
              label="Mobile Number"
              variant="outlined"
            />
          </>
        )}
        {searchType == "Email" && (
          <>
            <TextField
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              variant="outlined"
            />
          </>
        )}
        {searchType == "UID" && (
          <>
            <TextField
              id="uid"
              value={uid}
              onChange={(e) => setUid(e.target.value)}
              label="UID"
              variant="outlined"
            />
          </>
        )}
      </div>
      <div className="row">
        <Autocomplete
          multiple={false}
          disablePortal
          disableClearable
          sx={{ width: 350 }}
          autoHighlight
          value={selectedCourse}
          onChange={(ev, course) => {
            setName("");
            setNameFetched(false);
            setAccountDetails({});
            setPackages([]);
            setTokenTransactions([]);
            setApplicationInitiated(false);
            setSelectedCourse(course);
          }}
          getOptionLabel={(course) => {
            return course?.name || "";
          }}
          id="courseSelector"
          options={paidCourses}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a Course"
                {...params}
                variant="outlined"
              />
            );
          }}
        />
      </div>
      {warningText && <span className="warning">{warningText}</span>}
      <Button variant="outlined" color="info" onClick={fetchUser}>
        Fetch User
      </Button>
      {nameFetched && (
        <TextField
          id="name"
          value={name}
          disabled={true}
          label="User's Name"
          variant="outlined"
        />
      )}

      {nameFetched ? (
        applicationInitiated ? (
          <div style={{ width: "fit-content" }}>
            <hr />
            <div className="failed">
              You cannot send custom payment link to this user as the payments
              are being done through the Admission & Payment module
            </div>
          </div>
        ) : (
          <>
            <div style={{ width: "fit-content", minWidth: "800px" }}>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="pastPayment">Past Payments</div>
                <button
                  className="sendLink"
                  onClick={() => {
                    setShowSendLink(true);
                  }}
                >
                  SEND CUSTOM PAYMENT LINK
                </button>
              </div>
              {tokenTransactions.length ? (
                <TableContainer className="mainTable" component={Paper}>
                  <Table aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          style={{ fontWeight: 600, width: "100px" }}
                        >
                          S. No.
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "200px" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "200px" }}
                        >
                          Transaction ID
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "200px" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ fontWeight: 600, width: "200px" }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <React.Fragment>
                      <TableBody>
                        {tokenTransactions.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              style={{ width: "10px" }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ width: "200px" }}
                            >
                              {getDate(row.tim)}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ width: "200px" }}
                            >
                              {row._id}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              style={{ width: "200px" }}
                            >
                              {row.currency} {row.amount}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              className={
                                row.status == "SUCCESS"
                                  ? "done"
                                  : row.status == "FAILED"
                                  ? "failed"
                                  : "pending"
                              }
                              style={{ width: "200px" }}
                            >
                              {row.status == "SUCCESS"
                                ? "Done"
                                : row.status == "FAILED"
                                ? "Failed"
                                : "Pending"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </React.Fragment>
                  </Table>
                </TableContainer>
              ) : (
                <div>No Past Token Payments found</div>
              )}
            </div>
          </>
        )
      ) : null}
    </div>
  );
};

export default LegacyInstallmentCollection;
