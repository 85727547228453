const initialState = {
  efloading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "INDIVIDUAL_FEE_STRUCTURE":
      return {
        ...state,
        feestructure: action.payload.feestructure,
        prof: action.payload.prof,
        editfeereq: action.payload.editfeereq,
      };

    case "SELECTED_COURSE":
      return {
        ...state,
        crs_pg_id: action.payload.crs_pg_id,
      };

    case "SELECTED_COURSE_PACKAGE":
      return {
        ...state,
        crs_pkg_id: action.payload.crs_pkg_id,
      };

    case "UPDATE_COURSES":
      return {
        ...state,
        courses: action.payload.courses,
        coursePackages: action.payload.coursePackages,
        editfeereq: false,
      };

    case "REQUEST_REG_FEE_OTP":
      return {
        ...state,
        editfeereq: action.payload.editfeereq,
      };

    case "VERIFY_REG_FEE_OTP":
      return {
        ...state,
        editfeereq: action.payload.editfeereq,
        feestructure: action.payload.feestructure,
      };

    case "REQUEST_APP_FEE_OTP":
      return {
        ...state,
        editfeereq: action.payload.editfeereq,
      };

    case "VERIFY_APP_FEE_OTP":
      return {
        ...state,
        editfeereq: action.payload.editfeereq,
        feestructure: action.payload.feestructure,
      };

    case "INITITATE_EF_LOADING":
      return {
        ...state,
        efloading: true,
      };

    case "STOP_EF_LOADING":
      return {
        ...state,
        efloading: false,
      };

    case "SHOW_NOTIFY": {
      return {
        ...state,
        data: {
          type: action.payload.type,
          show: true,
          message: action.payload.message,
        },
      };
    }

    case "HIDE_NOTIFY": {
      return {
        ...state,
        data: {
          show: false,
        },
      };
    }

    default:
      return state;
  }
}
