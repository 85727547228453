import React, { useState, useEffect } from "react";
import "./leaddistribution.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Loader from "./Loader";
import Pagination from "./Pagination";
import { Link, useNavigate } from "react-router-dom";
import LeadDistributionModel from "./Models/LeadDistributionModel";
import {
  getPls,
  getSalesProgram,
  getSubSalesGroup,
  getLeadCount,
  getSalesGroup,
  getSalesGroupName,
  getSubSalesGroupEmail,
  getAggregateData,
  getPgLsKeyAndCost,
  getAdvisorAggregateData,
  getParameterByName,
  convertUtcToIst,
  convertUnixTimeToIst,
} from "./helper";
import { Skeleton } from "@mui/material";
import { connect } from "react-redux";
import LeadModel from "./Models/LeadModel";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
import PreviousTargetModel from "./Models/PreviousTargetModel";
const label = { inputProps: { "aria-label": "Switch demo" } };

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: teal[600],
    "&:hover": {
      backgroundColor: alpha(teal[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: teal[600],
  },
}));

const PER_PAGE = 10;
function LeadDistributionPanel(props) {
  const checkVisibility = (resource) => {
    if (!props.permissions) {
      return true;
    } else {
      if (!props.permissions[resource]) {
        return false;
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  let navigate = useNavigate();
  const [user, setUser] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPAge] = useState(0);
  const [data, setData] = useState([]);
  const [size, setSize] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [program, setProgram] = useState("");
  const [programArray, setProgramArray] = useState("");
  const [leadData, setLeadData] = useState();
  const [subSalesArray, setSubSalesArray] = useState();
  const [salesGroupArray, setSalesGroupArray] = useState();
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [aggregateData, setAggregateData] = useState();
  const [leadManagerData, setLeadManagerData] = useState();
  const [advisorAggregateData, setAdvisorAggregateData] = useState();
  const [defaultAggregateData, setDefaultAggregateData] = useState();
  const [defaultAdvisorAggregateData, setDefaultAdvisorAggregateData] =
    useState();
  const open = Boolean(anchorEl);
  const [selectSalesGroup, setSelectSalesGroup] = useState();
  const [modelShow, setModelShow] = useState({ Boolean: true, String: null });
  const [leadModelShow, setLeadModelShow] = useState(false);
  const [currentLeadPage, setCurrentLeadPage] = useState(0);
  const [leadSize, setLeadSize] = useState(0);
  const leadPageCount = Math.ceil(leadSize / PER_PAGE);
  const [businessHead, setBusinessHead] = useState();
  const [loadingLeadData, setLoadingLeadData] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isFirstString, setIsFirstString] = useState("true");
  const [firstRender, setFirstRender] = useState(false);
  const [showPreviousTarget, setShowPreviousTarget] = useState(false);

  function handleClick3({ selected: selectedPage }) {
    console.log({ selectedPage });
    setCurrentLeadPage(selectedPage);
    getLeadCounts(selectedPage).then((data) => {
      setLeadData(data.data);
      setLoading(false);
    });

    async function getLeadCounts(selectedPage) {
      setLoading(true);
      console.log("pagination clicked");
      return await getLeadCount(
        fromDate,
        toDate,
        program,
        selectedPage,
        PER_PAGE,
        businessHead ? businessHead : "",
        isFirst
      );
    }
  }

  const pageCount = Math.ceil(size / PER_PAGE);
  function handleClick2({ selected: selectedPage }) {
    setCurrentPAge(selectedPage);
    approvallist().then((dstaus) => {
      setData(dstaus.data.data);
      setLoading(false);
    });
    async function approvallist() {
      setLoading(true);
      return await getApprovalallschema(selectedPage, PER_PAGE);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLead = () => {
    setAnchorEl(null);
    setLeadModelShow(false);
  };

  const handleOpen = (value) => {
    handleClose();
  };

  const handleModelClose = (event) => {
    setModelShow({ Boolean: false });
  };

  useEffect(() => {
    let today = new Date();
    let startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let formatDate = (date) => {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    console.log("Start of the month:", formatDate(startOfMonth));
    console.log("Today's date:", formatDate(today));

    let fromDate = getParameterByName("fromDate") || formatDate(startOfMonth);
    let toDate = getParameterByName("toDate") || formatDate(today);
    let program = decodeURIComponent(getParameterByName("program"));
    let user = getParameterByName("user");

    setFromDate(fromDate);
    setToDate(toDate);
    setProgram(program);
    setBusinessHead(user);

    salesGroup().then((data) => {
      setSalesGroupArray(data.data);
      setLoading(false);
    });

    async function salesGroup() {
      setLoading(true);
      return await getSalesGroup();
    }
    getLeadSource().then((data) => {
      setLeadSource(data.data);
      setLoading(false);
    });

    async function getLeadSource() {
      setLoading(true);
      return await getPls();
    }

    if (!checkVisibility(20)) {
      getSalesPg().then((data) => {
        setProgramArray(data.data);
        setLoading(false);
      });

      async function getSalesPg() {
        setLoading(true);
        return await getPgLsKeyAndCost();
      }

      getLeadCounts().then((data) => {
        setLeadData(data.data);
        setLoading(false);
        setLeadSize(data.data.count);
        setLoadingLeadData(false);
      });

      async function getLeadCounts() {
        setLoading(true);
        setLoadingLeadData(true);
        return await getLeadCount(
          fromDate,
          toDate,
          "",
          currentLeadPage,
          PER_PAGE,
          "",
          isFirst
        );
      }

      subSales().then((data) => {
        setSubSalesArray(data.data);
        setLoading(false);
      });

      async function subSales() {
        setLoading(true);
        return await getSubSalesGroup();
      }

      getAggregate().then((data) => {
        console.log(data);
        setAggregateData(data.data);
        setDefaultAggregateData(data.data);
        setLoadingTableData(false);
      });

      async function getAggregate() {
        setLoadingTableData(true);
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
        let formattedDate = year + "-" + month;
        let formDate = formattedDate + "-" + "01";
        let toDate =
          formattedDate + "-" + ("0" + currentDate.getDate()).slice(-2);
        console.log(formDate, toDate);
        return await getAggregateData(formDate, toDate, isFirst);
      }

      getAdvisorAggregate().then((data) => {
        console.log(data);
        setAdvisorAggregateData(data.data);
        setDefaultAdvisorAggregateData(data.data);
        setLoadingTableData(false);
      });

      async function getAdvisorAggregate() {
        setLoadingTableData(true);
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
        let formattedDate = year + "-" + month;
        let formDate = formattedDate + "-" + "01";
        let toDate =
          formattedDate + "-" + ("0" + currentDate.getDate()).slice(-2);
        console.log(formDate, toDate);
        return await getAdvisorAggregateData(formDate, toDate, isFirst);
      }
    }
  }, []);

  useEffect(() => {
    if (businessHead) {
      getSalesPg().then((data) => {
        setProgramArray(data.data);
        setLoading(false);
      });

      async function getSalesPg() {
        setLoading(true);
        return await getPgLsKeyAndCost(businessHead);
      }

      getLeadCounts().then((data) => {
        setLeadData(data.data);
        setLeadSize(data.data.count);
        setLoading(false);
        setLoadingLeadData(false);
      });

      async function getLeadCounts() {
        setLoading(true);
        setLoadingLeadData(true);
        return await getLeadCount(
          fromDate,
          toDate,
          "",
          currentLeadPage,
          PER_PAGE,
          businessHead,
          isFirst
        );
      }

      subSales().then((data) => {
        setSubSalesArray(data.data);
        setLoading(false);
      });

      async function subSales() {
        setLoading(true);
        return await getSubSalesGroup(businessHead);
      }

      getAggregate().then((data) => {
        console.log(data);
        setAggregateData(data.data);
        setDefaultAggregateData(data.data);
        setLoadingTableData(false);
      });

      async function getAggregate() {
        setLoadingTableData(true);
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
        let formattedDate = year + "-" + month;
        let formDate = formattedDate + "-" + "01";
        let toDate =
          formattedDate + "-" + ("0" + currentDate.getDate()).slice(-2);
        console.log(formDate, toDate);
        return await getAggregateData(formDate, toDate, businessHead, isFirst);
      }

      getAdvisorAggregate().then((data) => {
        console.log(data);
        setAdvisorAggregateData(data.data);
        setDefaultAdvisorAggregateData(data.data);
        setLoadingTableData(false);
      });

      async function getAdvisorAggregate() {
        setLoadingTableData(true);
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
        let formattedDate = year + "-" + month;
        let formDate = formattedDate + "-" + "01";
        let toDate =
          formattedDate + "-" + ("0" + currentDate.getDate()).slice(-2);
        console.log(formDate, toDate);
        return await getAdvisorAggregateData(
          formDate,
          toDate,
          businessHead,
          isFirst
        );
      }
    }
  }, [businessHead]);

  useEffect(() => {
    if ((program || fromDate || toDate || isFirstString) && firstRender) {
      getLeadCounts().then((data) => {
        setLeadData(data.data);
        // setLeadSize(data.data.count);
        setLoading(false);
      });

      async function getLeadCounts() {
        setLoading(true);
        return await getLeadCount(
          fromDate,
          toDate,
          program,
          currentLeadPage,
          PER_PAGE,
          businessHead,
          isFirst
        );
      }
      if (businessHead) {
        window.history.pushState(
          {},
          "",
          `?user=${businessHead}&program=${encodeURIComponent(
            program
          )}&fromDate=${fromDate}&toDate=${toDate}`
        );
      } else {
        window.history.pushState(
          {},
          "",
          `?program=${encodeURIComponent(
            program
          )}&fromDate=${fromDate}&toDate=${toDate}`
        );
      }
    }
  }, [program, fromDate, toDate, isFirst]);

  useEffect(() => {
    if (program) {
      let agData =
        program == ""
          ? defaultAggregateData
          : defaultAggregateData &&
            defaultAggregateData.filter((data) => data.program == program);
      setAggregateData(agData);

      let adAgData =
        program == ""
          ? defaultAdvisorAggregateData
          : defaultAdvisorAggregateData &&
            defaultAdvisorAggregateData.filter(
              (data) => data.mx_program_3 == program
            );
      setAdvisorAggregateData(adAgData);
    } else {
      setAggregateData(defaultAggregateData);
      setAdvisorAggregateData(defaultAdvisorAggregateData);
    }
  }, [program]);

  useEffect(() => {
    if ((fromDate || toDate) && firstRender) {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let formattedDate = year + "-" + month;
      let currentFromDate = fromDate || formattedDate + "-" + "01";
      let currentToDate =
        toDate || formattedDate + "-" + currentDate.getDate() || toDate;

      getAggregate().then((data) => {
        if (program) {
          let agData = data.data;
          let Data =
            agData && agData.filter((data) => data.mx_program_3 == program);
          setAggregateData(Data);
          setDefaultAggregateData(Data);
          setLoadingTableData(false);
        } else {
          setAggregateData(data.data);
          setDefaultAggregateData(data.data);
          setLoadingTableData(false);
        }
      });

      async function getAggregate() {
        setLoadingTableData(true);
        return await getAggregateData(
          currentFromDate,
          currentToDate,
          businessHead,
          isFirst
        );
      }

      getAdvisorAggregate().then((data) => {
        if (program) {
          let agData = data.data;
          let Data =
            agData && agData.filter((data) => data.mx_program_3 == program);
          setAdvisorAggregateData(Data);
          setLoadingTableData(false);
        } else {
          setAdvisorAggregateData(data.data);
          setDefaultAdvisorAggregateData(data.data);
          setLoadingTableData(false);
        }
      });

      async function getAdvisorAggregate() {
        setLoadingTableData(true);
        return await getAdvisorAggregateData(
          currentFromDate,
          currentToDate,
          businessHead,
          isFirst
        );
      }
    }
  }, [fromDate, toDate, isFirst]);

  const leadModelShows = (data) => {
    setLeadModelShow(true);
    setSelectSalesGroup(data);
  };

  const resetFilter = () => {
    setFromDate("");
    setToDate("");
    setProgram("");
    setBusinessHead("");
    if (businessHead) {
      window.history.pushState({}, "", `?fromDate=&toDate=&program=&user=`);
    } else {
      window.history.pushState({}, "", `?fromDate=&toDate=&program=`);
    }
    window.location.reload();
  };

  const handleChange = (event) => {
    console.log(event.target.checked);
    setFirstRender(true);
    setIsFirst(event.target.checked);
    if (event.target.checked) {
      setIsFirstString("true");
    } else {
      setIsFirstString("false");
    }
  };

  console.log({ salesGroupArray });

  console.log({ leadData });
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
          color: "#fff",
          background: "rgb(5, 86, 70)",
        }}
      >
        <h4>Lead Distribution Panel</h4>
      </div>
      {loading && <Loader />}
      {modelShow.Boolean && modelShow.String != null && (
        <LeadDistributionModel
          show={modelShow}
          handleClose={handleModelClose}
          businessHead={businessHead}
        />
      )}

      {leadModelShow && (
        <LeadModel
          show={leadModelShow}
          handleClose={handleCloseLead}
          salesData={selectSalesGroup}
          advisorAggregateData={advisorAggregateData}
          lsplsArray={leadSource}
        />
      )}

      {/* {showPreviousTarget && (
        <PreviousTargetModel show={showPreviousTarget} handleClose={() => setShowPreviousTarget(false)} leadSource={leadSource} salesGroupArray={salesGroupArray} subSalesArray={subSalesArray} businessHead={businessHead} />
      )} */}
      <div
        style={{
          width: "100%",
          marginLeft: "22px",
          maxWidth: "1140px",
          paddingTop: "45px",
        }}
      >
        {/* business head dropdown */}
        {checkVisibility(20) && (
          <>
            <div style={{ marginBottom: "30px" }}>
              <select
                className="timeleadtabledrop"
                value={businessHead}
                onChange={(e) => {
                  setBusinessHead(e.target.value),
                    window.history.pushState({}, "", `?user=${e.target.value}`);
                }}
                name="leadsource"
                id="leadsource"
                style={{ width: "250px" }}
              >
                <option disabled selected value="">
                  Select Business Head
                </option>
                {salesGroupArray &&
                  salesGroupArray.map((data, index) => {
                    return <option value={data.aid}>{data.email}</option>;
                  })}
              </select>
            </div>
          </>
        )}

        {/* time */}
        <div className="timetablemaincontainer">
          <div className="timetablecontainer">
            <p>Lead Generation Time</p>
            <div className="timetabletimecontainer">
              <div>
                <input
                  className="timeleadtabledrop"
                  type="date"
                  id="monthyear"
                  name="monthyear"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    setFirstRender(true);
                  }}
                />
              </div>
              <div>
                <input
                  className="timeleadtabledrop"
                  type="date"
                  id="monthyear"
                  name="monthyear"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                    setFirstRender(true);
                  }}
                />
              </div>
            </div>
          </div>

          {/* <div className="timetablecontainer">
            <p>Lead Source</p>
            <div>
              <select
                className="timeleadtabledrop"
                value={leadSource}
                onChange={(e) => setLeadSource(e.target.value)}
                name="leadsource"
                id="leadsource"
              >
                <option disabled selected value="">
                  Lead Source
                </option>
                <option value="saab">Saab</option>
                <option value="mercedes">Mercedes</option>
                <option value="audi">Audi</option>
              </select>
            </div>
          </div> */}

          <div className="timetablecontainer">
            <p>Program</p>
            <div>
              <select
                value={program}
                onChange={(e) => {
                  setProgram(e.target.value);
                  setFirstRender(true);
                }}
                className="timeleadtabledrop"
                name="program"
                id="program"
              >
                <option value="" disabled selected>
                  All
                </option>
                <option value="" selected>
                  All Program
                </option>
                {programArray &&
                  programArray.map((data, i) => {
                    return (
                      <option value={data.leadsquareProgramKey} key={i}>
                        {data.leadsquareProgramKey}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <button className="resetFilterButton" onClick={() => resetFilter()}>
            Reset Filter
          </button>
        </div>

        {/* revenue */}
        <div className="revenuemaincontainer">
          <div className="revenuecontainer">
            <h4>Total Admission Targets</h4>
            {loadingLeadData ? (
              <p>Loading...</p>
            ) : (
              <h2> {leadData ? leadData.admsissionCount : 0}</h2>
            )}
          </div>

          <div className="revenuecontainer">
            <div style={{ display: "flex", gap: "5px" }}>
              <h4>Unique Leads</h4>
              <h4 className="tooltipl">
                <img
                  src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                  height={20}
                  width={20}
                />
                <span class="tooltiptextl2">
                  {" "}
                  This shows the unique number of leads created with unique
                  mobile number for selected filters. All leads shown here are
                  created for the first time in LS with unique mobile number.
                </span>{" "}
              </h4>
            </div>
            {loadingLeadData ? (
              <p>Loading...</p>
            ) : (
              <h2> {leadData ? leadData.ucount : 0}</h2>
            )}
          </div>

          <div className="revenuecontainer">
            <div style={{ display: "flex", gap: "5px" }}>
              <h4>Total Number of Leads</h4>
              <h4 className="tooltipl">
                <img
                  src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                  height={20}
                  width={20}
                />
                <span class="tooltiptextl2">
                  This shows the total number of leads created for the selected
                  dates. This includes the leads which are created more than
                  once by a lead with same mobile number.
                </span>{" "}
              </h4>
            </div>
            {loadingLeadData ? (
              <p>Loading...</p>
            ) : (
              <h2> {leadData ? leadData.count : 0}</h2>
            )}
          </div>

          <div
            className="revenuebtncontainer"
            style={{ marginLeft: "auto", marginRight: "60px" }}
          >
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="revenueeaddeditbtn"
              >
                Add/Edit
                <ArrowDropDownIcon style={{ fontSize: "28px" }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {(checkVisibility(23) || checkVisibility(20)) && (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setModelShow({
                        Boolean: true,
                        String: "Add Admission Targets",
                      });
                    }}
                    className="Popitem"
                    value="salesGroup"
                  >
                    Add Admission Targets
                  </MenuItem>
                )}
                {(checkVisibility(23) || checkVisibility(20)) && (
                  <MenuItem
                    onClick={() => {
                      navigate("/LeadDistributionPanelAdvisors");
                    }}
                    className="Popitem"
                    value="salesGroup"
                  >
                    View Advisors List
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setModelShow({ Boolean: true, String: "Manage Advisors" });
                  }}
                  className="Popitem"
                  value="salesGroup"
                >
                  Manage Advisors
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setModelShow({
                      Boolean: true,
                      String: "View Program Allocation",
                    });
                  }}
                  className="Popitem"
                  value="salesGroup"
                >
                  View Program Allocation
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        {/* Lead Table */}
        <div className="groupmaincontainer">
          <>
            <div className="groupmainheadingdiv">
              <h3>Lead View</h3>

              <div style={{ marginTop: "20px" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  <h5>Unique Lead </h5>
                  <h5 style={{ color: "#3c4852" }} className="tooltipl">
                    <img
                      src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                      height={20}
                      width={20}
                    />
                    <span class="tooltiptextl">
                      All leads shown here are created for the first time in LS
                      with unique mobile number.
                    </span>{" "}
                  </h5>
                </div>

                <div>
                  <GreenSwitch
                    {...label}
                    checked={isFirst}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="groupmaintablediv">
              <>
                <div className="" style={{ overflow: "scroll", width: "100%" }}>
                  <div style={{ width: "1700px" }}>
                    <table className="table table-striped groupleadtable">
                      <thead>
                        <tr className="">
                          <th
                            scope="col"
                            style={{ width: "254px", height: "48px" }}
                          >
                            Lead Generation Time
                          </th>
                          <th
                            scope="col"
                            style={{ width: "254px", height: "48px" }}
                          >
                            Lead Assigned Time
                          </th>
                          <th scope="col" style={{ width: "145px" }}>
                            Lead Source{" "}
                          </th>
                          <th scope="col" style={{ width: "145px" }}>
                            Lead Name
                          </th>
                          <th scope="col" style={{ width: "292px" }}>
                            Lead Email Id
                          </th>
                          <th scope="col" style={{ width: "292px" }}>
                            Lead Phone Number
                          </th>
                          <th scope="col" style={{ width: "150px" }}>
                            Product
                          </th>
                          <th scope="col" style={{ width: "214px" }}>
                            Business Head
                          </th>
                          <th scope="col" style={{ width: "214px" }}>
                            Sales Head
                          </th>
                          <th scope="col" style={{ width: "214px" }}>
                            Lead Owner
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Should Lead Saved On LS
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <p>Lead Saved On LS</p>
                              <h4 className="tooltipl">
                                <img
                                  src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                                  height={20}
                                  width={20}
                                />
                                <span class="tooltiptextl2">
                                  {" "}
                                  If "Should lead saved on LS" is true and "Lead
                                  saved on LS" is false for more than 15 min
                                  then, Due to some technical issues lead
                                  doesn't save on ls
                                </span>{" "}
                              </h4>
                            </div>
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Unique
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loadingLeadData ? (
                          <tr
                            className="uper_table_container"
                            style={{ border: "none" }}
                          >
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                            <th>
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </th>
                          </tr>
                        ) : (
                          leadData &&
                          leadData.leads &&
                          leadData.leads.map((data, idx) => {
                            return (
                              <tr className="ld_table_center" key={idx}>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {convertUtcToIst(data.createdAt)}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.advisorAssignAt
                                    ? convertUnixTimeToIst(data.advisorAssignAt)
                                    : "--"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.pls}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.FirstName}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.EmailAddress || "---"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.Phone || "---"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.mx_Program_3 || "---"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {getSalesGroupName(
                                    salesGroupArray,
                                    data.salesGroupId
                                  ) || "---"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {getSubSalesGroupEmail(
                                    subSalesArray,
                                    data.subSalesGroupId
                                  ) || "---"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.OwnerIdEmailAddress || "---"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.should_lead_be_saved_on_ls
                                    ? "Yes"
                                    : "No"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.is_lead_saved_ls ? "Yes" : "No"}
                                </th>
                                <th
                                  scope="row"
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {"isFirstTouch" in data
                                    ? data.isFirstTouch
                                      ? "Yes"
                                      : "No"
                                    : "N/A"}
                                </th>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Pagination
                    handleClick={handleClick3}
                    pageCount={leadPageCount}
                  />
                </div>
              </>
            </div>
          </>
        </div>

        {/* group view */}
        <div className="groupmaincontainer">
          {checkVisibility(22) ? (
            <>
              <div className="groupmainheadingdiv">
                <h3>Sales Head View</h3>
              </div>

              <div className="groupmaintablediv">
                <div className="" style={{ overflow: "scroll", width: "100%" }}>
                  <div style={{ width: "1700px" }}>
                    <table className="table table-striped groupleadtable">
                      <thead>
                        <tr className="">
                          <th
                            scope="col"
                            style={{ width: "250px", height: "48px" }}
                          >
                            Sales Head
                          </th>
                          <th scope="col" style={{ width: "320px" }}>
                            Advisor
                          </th>
                          <th scope="col" style={{ width: "180px" }}>
                            Program
                          </th>
                          {leadSource &&
                            leadSource.map((data, index) => {
                              return (
                                <th
                                  scope="col"
                                  style={{ width: "114px" }}
                                  key={index}
                                >
                                  {data}
                                </th>
                              );
                            })}
                          <th scope="col" style={{ width: "114px" }}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {advisorAggregateData &&
                          advisorAggregateData.map((data, idx) => {
                            return (
                              <tr
                                className="uper_table_container ld_table_center"
                                key={idx}
                              >
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {getSubSalesGroupEmail(
                                    subSalesArray,
                                    data.subSalesGroupId
                                  ) || "--"}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.OwnerIdName || "--"}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.mx_program_3}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.acp_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.affiliate_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.btldatabase_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.community_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.display_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.fb_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.mailer_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.referral_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.search_count}
                                </th>
                                <th
                                  style={{
                                    borderRight:
                                      "1px solid rgba(60, 72, 82, 0.25)",
                                  }}
                                >
                                  {data.total_pls_count}
                                </th>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <p>
                  Note : It might take upto 20 minutes for Aggregate Analysis to
                  be updated
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {/* aggregate */}
        {(checkVisibility(23) || checkVisibility(20)) && (
          <div className="groupmaincontainer">
            <div
              className="groupmainheadingdiv"
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "baseline",
                borderBottom: "1px solid rgba(60, 72, 82, 0.25)",
              }}
            >
              <h3 style={{ borderBottom: "none" }}>Aggregate Analysis</h3>
              <h4 className="tooltipl">
                <img
                  src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                  height={20}
                  width={20}
                />
                <span class="tooltiptextl2">
                  {" "}
                  This aggregate allocation is for distribution of "Unique
                  leads" only
                </span>{" "}
              </h4>
            </div>

            <div className="groupmaintablediv">
              <div className="" style={{ overflow: "scroll", width: "100%" }}>
                <table className="table table-striped groupleadtable">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        style={{ width: "158px", height: "48px" }}
                      >
                        Program
                      </th>
                      <th scope="col" style={{ width: "194px" }}>
                        Lead Source
                      </th>
                      <th scope="col" style={{ width: "212px" }}>
                        Total Leads
                      </th>
                      {subSalesArray &&
                        subSalesArray.map((data, index) => {
                          return (
                            <th
                              onClick={() => leadModelShows(data)}
                              scope="col"
                              style={{ width: "124px" }}
                            >
                              {data.email}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {loadingTableData ? (
                      <tr
                        className="uper_table_container"
                        style={{ border: "none" }}
                      >
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                      </tr>
                    ) : (
                      aggregateData &&
                      aggregateData.map((data, idx) => {
                        let total = 0;
                        {
                          subSalesArray &&
                            subSalesArray.map((d, index) => {
                              data.target.map((d1, i1) => {
                                if (d1.d === d._id) {
                                  total += d1.c;
                                }
                              });
                            });
                        }
                        return (
                          <tr
                            className="uper_table_container ld_table_center"
                            key={idx}
                          >
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.program}
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.pls}
                            </th>

                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {parseInt(total)}
                            </th>
                            {subSalesArray &&
                              subSalesArray.map((d, i) => {
                                const index = data.target.findIndex(
                                  (d1) => d1.d == d._id
                                );
                                return (
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {index != -1 ? data.target[index].c : "--"}
                                  </th>
                                );
                              })}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>

                <p>
                  Note : It might take upto 20 minutes for Aggregate Analysis to
                  be updated
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="groupmaincontainer">
          {(checkVisibility(23) || checkVisibility(20)) && (
            <>
              <div
                className="groupmainheadingdiv"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(60, 72, 82, 0.25)",
                }}
              >
                <h3 style={{ borderBottom: "none" }}>Business Head View</h3>

                {/* <button onClick={() => setShowPreviousTarget(true)} style={{height: '48px', padding: '16px', borderRadius: '4px', border: '1px solid #035642', fontSize: '16px', background: 'transparent', color: '#035642', alignItems: 'center', display: 'flex'}}>View Previous Target</button> */}
              </div>

              <div className="groupmaintablediv">
                <>
                  <div
                    className=""
                    style={{ overflow: "scroll", width: "100%" }}
                  >
                    <div style={{ width: "1700px" }}>
                      <table className="table table-striped groupleadtable">
                        <thead>
                          <tr className="">
                            <th
                              scope="col"
                              style={{ width: "250px", height: "48px" }}
                            >
                              Business Head
                            </th>
                            <th scope="col" style={{ width: "320px" }}>
                              Sales Head
                            </th>
                            <th scope="col" style={{ width: "180px" }}>
                              Program
                            </th>
                            {leadSource &&
                              leadSource.map((data, index) => {
                                return (
                                  <th
                                    scope="col"
                                    style={{ width: "114px" }}
                                    key={index}
                                  >
                                    {data}
                                  </th>
                                );
                              })}
                            <th scope="col" style={{ width: "114px" }}>
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {leadData &&
                            leadData.distribution &&
                            leadData.distribution.map((data, idx) => {
                              return (
                                <tr
                                  className="uper_table_container ld_table_center"
                                  key={idx}
                                >
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {getSalesGroupName(
                                      salesGroupArray,
                                      data.salesgroup
                                    ) || "--"}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {getSubSalesGroupEmail(
                                      subSalesArray,
                                      data.subSalesGroupId
                                    ) || "--"}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.programLSKey}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["ACP"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["Affiliate"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["BTL/Database"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["Community"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Display"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["FB"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Mailer"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Referral"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Search"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["ACP"] +
                                        data.plsCount["Affiliate"] +
                                        data.plsCount["BTL/Database"] +
                                        data.plsCount["Community"] +
                                        data.plsCount["Display"] +
                                        data.plsCount["FB"] +
                                        data.plsCount["Mailer"] +
                                        data.plsCount["Referral"] +
                                        data.plsCount["Search"]}
                                  </th>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Pagination
                      handleClick={handleClick2}
                      pageCount={pageCount}
                    />
                  </div>
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.login.permissions,
  resources: state.login.resources,
});

export default connect(mapStateToProps)(LeadDistributionPanel);
