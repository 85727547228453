import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchApplications,
  selectedApplicationStatus,
  fetchCourses,
  selectedCourse,
  resetApplication,
} from "../../actions/applicationFormAction";
import { Select } from "antd";
import { Autocomplete, TextField } from "@mui/material";
const { Option } = Select;
const cookies = new Cookies();

const status = [
  {
    displayStatus: "Not Filled",
    status: 0,
  },
  {
    displayStatus: "Pending",
    status: 1,
  },
  {
    displayStatus: "Paid",
    status: 2,
  },
  {
    displayStatus: "Approved",
    status: 3,
  },
  {
    displayStatus: "Rejected",
    status: 4,
  },
];

let ApplicationSideBar = (props) => {
  console.log(props);
  const navigate = useNavigate();
  useEffect(() => props.fetchCourses(), []);

  let handleStatusChange = (val) => {
    props.selectedApplicationStatus(val);
    props.fetchApplications({
      crs_pg_id: props.appreview.crs_pg_id._id,
      app_status: val.status,
      appcurrent_page: 1,
      search_val: props.appreview.searchVal,
      search_type: props.appreview.searchType,
    });
  };
  let handleCourseChange = (val) => {
    props.selectedCourse(val);
    props.fetchApplications({
      crs_pg_id: val._id,
      app_status: props.appreview.app_status
        ? props.appreview.app_status.status
        : undefined,
      appcurrent_page: 1,
    });
  };

  console.log(props);
  return (
    <div className="app-review-sidebar">
      <button
        className="ar-back-button"
        onClick={() => {
          props.resetApplication();
          props.selectedCourse(null);
          navigate("/");
        }}
        style={{ border: "2px solid white" }}
      >
        Back
      </button>
      <div>
        <div className="filter-nm">Course</div>
        <Autocomplete
          disablePortal
          fullWidth
          autoHighlight
          value={props.appreview.crs_pg_id}
          onChange={(event, selectedCourse) => {
            handleCourseChange(selectedCourse);
          }}
          getOptionLabel={(selectedCourse) => {
            return selectedCourse?.name ? `${selectedCourse.name}` : "";
          }}
          id="courseSelectorModal"
          options={props.appreview.courses}
          renderInput={(params) => {
            return (
              <TextField label="Select Course" {...params} variant="outlined" />
            );
          }}
        />
      </div>
      {props.appreview.crs_pg_id && (
        <Autocomplete
          disablePortal
          fullWidth
          autoHighlight
          value={props.appreview.app_status}
          onChange={(event, selectedStatus) => {
            handleStatusChange(selectedStatus);
          }}
          getOptionLabel={(selectedStatus) => {
            return selectedStatus?.displayStatus
              ? `${selectedStatus.displayStatus}`
              : "";
          }}
          id="statusSelectorModal"
          options={status}
          renderInput={(params) => {
            return (
              <TextField label="Select Status" {...params} variant="outlined" />
            );
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
});

const mapDispatchToProps = {
  fetchApplications,
  selectedApplicationStatus,
  fetchCourses,
  resetApplication,
  selectedCourse,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSideBar);
