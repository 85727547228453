import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  editGeneralPromo,
  fetchGeneralPromo,
  resetPromoState,
} from "../../actions/promoActions";
import { useParams, useNavigate } from "react-router-dom";
import AddForm from "./components/addForm_general";
import {
  Form,
  Spin,
  Input,
  Layout,
  notification,
  Tooltip,
  Breadcrumb,
  Icon,
  DatePicker,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
} from "antd";
const FormItem = Form.Item;
const Option = Select.Option;

// @connect((store) => {
//   console.log(store);
//   return {
//     promo: store.promo.data,
//     loading : store.promo.loading
//   };
// })

const EditPromocodeForm = (props) => {
  console.log({ props });
  const [gcode, setGcode] = useState(null);
  const [promoid, setPromoid] = useState(useParams().promoid);

  const navigate = useNavigate();

  useEffect(() => {
    if (!props.promo.code) {
      props.dispatch(fetchGeneralPromo(promoid));
    }
  }, []);

  useEffect(() => {
    if (props.promo.success) {
      props.dispatch(resetPromoState());
      navigate("/promocode/generalpromocode");
    }
  }, [props]);

  const Notify = (type, message, desc) => {
    notification[type]({
      message: message,
      description: desc,
    });
  };

  const submitPromoCode = (data, genralPromForm) => {
    console.log("submitting promo code");
    data.promo_id = promoid;
    data.exp = moment(data.exp).format("x");
    props.dispatch(editGeneralPromo(data, genralPromForm));
  };

  const { Content } = Layout;
  const formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };

  const promo =
    props.promo.promos.find((promo) => {
      if (promo._id == promoid) {
        return promo;
      }
    }) || props.promo.currentPromo;

  return (
    <div>
      <Breadcrumb style={{ margin: "12px" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Promocode</Breadcrumb.Item>
        <Breadcrumb.Item>General Promocode</Breadcrumb.Item>
        <Breadcrumb.Item>Edit</Breadcrumb.Item>
      </Breadcrumb>
      <Spin size="large" spinning={props.loading}>
        <Content
          style={{
            background: "#fff",
            padding: 24,
            margin: 0,
            minHeight: 400,
          }}
        >
          <h3>Edit Promocode</h3>
          <AddForm
            promo={promo}
            data={props.promo}
            callback={submitPromoCode}
            edit={true}
          />
        </Content>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    promo: state.promo.data,
    loading: state.promo.loading,
  };
};
export default connect(mapStateToProps)(EditPromocodeForm);
// export default EditPromocode;
