import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";
import { renewAccessToken } from "./loginActions";

const cookies = new Cookies();

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

export function resetPromoState() {
  return function (dispatch) {
    dispatch({ type: "RESET_PROMO_STATE" });
  };
}

export function resetGenralPromo() {
  return function (dispatch) {
    dispatch({ type: "RESET_GENREALPROMO_STATE" });
  };
}

export function resetSpecialPromoState() {
  return function (dispatch) {
    dispatch({ type: "RESET_SPECIALPROMO_STATE" });
  };
}

export function logout() {
  renewATorLogout(dispatch);
  return function (dispatch) {
    dispatch({ type: "LOGOUT" });
  };
}

export function listSpecialPromos(data) {
  var listPromosConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/ls_sp",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "LIST_SPECIAL_PROMO" });
    axios(listPromosConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "LIST_SPECIALPROMO_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "LIST_SPECIALPROMO_REJECTED", payload: err });
      });
  };
}

export function getPermissionsViewPromo() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_view_promo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_VIEW_PROMO",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsCreatePromo() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_crt_promo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_CREATE_PROMO",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsUpdatePromo() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_updt_promo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_UPDATE_PROMO",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsDeletePromo() {
  console.log("reached here");
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_rmv_promo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_DELETE_PROMO",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsViewReviews() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_view_reviews",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_VIEW_REVIEWS",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsCreateReviews() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_crt_reviews",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_CREATE_REVIEWS",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsUpdateReviews() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_updt_reviews",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_UPDATE_REVIEWS",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsDeleteReviews() {
  console.log("reached here");
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/prmsn_rmv_reviews",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_DELETE_REVIEWS",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsViewCampaign() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/appnotification/prmsn_view_campaign",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_VIEW_COMPAIGN",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsCreateCampaign() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/appnotification/prmsn_crt_campaign",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_CREATE_COMPAIGN",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsUpdateCampaign() {
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/appnotification/prmsn_updt_campaign",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_UPDATE_COMPAIGN",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function getPermissionsDeleteCampaign() {
  console.log("reached here");
  var config = {
    method: "GET",
    url: constants.API_ENDPOINT + "/appnotification/prmsn_rmv_campaign",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "SAVE_PERMISSION_DELETE_COMPAIGN",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
      });
  };
}

export function listPromos(data) {
  if (data.type == "gcode") {
    var listPromosConfig = {
      method: "POST",
      url: constants.API_ENDPOINT + "/ops/ls",
      headers: {
        "x-access-token": cookies.get("at"),
      },
      data: data,
    };
  } else if (data.type == "cutag") {
    var listPromosConfig = {
      method: "POST",
      url: constants.API_ENDPOINT + "/ops/ls_course_promo",
      headers: {
        "x-access-token": cookies.get("at"),
      },
      data: data,
    };
  }
  return function (dispatch) {
    dispatch({ type: "LIST_PROMO" });
    axios(listPromosConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: "LIST_PROMO_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "LIST_PROMO_REJECTED", payload: err });
      });
  };
}

export function getGroupDetails(data) {
  var getGroupDetailsConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/g_grp_dt",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      code: data.gcode,
    },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_GROUP_DETAILS" });
    axios(getGroupDetailsConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "FETCH_GROUP_DETAILS_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "FETCH_GROUP_DETAILS_REJECTED", payload: err });
      });
  };
}

export function fetchPromo(promo) {
  var fetchPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/lsi",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      promo_id: promo,
    },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_PROMO" });
    axios(fetchPromoConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: "FETCH_PROMO_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "FETCH_PROMO_REJECTED", payload: err });
      });
  };
}

export function createPromo(data) {
  var createPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/cr_prmo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "CREATE_PROMO" });
    axios(createPromoConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "CREATE_PROMO_FULFILLED_LOADING",
          payload: response.data,
        });
        dispatch({ type: "CREATE_PROMO_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "CREATE_PROMO_REJECTED", payload: err });
      });
  };
}

export function createGeneralPromo(data, genralPromForm) {
  var createPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/cr_general_prmo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "CREATE_PROMO" });
    axios(createPromoConfig)
      .then((response) => {
        console.log({ response });
        dispatch({
          type: "CREATE_PROMO_FULFILLED_LOADING",
          payload: response.data,
        });
        dispatch({ type: "CREATE_PROMO_FULFILLED", payload: response.data });
        genralPromForm.resetFields();
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "CREATE_PROMO_REJECTED", payload: err });
      });
  };
}

export function createSpecialPromo(data, specialPromForm) {
  var createPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/cr_special_prmo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "CREATE_PROMO" });
    axios(createPromoConfig)
      .then((response) => {
        if (response.status == 200) {
          specialPromForm.resetFields();
          dispatch({
            type: "CREATE_SPECIAL_PROMO_FULFILLED",
            payload: response.data,
          });
        } else {
          dispatch({ type: "CREATE_PROMO_REJECTED", payload: response });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "CREATE_PROMO_REJECTED", payload: err });
      });
  };
}

export function requestDiscountOTP(data) {
  var requestDiscountOTPConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/request_discount_otp",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "CREATE_PROMO" });
    axios(requestDiscountOTPConfig)
      .then((response) => {
        if (response.status == 200) {
          dispatch({
            type: "CREATE_PROMO_OTP_SENT_SUCCESS",
          });
        } else {
          dispatch({ type: "CREATE_PROMO_OTP_SENT_FAILED", payload: response });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "CREATE_PROMO_OTP_SENT_FAILED", payload: err });
      });
  };
}

export function verifyDiscountOTP(data, specialPromForm) {
  var verifyDiscountOTPConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/verify_discount_otp",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "CREATE_PROMO" });
    axios(verifyDiscountOTPConfig)
      .then((response) => {
        if (response.status == 200) {
          specialPromForm.resetFields();
          dispatch({
            type: "CREATE_SPECIAL_PROMO_FULFILLED",
            payload: response.data,
          });
        } else {
          dispatch({ type: "CREATE_PROMO_REJECTED", payload: response });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "CREATE_PROMO_REJECTED", payload: err });
      });
  };
}

export function editPromo(data) {
  var editPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/ed_prmo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "EDIT_PROMO" });
    axios(editPromoConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: "EDIT_PROMO_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "EDIT_PROMO_REJECTED", payload: err });
      });
  };
}

export function fetchGeneralPromo(promo) {
  var fetchPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/ls_g",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      promo_id: promo,
    },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_PROMO" });
    axios(fetchPromoConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: "FETCH_PROMO_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "EDIT_PROMO_REJECTED", payload: err });
      });
  };
}

export function editGeneralPromo(data, genralPromForm) {
  var editPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/ed_gen_prmo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    dispatch({ type: "EDIT_PROMO" });
    axios(editPromoConfig)
      .then((response) => {
        dispatch({ type: "EDIT_PROMO_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "EDIT_PROMO_REJECTED", payload: err });
      });
  };
}

export function getGenralPromoCodes(promo_id) {
  var data = {};
  if (promo_id) {
    data.promo_id = promo_id;
  }
  var getAllGenPromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/ls_g",
    data: data,
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  return function (dispatch) {
    axios(getAllGenPromoConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: "LIST_PROMO_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "LIST_PROMO_REJECTED", payload: err });
      });
  };
}

export function deletePromocode(pr_id, promo_type) {
  var data = {};
  data["promo_id"] = pr_id;
  data["promo_type"] = Number(promo_type);
  var deletePromoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/del_promo",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: data,
  };
  return function (dispatch) {
    axios(deletePromoConfig)
      .then((response) => {
        console.log(response, response.data.status);
        var msg;
        var resp =
          response.data && response.data.data ? response.data.data : {};
        if (resp.status == true) {
          msg = "Promocode successfully deleted";
        } else if (resp.status == false) {
          msg = resp.msg;
        } else {
          msg = "Something went wrong";
        }
        var status = typeof resp.status == "boolean" ? resp.status : true;
        dispatch({
          type: "DELETE_PROMOCODE_FULFILLED",
          pr_type: promo_type,
          promo_id: pr_id,
          status: status,
          msg: msg,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "DELETE_PROMOCODE_REJECTED", payload: err });
      });
  };
}
