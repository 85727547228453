import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";
import { renewAccessToken } from "./loginActions";

const cookies = new Cookies();

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_FULFILLED = "CREATE_CAMPAIGN_FULFILLED";
export const CREATE_CAMPAIGN_REJECTED = "CREATE_CAMPAIGN_REJECTED";
export const CREATE_CAMPAIGN_COMPLETED = "CREATE_CAMPAIGN_COMPLETED";

export const OPEN_CAMPAIGN = "OPEN_CAMPAIGN";
export const OPEN_CAMPAIGN_FULFILLED = "OPEN_CAMPAIGN_FULFILLED";
export const OPEN_CAMPAIGN_REJECTED = "OPEN_CAMPAIGN_REJECTED";

export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
export const FETCH_CAMPAIGNS_FULFILLED = "FETCH_CAMPAIGNS_FULFILLED";
export const FETCH_CAMPAIGNS_REJECTED = "FETCH_CAMPAIGNS_REJECTED";

export const LAUNCH_CAMPAIGN = "LAUNCH_CAMPAIGN";
export const LAUNCH_CAMPAIGN_FULFILLED = "LAUNCH_CAMPAIGN_FULFILLED";
export const LAUNCH_CAMPAIGN_REJECTED = "LAUNCH_CAMPAIGN_REJECTED";

export const CANCEL_CAMPAIGN = "CANCEL_CAMPAIGN";
export const CANCEL_CAMPAIGN_FULFILLED = "CANCEL_CAMPAIGN_FULFILLED";
export const CANCEL_CAMPAIGN_REJECTED = "CANCEL_CAMPAIGN_REJECTED";

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_FULFILLED = "DELETE_CAMPAIGN_FULFILLED";
export const DELETE_CAMPAIGN_REJECTED = "DELETE_CAMPAIGN_REJECTED";

export const RELAUNCH_CAMPAIGN = "RELAUNCH_CAMPAIGN";
export const RELAUNCH_CAMPAIGN_FULFILLED = "RELAUNCH_CAMPAIGN_FULFILLED";
export const RELAUNCH_CAMPAIGN_REJECTED = "RELAUNCH_CAMPAIGN_REJECTED";

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

export function logout() {
  renewATorLogout(dispatch);
  return function (dispatch) {
    dispatch({ type: "LOGOUT" });
  };
}

export function fetchCampaigns() {
  var fetchCampaignsConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/appnotification/ls",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      a_id: cookies.get("aid"),
      start: 0,
      ipp: 100,
    },
  };
  return function (dispatch) {
    dispatch({ type: FETCH_CAMPAIGNS });
    axios(fetchCampaignsConfig)
      .then((response) => {
        dispatch({ type: FETCH_CAMPAIGNS_FULFILLED, payload: response.data });
        console.log(response);
      })
      .catch((err) => {
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: FETCH_CAMPAIGNS_REJECTED, payload: err.response });
      });
  };
}

export function openCampaign(cmp_id) {
  console.log(cmp_id);
  var openCampaignConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/appnotification/dt",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { cmp_id, a_id: cookies.get("aid") },
  };
  return function (dispatch) {
    dispatch({ type: OPEN_CAMPAIGN });
    axios(openCampaignConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: OPEN_CAMPAIGN_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: OPEN_CAMPAIGN_REJECTED, payload: err.response });
      });
  };
}

export function createCampaign(data) {
  console.log(data);
  var createCampaignConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/appnotification/cr",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { ...data, a_id: cookies.get("aid") },
  };
  return function (dispatch) {
    dispatch({ type: CREATE_CAMPAIGN });

    let request = axios(createCampaignConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: CREATE_CAMPAIGN_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: CREATE_CAMPAIGN_REJECTED, payload: err.response });
      });

    request.then((completed) => {
      console.log("upload completed");
      dispatch({ type: CREATE_CAMPAIGN_COMPLETED });
    });

    // request.then(complete =>
    //   console.log("completed");
    //   dispatch({type : CREATE_CAMPAIGN_COMPLETED })
    // );
  };
}

export function launchCampaign(cmp_id) {
  var launchCampaignConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/appnotification/launch",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { cmp_id, a_id: cookies.get("aid") },
  };
  return function (dispatch) {
    dispatch({ type: LAUNCH_CAMPAIGN });
    axios(launchCampaignConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: LAUNCH_CAMPAIGN_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: LAUNCH_CAMPAIGN_REJECTED, payload: err.response });
      });
  };
}

export function relaunchCampaign(cmp_id) {
  var relaunchCampaignConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/appnotification/relaunch",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { cmp_id, a_id: cookies.get("aid") },
  };
  return function (dispatch) {
    dispatch({ type: RELAUNCH_CAMPAIGN });
    axios(relaunchCampaignConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: RELAUNCH_CAMPAIGN_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: RELAUNCH_CAMPAIGN_REJECTED, payload: err.response });
      });
  };
}

export function cancelCampaign(cmp_id) {
  var launchCampaignConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/appnotification/cl",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { cmp_id, a_id: cookies.get("aid") },
  };
  return function (dispatch) {
    dispatch({ type: CANCEL_CAMPAIGN });
    axios(launchCampaignConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: CANCEL_CAMPAIGN_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: CANCEL_CAMPAIGN_REJECTED, payload: err.response });
      });
  };
}

export function deleteCampaign(cmp_id) {
  var launchCampaignConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/appnotification/dl",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { cmp_id, a_id: cookies.get("aid") },
  };
  return function (dispatch) {
    dispatch({ type: DELETE_CAMPAIGN });
    axios(launchCampaignConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: DELETE_CAMPAIGN_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: DELETE_CAMPAIGN_REJECTED, payload: err.response });
      });
  };
}
