import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  getSubSalesGroupEmail,
  getSalesGroupName,
  getPreviousTargets,
} from "../helper";

function PreviousTargetModel(props) {
  const [month, setMonth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [targetData, setTargetData] = useState(null);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = await getPreviousTargets(props.businessHead, month);
    console.log({ data });
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="leaddistributionmodel"
      >
        <Modal.Header
          closeButton
          className="leadmodelheaderheading"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Previous Target
          <button
            onClick={props.handleClose}
            style={{ marginLeft: "auto", fontSize: "18px" }}
          >
            x
          </button>
        </Modal.Header>

        <div>
          <form
            onSubmit={submitHandler}
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <div
              className="addprogram2container"
              style={{
                flexDirection: "row",
                paddingBottom: "0px",
                paddingTop: "16px",
              }}
            >
              <div>
                <p className="addadmissiontargetmonth">Select Month</p>
                <div>
                  <input
                    className="addadmissiontargetmonthdropdown"
                    type="month"
                    id="birthday"
                    name="birthday"
                    value={month}
                    required
                    onChange={(e) => setMonth(e.target.value)}
                  />
                </div>
              </div>

              <div></div>
            </div>
            <div style={{ marginRight: "20px" }}>
              <button
                onClick={submitHandler}
                style={{ background: "#035642" }}
                className="addprogramsubmitbtn"
              >
                {" "}
                View{" "}
              </button>
            </div>
          </form>

          <div>
            {targetData && (
              <>
                <div style={{ maxHeight: "500px", margin: "22px 16px" }}>
                  <div
                    className=""
                    style={{ overflow: "scroll", width: "100%" }}
                  >
                    <div style={{ width: "1700px" }}>
                      <table className="table table-striped groupleadtable">
                        <thead>
                          <tr className="">
                            <th
                              scope="col"
                              style={{ width: "250px", height: "48px" }}
                            >
                              Business Head
                            </th>
                            <th scope="col" style={{ width: "320px" }}>
                              Sales Head
                            </th>
                            <th scope="col" style={{ width: "180px" }}>
                              Program
                            </th>
                            {props.leadSource &&
                              props.leadSource.map((data, index) => {
                                return (
                                  <th
                                    scope="col"
                                    style={{ width: "114px" }}
                                    key={index}
                                  >
                                    {data}
                                  </th>
                                );
                              })}
                            <th scope="col" style={{ width: "114px" }}>
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {targetData &&
                            targetData.map((data, idx) => {
                              return (
                                <tr className="uper_table_container" key={idx}>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {getSalesGroupName(
                                      props.salesGroupArray,
                                      data.salesgroup
                                    ) || "--"}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {getSubSalesGroupEmail(
                                      props.subSalesArray,
                                      data.subSalesGroupId
                                    ) || "--"}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.programLSKey}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["ACP"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["Affiliate"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["BTL/Database"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["Community"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Display"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["FB"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Mailer"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Referral"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount && data.plsCount["Search"]}
                                  </th>
                                  <th
                                    style={{
                                      borderRight:
                                        "1px solid rgba(60, 72, 82, 0.25)",
                                    }}
                                  >
                                    {data.plsCount &&
                                      data.plsCount["ACP"] +
                                        data.plsCount["Affiliate"] +
                                        data.plsCount["BTL/Database"] +
                                        data.plsCount["Community"] +
                                        data.plsCount["Display"] +
                                        data.plsCount["FB"] +
                                        data.plsCount["Mailer"] +
                                        data.plsCount["Referral"] +
                                        data.plsCount["Search"]}
                                  </th>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PreviousTargetModel;
