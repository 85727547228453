import { FETCH_QUIZ_CATEGORIES,
         FETCH_QUIZ_CATEGORIES_FULFILLED,
         FETCH_QUIZ_CATEGORIES_REJECTED,
         FETCH_QUIZ_TESTS,
         FETCH_QUIZ_TESTS_FULFILLED,
         FETCH_QUIZ_TESTS_REJECTED,
         FETCH_VIDEO_CATEGORIES,
         FETCH_VIDEO_CATEGORIES_FULFILLED,
         FETCH_VIDEO_CATEGORIES_REJECTED, 
         FETCH_VIDEO_TOPICS,
         FETCH_VIDEO_TOPICS_FULFILLED,
         FETCH_VIDEO_TOPICS_REJECTED,
        } from "../actions/campaignActionsS2"
export default function reducer(state={
    categories:{},
    errors : {
      message : "",
      description : ""
    },
    logout : false,
  }, action) {
    switch (action.type) {
      case FETCH_QUIZ_CATEGORIES: {
        console.log("fetching quiz categories");
        return state
      }
      case FETCH_QUIZ_CATEGORIES_FULFILLED : {
        console.log(action.payload);
        let data = action.payload.data;
        return {
          ...state,
          categories : {
            ...state.categories,
            [data.plugin_id] : data.categories 
          }
        }
      }
      case FETCH_QUIZ_CATEGORIES_REJECTED : {
        // console.log(action.payload.response);
        let message,description;
        if(action.payload){
          let {data} = action.payload;
          message = 'Error',
          description = data.message
        } else {
          message = 'Server Error',
          description = 'Please try again later'
        }
        // console.log(action.payload.response);
        return {
          ...state,
          errors : {message,description}
        }
      }
      case FETCH_QUIZ_TESTS: {
        return state
      }
      case FETCH_QUIZ_TESTS_FULFILLED : {
        console.log('From Reducer - > FETCH_QUIZ_TESTS_FULFILLED');
        console.log(action.payload);
        let category = action.payload.data.category[0];
        console.log(state);
        console.log(category);
        return {
          ...state,
          categories : {
            ...state.categories,
            [category.plugin_id] : state.categories[category.plugin_id].map(catg => {
                if(catg._id === category._id){
                  catg.topics = action.payload.data.tests
                }
                return catg
            })
          },
          prnm : action.payload.data.prnm
        }
      }
      case FETCH_QUIZ_TESTS_REJECTED : {
        let message,description;
        if(action.payload){
          let {data} = action.payload;
          message = 'Error',
          description = data.message
        } else {
          message = 'Server Error',
          description = 'Please try again later'
        }
        console.log(action.payload);
        return {
          ...state,
          errors : {message,description}
        }
      }
      case FETCH_VIDEO_CATEGORIES : {
        console.log(action.payload);
        return state
      }
      case FETCH_VIDEO_CATEGORIES_FULFILLED : {
        console.log(action.payload);
        let {plugin_id} = action.payload.data; 
        return {
          ...state,
          categories : {
            ...state.categories,
            [plugin_id] : action.payload.data.chapters 
          }
        }
      }
      case FETCH_VIDEO_CATEGORIES_REJECTED : {
        console.log(action.payload);
        let message,description;
        if(action.payload){
          let {data} = action.payload;
          message = 'Error',
          description = data.message
        } else {
          message = 'Server Error',
          description = 'Please try again later'
        }
        console.log(action.payload);
        return {
          ...state,
          errors : {message,description}
        }
      }
      case FETCH_VIDEO_TOPICS : {
        console.log(action.payload);
        return state
      }
      case FETCH_VIDEO_TOPICS_FULFILLED : {
        console.log('From Reducer - > FETCH_VIDEO_TOPICS_FULFILLED');
        console.log(action.payload);
        let {data} = action.payload;
        return {
          ...state,
          categories : {
            ...state.categories,
            [data.plugin_id] : state.categories[data.plugin_id].map(catg => {
                  if(catg._id === data.chap_id){
                    catg.topics = data.topics
                  }
                  return catg
              })
          },
          prnm : data.prnm
        }
      }
      case FETCH_VIDEO_TOPICS_REJECTED : {
        console.log(action.payload);
        let message,description;
        if(action.payload){
          let {data} = action.payload;
          message = 'Error',
          description = data.message
        } else {
          message = 'Server Error',
          description = 'Please try again later'
        }
        console.log(action.payload);
        return {
          ...state,
          errors : {message,description}
        }
      }
      case "LOGOUT":{
        return{...state,
          data: {
            group : {},
            plugins : {},
            ready : false,
            success : false,
            promos : [],
            currentPromo : {}
          },
          fgd_loading: false,
          lp_loading : false,
          loading : false,
        }
      }
    }
    return state
}
