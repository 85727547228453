import React, { Component, useState, useEffect } from "react";
import "./ApplyPayment.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Icon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import {
  fetchNextCustomerPaymentAmount,
  getManagerOtp,
  applyCustomerCashPayment,
  getUnallocatedPayments,
  allocateTokenAmountToIndividualPayments,
} from "../../../actions/general";
import {
  fetchCourses,
  fetchFeeStructure,
} from "../../../actions/editFeeAction";

const style = {
  position: "absolute",
  top: "10%",
  left: "10%",
  transform: "translate(0%, -8%)",
  width: "80%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const OPS_LEVEL_ONE = 21,
  OPS_LEVEL_TWO = 22,
  OPS_LEVEL_THREE = 23,
  OPS_LEVEL_FOUR = 24;
let ApplyPayment = ({ open, transaction, ...props }) => {
  console.log({ transaction, props });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(
    transaction.amount - transaction.alreadyUsedAmount || 0
  );
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<any | null>(null);
  const [students, setStudents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    console.log("componentDidMount");
    console.log({ transaction });
    props.fetchCourses();
  }, []);
  useEffect(() => {
    console.log({ "props.courses": props.courses });
    // let coursesTemp = props.courses.filter(
    //   (el) => el.paymentCollectionType == 2
    // );
    setCourses(props.courses);
    let index = props.courses.findIndex(
      (el) => el._id == transaction.crs_pg_id
    );
    setSelectedCourse(props.courses[index]);
  }, [props.courses]);
  let getUnallocatedStudents = async () => {
    console.log("getUnallocatedStudents");
    setLoading(true);
    let { success, message, ...data } = await getUnallocatedPayments({
      crs_pg_id: selectedCourse?._id || "",
      loc_id: selectedLocation?._id || "",
      limit: rowsPerPage,
      skip: page * rowsPerPage,
      curr: transaction.currency,
      aid: transaction.aid,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    setStudents(data.payments);
  };
  useEffect(() => {
    if (selectedCourse?._id) getUnallocatedStudents();
  }, [page, rowsPerPage, selectedCourse, selectedLocation]);

  useEffect(() => {
    let allocatedAmount = 0;
    students.forEach((stu) => {
      if (!isNaN(stu.allocatedAmount)) allocatedAmount += stu.allocatedAmount;
    });
    setRemainingAmount(
      transaction.amount - transaction.alreadyUsedAmount - allocatedAmount
    );
  }, [students]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let getDate = (date: string) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };
  let convertPriceToLocalString = function (amount: number | string) {
    return parseFloat(String(amount)).toLocaleString("hi");
  };
  let allocateToPayment = async () => {
    console.log("addPartnerInvoice");
    if (remainingAmount < 0)
      return alert(
        "Invalid allocation request. Amount Remaining to be adjusted is less than the sum of proposed allocations."
      );
    if (
      !window.confirm(
        "Are you sure? These allocations cannot be edited/removed later."
      )
    )
      return;
    setLoading(true);
    let studentsTemp = [...students];
    studentsTemp = studentsTemp.filter((stu) => stu.allocatedAmount);
    let { success, message, ...data } =
      await allocateTokenAmountToIndividualPayments({
        payments: studentsTemp,
        bankTransactionId: transaction._id,
      });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Success! The payment has been recorded.");
    // props.onClose()
    return props.closeModal();
  };

  return (
    <Modal
      open={open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="ApplyPayment">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Mark Transaction as Paid Undeposited Cash
            </Typography>
            <Typography id="modal-modal-description" sx={{}}>
              {`With this transaction, Unallocated amount is ${
                transaction.currency
              } ${convertPriceToLocalString(
                transaction.amount - (transaction.alreadyUsedAmount || 0)
              )}`}
            </Typography>
            {selectedCourse?._id && (
              <p>
                {`Amount that can be adjusted is:`}{" "}
                <b>
                  {transaction.currency}
                  {` ${convertPriceToLocalString(remainingAmount)}`}
                </b>
              </p>
            )}

            <Autocomplete
              disablePortal
              // disableClearable
              fullWidth
              autoHighlight
              value={selectedCourse}
              onChange={(event, selectedCourse) => {
                setSelectedCourse({ ...selectedCourse });
                setSelectedLocation(null);
              }}
              disabled
              getOptionLabel={(selectedCourse) => {
                return selectedCourse.name || "";
              }}
              id="courseSelectorModal"
              options={courses}
              renderInput={(params) => {
                return (
                  <TextField
                    label="Select a Course"
                    error={!selectedCourse?._id}
                    {...params}
                    // onChange={(event, selectedCourse) => {
                    //   setSelectedCourse({ ...selectedCourse });
                    // }}
                    variant="outlined"
                    helperText="Select a Course to pending student unsettled payments"
                  />
                );
              }}
            />
            {/* <Autocomplete
              disablePortal
              // disableClearable
              fullWidth
              autoHighlight
              value={selectedLocation}
              onChange={(event, selectedLocation) => {
                setSelectedLocation({ ...selectedLocation });
              }}
              getOptionLabel={(selectedLocation) =>
                selectedLocation?.name
                  ? `${selectedLocation.name}, ${selectedLocation.city}, ${selectedLocation.country}`
                  : ""
              }
              id="courseSelectorModal"
              options={props.locations}
              renderInput={(params) => {
                return (
                  <TextField
                    label="Select a Location"
                    {...params}
                    // onChange={(event, selectedLocation) => {
                    //   setSelectedLocation({ ...selectedLocation });
                    // }}
                    variant="outlined"
                  />
                );
              }}
            /> */}

            <TableContainer className="mainTable" component={Paper}>
              <Table aria-label="custom pagination table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Date
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Name
                    </TableCell>
                    <TableCell>Contact Number</TableCell>
                    <TableCell>Course</TableCell>
                    <TableCell>Student Batch</TableCell>
                    <TableCell>
                      <span>Amount to be Allocated</span>
                    </TableCell>
                    <TableCell>
                      <span>Amount Allocated</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={30}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={30}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={30}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={30}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={30}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={30}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : students?.length > 0 ? (
                  <React.Fragment>
                    <TableBody>
                      {students.map(
                        (
                          row,
                          index //name, location, l_date
                        ) => (
                          <TableRow key={row._id}>
                            <TableCell component="th" scope="row">
                              {getDate(row.createdAt)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.studentName}
                            </TableCell>
                            <TableCell>{row.mobile}</TableCell>
                            <TableCell>{row.courseName}</TableCell>
                            <TableCell>
                              {row.batchName || "Waiting for Next Batch"}
                            </TableCell>
                            <TableCell>
                              {convertPriceToLocalString(
                                row.amount - row.alreadyAllocatedAmount
                              )}
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="outlined-basic"
                                value={students[index].allocatedAmount || ""}
                                type="number"
                                onChange={(e) => {
                                  let studentsTemp = [...students];
                                  studentsTemp[index].allocatedAmount = Number(
                                    e.target.value
                                  );
                                  studentsTemp[index].bankTransactionId =
                                    transaction._id;
                                  setStudents(studentsTemp);
                                }}
                                label="Amount Allocated"
                                variant="outlined"
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </React.Fragment>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={8}
                        align="center"
                      >
                        <span
                          style={{ textAlign: "center", fontSize: "18spanx" }}
                        >
                          No entries fetched.
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                colSpan={8}
              />
            </TableContainer>

            <Button variant="contained" onClick={allocateToPayment}>
              Save
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
    permissions: state.login.permissions,
    resources: state.login.resources,
    editfee: state.editfee,
    courses: state.appreview.courses,
    coursePackages: state.appreview.coursePackages,
    locations: state.appreview.locations,
  };
};

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyPayment);
