import React, { useEffect } from "react";
import { connect } from "react-redux";
// import PropTypes from 'prop-types'
import {
  loginUser,
  renewAccessToken,
  verifyUser,
} from "../../actions/loginActions";
import { clearNotification } from "../../actions/notifierActions";
import {
  Form,
  Spin,
  notification,
  // Icon,
  Input,
  Button,
  Checkbox,
  InputNumber,
} from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const FormItem = Form.Item;

// @connect((store) => {
//   console.log(store);
//   return {
//     user: store.login.user,
//     loading : store.login.fetching,
//     notification : store.notifier.data,
//     prmsn:store.promo
//   };
// })

let NormalLoginForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (cookies.get("isLoggedIn")) {
      if (location.search && location.search.includes("?redirect=")) {
        navigate(decodeURIComponent(location.search.split("=")[1]));
      } else {
        navigate("/");
      }
    }
    if (props.notification.type) {
      Notify(
        props.notification.type,
        props.notification.title,
        props.notification.message
      );
      props.dispatch(clearNotification());
    }
  });

  let Notify = (type, message, desc) => {
    notification[type]({
      message: message,
      description: desc,
    });
  };

  let handleVerification = (values) => {
    // e.preventDefault();
    console.log({
      "props.user.token.aid": props.user.token.aid,
    });
    props.dispatch(verifyUser(props.user.token.aid, values));
    console.log("Received values of form: ", values);
    // props.form.validateFields((err, values) => {
    //   if (!err) {
    //     props.dispatch(verifyUser(props.user.token.request_token,values));
    //     console.log('Received values of form: ', values);
    //   }
    // });
  };

  let onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    props.dispatch(renewAccessToken());
    console.log("Received values of form: ", {});
  };
  let handleSubmit = (values) => {
    // e.preventDefault();
    console.log("Success:", values);
    // props.form.validateFields((err, values) => {
    //   if (!err) {
    //     props.dispatch(loginUser(values));
    //     console.log('Received values of form: ', values);
    //   }
    // });
    props.dispatch(loginUser(values));
    console.log("Received values of form: ", values);
  };

  const { verifying } = props.user;
  return (
    // const { getFieldDecorator } = props.form;
    <div className="full-height center-all login-background">
      {!verifying && (
        <Form
          onFinish={handleSubmit}
          className="login-form"
          onFinishFailed={onFinishFailed}
        >
          <img
            className="login-image"
            alt="imarticusLogo"
            src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            width="194"
            height="40"
          />
          <Spin size="large" spinning={props.loading}>
            <FormItem
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
              type="email"
            >
              <Input
                placeholder="Email"
                prefix={
                  // <Icon type="mail" style={{ fontSize: 13 }} />
                  <MailOutlined style={{ fontSize: 13 }} />
                }
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{
                  backgroundColor: "#055646",
                  color: "#fff",
                  fontFamily: "Archivo Black",
                }}
              >
                Log in
              </Button>
            </FormItem>
          </Spin>
        </Form>
      )}
      {verifying && (
        <Form
          onFinish={handleVerification}
          onFinishFailed={onFinishFailed}
          className="login-form"
        >
          <img
            className="login-image"
            alt="imarticusLogo"
            src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            width="194"
            height="40"
          />
          <Spin size="large" spinning={props.loading}>
            <FormItem
              label="OTP"
              name="otp"
              rules={[{ required: true, message: "Please enter your otp!" }]}
            >
              <Input
                type="number"
                placeholder="One time password"
                prefix={
                  // <Icon type="phone" style={{ fontSize: 13 }} />
                  <PhoneOutlined style={{ fontSize: 13 }} />
                }
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{
                  backgroundColor: "#055646",
                  color: "#fff",
                  fontFamily: "Archivo Black",
                }}
              >
                Verify
              </Button>
            </FormItem>
          </Spin>
        </Form>
      )}
    </div>
  );
};

// const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

const mapStateToProps = (state) => {
  console.log(state);
  return {
    user: state.login.user,
    loading: state.login.fetching,
    notification: state.notifier.data,
    prmsn: state.promo,
  };
};
export default connect(mapStateToProps)(NormalLoginForm);
// export default WrappedNormalLoginForm;
