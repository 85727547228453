import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function LeadModel(props) {
  console.log(props.salesData);

  const [tableData, setTableData] = useState();

  useEffect(() => {
    let data =
      props.advisorAggregateData &&
      props.advisorAggregateData.filter(
        (data) => data.subSalesGroupId == props.salesData._id
      );
    setTableData(data);
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="leaddistributionmodel"
      >
        <div style={{ padding: "20px", height: "700px", overflow: "scroll" }}>
          <h2
            style={{ fontSize: "20px", color: "#097252", textAlign: "initial" }}
          >
            {props.salesData && props.salesData.email}'s Team
          </h2>
          <div>
            <div className="" style={{ width: "100%" }}>
              <table className="table table-striped groupleadtable">
                <thead>
                  <tr className="">
                    <th scope="col" style={{ width: "116px", height: "48px" }}>
                      Program
                    </th>
                    <th scope="col" style={{ width: "287px" }}>
                      Advisor
                    </th>
                    <th scope="col" style={{ width: "130px" }}>
                      Total Lead
                    </th>
                    {props.lsplsArray &&
                      props.lsplsArray
                        .sort((a, b) => a - b)
                        .map((data, index) => {
                          return (
                            <th
                              scope="col"
                              style={{ width: "114px", padding: "0px 8px" }}
                              key={index}
                            >
                              {data}
                            </th>
                          );
                        })}
                  </tr>
                </thead>
                {tableData &&
                  tableData.map((data) => {
                    return (
                      <tr
                        className="uper_table_container"
                        style={{ border: "none" }}
                      >
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.mx_program_3}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.OwnerIdName}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.total_pls_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.acp_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.affiliate_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.btldatabase_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.community_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.display_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.fb_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.mailer_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.referral_count}
                        </th>
                        <th style={{ borderRight: "1px solid #dee2e6" }}>
                          {data.search_count}
                        </th>
                      </tr>
                    );
                  })}
              </table>

              <p>
                Note: The Aggregate Analysis Shows the data with 20 min time
                difference
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LeadModel;
