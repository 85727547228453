import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";

const cookies = new Cookies();

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

export function loginUser(data) {
  return function (dispatch) {
    dispatch({ type: "LOGIN_USER" });
    axios
      .post(constants.API_ENDPOINT + "/account/req_otp_email", {
        email: data.email,
      })
      .then((response) => {
        console.log(response);
        dispatch({ type: "LOGIN_USER_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "LOGIN_USER_REJECTED", payload: err });
      });
  };
}

export function logout() {
  renewATorLogout(dispatch);
  return function (dispatch) {
    dispatch({ type: "LOGOUT" });
  };
}

export function renewAccessToken() {
  var renewConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/account/g_at_w",
    data: {
      a_id: cookies.get("aid"),
      at: cookies.get("at"),
      cl: "W",
      rt: cookies.get("rt"),
      k_ey: cookies.get("secret"),
    },
  };

  return function (dispatch) {
    dispatch({ type: "RENEW_AT" });
    axios(renewConfig)
      .then((response) => {
        console.log(response);
        let expirydate = new Date();

        let options = {
          domain: constants.DOMAIN,
          path: "/",
        };
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("at", response.data.data.at, options);
        dispatch({ type: "RENEW_AT_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        let options = {
          expires: expirydate,
          domain: constants.DOMAIN,
          path: "/",
        };
        cookies.remove("rt", options);
        cookies.remove("at", options);
        cookies.remove("aid", options);
        cookies.remove("isLoggedIn", options);
        dispatch({ type: "RENEW_AT_REJECTED", payload: err });
      });
  };
}

export function verifyUser(aid, data) {
  var verifyConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/account/verify_otps",
    data: {
      e_otp: data.otp,
      aid: aid,
    },
  };
  return function (dispatch) {
    dispatch({ type: "VERIFY_OTP" });
    axios(verifyConfig)
      .then((response) => {
        console.log(response);
        let expirydate = new Date();

        let options = {
          expires: expirydate,
          domain: constants.DOMAIN,
          path: "/",
        };
        expirydate.setDate(expirydate.getDate() + 180);
        cookies.set("at", response.data.data.at, options);
        cookies.set("aid", response.data.data.aid, options);
        cookies.set("secret", response.data.data.secret, options);
        cookies.set("rt", response.data.data.rt, options);
        cookies.set("isLoggedIn", true, options);
        cookies.set("pid", response.data.data.pid, options);
        cookies.set("s_id", response.data.data.sid, options);
        cookies.set("user", response.data.data.Account, options);
        dispatch({ type: "VERIFY_OTP_FULFILLED", payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "VERIFY_OTP_REJECTED", payload: err });
      });
  };
}
