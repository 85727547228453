import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Popconfirm,
  Menu,
  Breadcrumb,
  // Icon,
  Button,
  PageHeader,
} from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../images/new-logo.png";
import Cookies from "universal-cookie";
import * as constants from "../../constants";
import { logout } from "../../actions/loginActions";
const { SubMenu } = Menu;
import {
  LogoutOutlined,
  UserOutlined,
  SolutionOutlined,
  NotificationOutlined,
  AccountBookOutlined,
  ProjectOutlined,
} from "@ant-design/icons";

const cookies = new Cookies();

function getDefault(hash) {
  if (hash.substr(0, 10) === "#/campaign") {
    return "2";
  }
  return "1";
}

let Sidebar = (props) => {
  const navigate = useNavigate();
  let [collapsed, setCollapsed] = useState(false);

  let toggleCollapsed = () => {
    setCollapsed(collapsed);
  };

  let openRoute = (e) => {
    const { key } = e;
    if (!key) return;
    switch (key.toString()) {
      case "promo": {
        navigate("/promocode");
        return;
      }
      case "promo_0": {
        navigate("/promocode/grouppromocode");
        return;
      }
      case "promo_1": {
        navigate("/promocode/generalpromocode");
        return;
      }
      case "promo_2": {
        navigate("/promocode/specialpromocode");
        return;
      }
      case "camp_1": {
        navigate("/campaign");
        return;
      }
      case "review_1": {
        navigate("/review");
        return;
      }
      case "app_forms": {
        navigate("/applicationsreview");
        return;
      }
      case "lead_distribution": {
        navigate("/leaddistributionpanel");
        return;
      }
      case "undepositedCash": {
        navigate("/cashpayment");
        return;
      }
      case "paymentStatus": {
        navigate("/paymentStatus");
        return;
      }
      case "imarticus_collected": {
        navigate("/imarticus_collected");
        return;
      }
      case "partner_collected": {
        navigate("/partner_collected");
        return;
      }
      case "set_user_payment_preferences": {
        navigate("set_user_payment_preferences");
        return;
      }
      case "registration": {
        navigate("/edit-transaction/registration");
        return;
      }
      case "application": {
        navigate("/edit-transaction/application");
        return;
      }
      case "installment": {
        navigate("/edit-transaction/installment");
        return;
      }
      case "installmentdates": {
        navigate("/edit-transaction/installmentdates");
        return;
      }
      case "applyTokenPayment": {
        navigate("/applyTokenPayment");
        return;
      }
      case "legacy_installment_collection": {
        navigate("/legacy_installment_collection");
        return;
      }
      case "live_payment_tracking": {
        navigate("/live_payment_tracking");
        return;
      }
      case "0": {
        props.logout();
        return;
      }
    }
  };

  let logout = () => {
    console.log("logging out user");
    let options = {
      domain: constants.DOMAIN,
      path: "/",
    };
    cookies.remove("rt", options);
    cookies.remove("at", options);
    cookies.remove("aid", options);
    cookies.remove("isLoggedIn", options);
    window.location.reload();
  };
  const logoutConfirmMsg = "Are you sure want to logout?";
  const logoBody = (
    <center>
      <div className="logo">
        <img src={logo} />
      </div>
    </center>
  );
  const logoutBtn = (
    <Popconfirm
      placement="rightBottom"
      title={logoutConfirmMsg}
      onConfirm={logout}
      okText="Yes"
      cancelText="No"
    >
      {/* <Icon type="logout" /> */}
      <LogoutOutlined />
      <span>Logout</span>
    </Popconfirm>
  );

  const checkVisibility = (resource) => {
    if (!props.permissions) {
      return true;
    } else {
      if (!props.permissions[resource]) {
        return false;
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const defaultSelected = getDefault(window.location.hash);

  const { SubMenu } = Menu;
  const { Header, Content, Sider } = Layout;

  const getDefaultSelectedKey = () => {
    let key = "";

    const url = window.location.href;

    if (url.includes("promocode/generalpromocode")) {
      key = "promo_1";
    }
    if (url.includes("promocode/specialpromocode")) {
      key = "promo_2";
    }
    if (url.includes("applicationsreview")) {
      key = "app_forms";
    }
    if (url.includes("leaddistributionpanel")) {
      key = "lead_distribution";
    }
    if (url.includes("cashpayment")) {
      key = "undepositedCash";
    }
    if (url.includes("imarticus_collected")) {
      key = "imarticus_collected";
    }
    if (url.includes("partner_collected")) {
      key = "partner_collected";
    }
    if (url.includes("set_user_payment_preferences")) {
      key = "set_user_payment_preferences";
    }
    if (url.includes("edit-transaction/registration")) {
      key = "registration";
    }
    if (url.includes("edit-transaction/application")) {
      key = "application";
    }
    if (url.includes("edit-transaction/installment")) {
      key = "installment";
    }
    if (url.includes("edit-transaction/installmentdates")) {
      key = "installmentdates";
    }
    if (url.includes("paymentStatus")) {
      key = "paymentStatus";
    }
    if (url.includes("applyTokenPayment")) {
      key = "applyTokenPayment";
    }
    if (url.includes("legacy_installment_collection")) {
      key = "legacy_installment_collection";
    }
    if (url.includes("live_payment_tracking")) {
      key = "live_payment_tracking";
    }

    return key;
  };

  const getDefaultOpenKey = () => {
    let key;
    const url = window.location.href;

    if (url.includes("promocode")) {
      key = "promo";
    }
    if (url.includes("applicationsreview")) {
      key = "";
    }
    if (
      url.includes("cashpayment") ||
      url.includes("imarticus_collected") ||
      url.includes("partner_collected") ||
      url.includes("set_user_payment_preferences") ||
      url.includes("applyTokenPayment") ||
      url.includes("legacy_installment_collection") ||
      url.includes("paymentStatus") ||
      url.includes("live_payment_tracking")
    ) {
      key = "payments";
    }
    if (url.includes("edit-transaction")) {
      key = "edit-fee";
    }
    if (key == undefined) {
      key = "";
    }
    return key;
  };

  return (
    <div style={{ maxWidth: "249px !important", width: 249 }}>
      <Menu
        defaultOpenKeys={[getDefaultOpenKey()]}
        mode="inline"
        theme="dark"
        style={{ backgroundColor: "#055646" }}
        inlineCollapsed={collapsed}
        onClick={openRoute}
        defaultSelectedKeys={[getDefaultSelectedKey()]}
      >
        {checkVisibility("7") && (
          <SubMenu
            key="promo"
            mode="inline"
            style={{ backgroundColor: "#055646" }}
            title={
              <span>
                <UserOutlined />
                <span>Promocode</span>
              </span>
            }
          >
            {checkVisibility("30") && (
              <Menu.Item key="promo_1">General Promocode</Menu.Item>
            )}
            <Menu.Item key="promo_2">Special Promocode</Menu.Item>
          </SubMenu>
        )}
        <Menu.Item key="app_forms">
          <SolutionOutlined />
          <span>Applications Review</span>
        </Menu.Item>
        {checkVisibility("39") && (
          <Menu.Item key="lead_distribution">
            <ProjectOutlined rotate={180} />
            <span>Lead Distribution</span>
          </Menu.Item>
        )}
        {
          <SubMenu
            key="payments"
            mode="inline"
            style={{ backgroundColor: "#055646" }}
            title={
              <span>
                <UserOutlined />
                <span>Payments</span>
              </span>
            }
          >
            {checkVisibility("33") && (
              <>
                <Menu.Item key="paymentStatus" style={{ paddingLeft: "10px" }}>
                  <AccountBookOutlined />
                  <span>Track Payment Status</span>
                </Menu.Item>
                <Menu.Item
                  key="set_user_payment_preferences"
                  style={{ paddingLeft: "10px" }}
                >
                  <AccountBookOutlined />
                  <span>Set User Preferences (Payment)</span>
                </Menu.Item>
                <Menu.Item
                  key="applyTokenPayment"
                  style={{ paddingLeft: "10px" }}
                >
                  <AccountBookOutlined />
                  <span>Allocate Token Payment</span>
                </Menu.Item>
                <Menu.Item
                  key="undepositedCash"
                  style={{ paddingLeft: "10px" }}
                >
                  <AccountBookOutlined />
                  <span>Individual Cash Collected Payments</span>
                </Menu.Item>
                <Menu.Item
                  key="imarticus_collected"
                  style={{ paddingLeft: "10px" }}
                >
                  <AccountBookOutlined />
                  <span>Allocate Imarticus Collected Payments</span>
                </Menu.Item>
                <Menu.Item
                  key="partner_collected"
                  style={{ paddingLeft: "10px" }}
                >
                  <AccountBookOutlined />
                  <span>Allocate Partner Collected Payments</span>
                </Menu.Item>
                {checkVisibility("37") && (
                  <Menu.Item
                    key="legacy_installment_collection"
                    style={{ paddingLeft: "10px" }}
                  >
                    <AccountBookOutlined />
                    <span>Legacy Installment Collection System</span>
                  </Menu.Item>
                )}
              </>
            )}
            {
              <Menu.Item
                key="live_payment_tracking"
                style={{ paddingLeft: "10px" }}
              >
                <AccountBookOutlined />
                <span>Live Payment Tracking</span>
              </Menu.Item>
            }
          </SubMenu>
        }
        <SubMenu
          key="edit-fee"
          mode="inline"
          style={{ backgroundColor: "#055646" }}
          title={
            <span>
              <UserOutlined />
              <span>Edit Transaction</span>
            </span>
          }
        >
          <Menu.Item key="registration">
            <SolutionOutlined />
            <span>Registration</span>
          </Menu.Item>
          <Menu.Item key="application">
            <SolutionOutlined />
            <span>Application</span>
          </Menu.Item>
          {checkVisibility("33") && (
            <>
              <Menu.Item key="installment">
                <SolutionOutlined />
                <span>Installment Plan</span>
              </Menu.Item>
              <Menu.Item key="installmentdates">
                <SolutionOutlined />
                <span>Installment Dates</span>
              </Menu.Item>
            </>
          )}
        </SubMenu>
        <Menu.Item key="logout">{logoutBtn}</Menu.Item>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.login.permissions,
  resources: state.login.resources,
});

export default connect(mapStateToProps)(Sidebar);
