import React, { useState, useEffect } from "react";
import "./EditSubventionAmount.scss";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { editSubventionAmount } from "../../../../actions/general";
import { Info } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35vw",
  height: "60vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditSubventionAmount = ({ open, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [updatedSubventionAmount, setUpdatedSubventionAmount] = useState(null);

  const onSubmit = () => {
    setLoading(true);
    const { success, message, ...data } = editSubventionAmount({
      customerPaymentId: props.data._id,
      updatedSubventionAmount,
    });

    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      setLoading(false);
      return;
    }

    props.getUnallocatedStudents();
    setLoading(false);
    props.onClose();
  };
  return (
    <Modal
      open={open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="EditSubventionPoint">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Adjustment Amount
              </Typography>
              <div
                style={{
                  position: "relative",
                }}
              >
                <div className="tooltipabc">
                  <Info
                    style={{
                      color: "#1976d2",
                      marginLeft: "4px",
                      cursor: "pointer",
                      height: "24px",
                      width: "24px",
                      marginTop: "-8px",
                    }}
                  />
                  <div className="tooltiptext">
                    <ul style={{ padding: "16px" }}>
                      <li>
                        Convenience Fees / Gateway Charges / Service Fees
                        charged by external payment services.
                      </li>
                      <li style={{ marginTop: "4px" }}>
                        Maximum of 20% of the total amount being paid can be
                        adjusted.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                margin: "12px 0",
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: ""
              }}
            >
              <div style={{ margin: "12px 0" }}>
                Total Amount to be Allocated : {props.data.amount}
              </div>
              <div style={{ margin: "12px 0" }}>
                Amount Already Allocated : {props.data.alreadyAllocatedAmount}
              </div>
              <div style={{ margin: "12px 0" }}>
                Current Adjustment Amount : {props.data.subventionAmount}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="outlined-basic"
                  value={updatedSubventionAmount}
                  type="number"
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      return alert(
                        "Adjustment Amount cannot be less than Zero"
                      );
                    }
                    if (
                      e.target.value >
                      (props.data.amount + props.data.subventionAmount) / 5
                    ) {
                      return alert(
                        `Adjustment Amount cannot be greater than ${
                          (props.data.amount + props.data.subventionAmount) / 5
                        }`
                      );
                    }
                    setUpdatedSubventionAmount(e.target.value);
                  }}
                  label="New Adjustment Amount"
                  variant="outlined"
                  style={{ margin: "12px 0" }}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <Button variant="contained" onClick={onSubmit}>
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={props.onClose}
                style={{ marginLeft: "80px" }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default EditSubventionAmount;
