import axios from "axios";
// import { configs } from "../constants";
import { API_ENDPOINT, DOMAIN } from "./../../constants";
import Cookies from "universal-cookie";
import moment from "moment-timezone";

export async function getPgLsKeyAndCost(businessHead) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_pg_lskey_cost?aid=${aid}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getSubSalesGroup(businessHead) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_sub_sales_group?aid=${aid}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getAdvisors(id, businessHead) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_advisors_list?id=${id}&aid=${aid}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function freezeAdvisor(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/freeze_advisor`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function addAdmissionTarget(data, businessHead) {
  const cookies = new Cookies();
  let aid = businessHead || null;

  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/add_admission_target`,
      { data, aid: aid, bId: businessHead },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function addAdvisorProgramMapingTarget(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/add_advisor_target`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getSubSalesGroupTargetRevenue(subSalesGroupId, month) {
  const cookies = new Cookies();
  let aid = cookies.get("aid");
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_sub_sales_revenue_target?subSalesGroupId=${subSalesGroupId}&month=${month}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getOtp(data, businessHead) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/req_sales_target_otp`,
      { aid: aid, data: data, bId: businessHead },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getOtpTimer() {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: DOMAIN, path: "/" });
  try {
    const res = await axios.get(`${API_ENDPOINT}/ops/get_otp_timer?id=${aid}`, {
      headers: {
        "x-access-token": cookies.get("at", {
          domain: DOMAIN,
          path: "/",
        }),
      },
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function verifyOtp(code) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/vrfy_sales_tg_otp`,
      { aid: aid, code },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getPls() {
  const cookies = new Cookies();
  let aid = cookies.get("aid");
  try {
    const res = await axios.get(`${API_ENDPOINT}/ops/get_pls`, {
      headers: {
        "x-access-token": cookies.get("at", {
          domain: DOMAIN,
          path: "/",
        }),
      },
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getSalesProgram() {
  const cookies = new Cookies();
  let aid = cookies.get("aid");
  try {
    const res = await axios.get(`${API_ENDPOINT}/ops/get_sale_pg?aid=${aid}`, {
      headers: {
        "x-access-token": cookies.get("at", {
          domain: DOMAIN,
          path: "/",
        }),
      },
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getLeadCount(
  fromDate,
  toDate,
  program,
  currentPage,
  PER_PAGE,
  businessHead,
  isFirst
) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_leads?aid=${aid}&fromDate=${fromDate}&toDate=${toDate}&program=${encodeURIComponent(
        program
      )}&pageNumber=${currentPage}&limit=${PER_PAGE}&isFirst=${isFirst}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getSalesGroup() {
  const cookies = new Cookies();
  let aid = cookies.get("aid");
  try {
    const res = await axios.get(`${API_ENDPOINT}/ops/get_sales_group`, {
      headers: {
        "x-access-token": cookies.get("at", {
          domain: DOMAIN,
          path: "/",
        }),
      },
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export const getSalesGroupName = (data, id) => {
  // console.log("---sales group convet to name")
  // console.log(data, id)
  // console.log("-------")

  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return `${data[i].salesDisplayName} (${data[i].email})`;
      }
    }
  }
};

export const getSalesGroupEmail = (data, id) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return `${data[i].salesDisplayName} (${data[i].email})`;
      }
    }
  }
};

export const getSubSalesGroupName = (data, id) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return data[i].email;
      }
    }
  }
};

export const getSubSalesGroupEmail = (data, id) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return data[i].email;
      }
    }
  }
};

export async function getAggregateData(
  fromDate,
  toDate,
  businessHead,
  isFirst
) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/aggrgate_analysis?fromDate=${fromDate}&toDate=${toDate}&aid=${aid}&isFirst=${isFirst}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getAdvisorAggregateData(
  fromDate,
  toDate,
  businessHead,
  isFirst
) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/advisor_aggrgate_analysis?fromDate=${fromDate}&toDate=${toDate}&aid=${aid}&isFirst=${isFirst}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getCurrentMonthTarget(month, businessHead) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  let date = month || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_latest_revenue?aid=${aid}&month=${date}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getCurrentMonthAdvisorTarget(subSalesGroup) {
  const cookies = new Cookies();
  let aid = cookies.get("aid");
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_latest_advisor_target?aid=${aid}&subSalesGroup=${subSalesGroup}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export function sortObjectByValues(obj) {
  // Convert object to array of key-value pairs
  const entries = Object.entries(obj);

  // Sort the array based on the values in each nested object
  const sortedEntries = entries.map(([key, value]) => {
    // Convert nested object to array of key-value pairs
    const nestedEntries = Object.entries(value);
    // Sort the nested array based on the values
    const sortedNestedEntries = nestedEntries.sort((a, b) => b[1] - a[1]);
    // Return the sorted nested array
    return [key, Object.fromEntries(sortedNestedEntries)];
  });

  // Construct a new object from the sorted array
  const sortedObj = Object.fromEntries(sortedEntries);

  return sortedObj;
}

export async function removeAdmissionTarget(data, businessHead) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/rm_admission_target`,
      { data, aid: aid, bId: businessHead },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results;
  if (typeof window !== `undefined`) {
    results = regex.exec(window.location.search);
  }
  return !results ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const convertUtcToIst = (utcTime) => {
  return moment.utc(utcTime).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
};

export const convertUnixTimeToIst = (unixTime) => {
  const date = new Date(Number(unixTime));
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");
  let utcTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return moment.utc(utcTime).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
};

export async function getPreviousTargets(businessHead, month) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/previous_target?aid=${aid}&month=${month}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getAdvisorsList() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(`${API_ENDPOINT}/ops/get_all_advisors_list`, {
      headers: {
        "x-access-token": cookies.get("at", {
          domain: DOMAIN,
          path: "/",
        }),
      },
    });

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function getAllSubSalesGroup(data) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/ops/get_all_sub_sales_group?salesGroup=${data}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function rmSalesGroupAndSubSalesGroup(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid");
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/rm_salesGroup_and_subSalesGroup`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function assignSalesAndSubSalesGroup(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid");
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/assign_sales_sub_sales_group`,
      { data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function getEditTargetOtp(data, businessHead) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/req_edit_sales_target_otp`,
      { aid: aid, data: data, bId: businessHead },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function editAdmissionTarget(data, businessHead) {
  const cookies = new Cookies();
  let aid = businessHead || null;
  try {
    return await axios.post(
      `${API_ENDPOINT}/ops/edit_admission_target`,
      { data, aid: aid, bId: businessHead },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}
