import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loader from "./Loader";
import {
  getAdvisorsList,
  convertUtcToIst,
  getSalesGroup,
  getAllSubSalesGroup,
  assignSalesAndSubSalesGroup,
  rmSalesGroupAndSubSalesGroup,
} from "./helper";
import AdvisorSalesAndSubSalesGroupAssign from "./Models/AdvisorSalesAndSubSalesGroupAssign";
function LeadDistributionPanelAdvisors() {
  const [advisorList, setAdvisorList] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState([{ name: "", value: "" }]);
  const [modelShow, setModelShow] = useState(false);
  const [salesGroup, setSalesGroup] = useState("");
  const [subSalesGroup, setSubSalesGroup] = useState("");
  const [lastCheckedOn, setLastCheckedOn] = useState(false);
  const [allCheckInData, setAllCheckInData] = useState("");

  useEffect(() => {
    console.log("in use effect");
    getAdviors()
      .then((data) => {
        console.log(data);
        let advisor = data.data;

        advisor.sort(function (a, b) {
          var emailA = a.email.toLowerCase();
          var emailB = b.email.toLowerCase();

          if (emailA < emailB) {
            return -1;
          } else if (emailA > emailB) {
            return 1;
          } else {
            return 0;
          }
        });

        setAdvisorList(advisor);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    async function getAdviors() {
      console.log("Getting getAdviors");
      setLoading(true);
      return await getAdvisorsList();
    }

    salesGroup().then((data) => {
      setSalesGroup(data.data);
      setLoading(false);
    });

    async function salesGroup() {
      setLoading(true);
      return await getSalesGroup();
    }

    getSubSalesGroupName().then((data) => {
      console.log(data);
      setSubSalesGroup(data.data);
      setLoading(false);
    });

    async function getSubSalesGroupName() {
      setLoading(true);
      return await getAllSubSalesGroup();
    }
  }, []);

  function handlecheckbox(e) {
    let arrayToDo = [];
    if (e.target.checked) {
      if (checkbox && checkbox.length > 0) {
        arrayToDo = [
          ...checkbox,
          { name: e.target.name, value: e.target.value },
        ];
      } else arrayToDo = [e.target.name.trim()];
    } else {
      var index = checkbox
        .map((data) => data.name)
        .indexOf(e.target.name.trim());
      if (index !== -1) {
        checkbox.splice(index, 1);
        arrayToDo = checkbox;
      } else {
        arrayToDo = checkbox;
      }
    }

    setCheckbox([...arrayToDo]);
    return;
  }

  const salesGroupName = (advisorId, salesGroupArray) => {
    if (advisorId && salesGroupArray) {
      for (let i = 0; i < salesGroupArray.length; i++) {
        if (advisorId === salesGroupArray[i]._id) {
          return salesGroupArray[i].email;
        }
      }
    }
  };

  const subSalesGroupName = (advisorId, subSalesGroupArray) => {
    if (advisorId && subSalesGroupArray) {
      for (let i = 0; i < subSalesGroupArray.length; i++) {
        if (advisorId === subSalesGroupArray[i]._id) {
          return subSalesGroupArray[i].email;
        }
      }
    }
  };

  const removeSubSalesAngSalesGroup = async (data) => {
    let ans = window.confirm("Are you sure you want to remove");
    console.log({ ans });
    if (ans) {
      setLoading(true);
      let res = await rmSalesGroupAndSubSalesGroup(data);
      console.log({ res });
      alert(res.data.data);
      setLoading(false);
      window.location.reload();
    }
  };

  const handleModelClose = (event) => {
    setModelShow(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
          color: "#fff",
          background: "rgb(5, 86, 70)",
        }}
      >
        <h4>Lead Distribution Advisors List</h4>
      </div>
      {loading && <Loader />}

      {modelShow && (
        <AdvisorSalesAndSubSalesGroupAssign
          show={modelShow}
          handleClose={handleModelClose}
          data={checkbox}
          salesGroup={salesGroup}
          subSalesGroup={subSalesGroup}
          setModelShow={setModelShow}
        />
      )}

      <div>
        <div>
          <div className="groupmaintablediv" style={{ marginLeft: "30px" }}>
            <div
              className=""
              style={{
                overflow: "scroll",
                width: "100%",
                marginBottom: "100px",
              }}
            >
              <div style={{ width: "1100px" }}>
                <table className="table table-striped groupleadtable">
                  <thead style={{ height: "40px" }}>
                    <tr className="">
                      <th
                        scope="col"
                        style={{ width: "70px", padding: "0px 8px" }}
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        style={{ width: "70px", padding: "0px 8px" }}
                      >
                        Email Id
                      </th>
                      <th
                        scope="col"
                        style={{ width: "120px", padding: "0px 8px" }}
                      >
                        Sales Group
                      </th>
                      <th
                        scope="col"
                        style={{ width: "100px", padding: "0px 8px" }}
                      >
                        Sub Sales Group
                      </th>
                      <th
                        scope="col"
                        style={{ width: "90px", padding: "0px 8px" }}
                      >
                        Is Checked In
                      </th>
                      <th
                        scope="col"
                        style={{ width: "50px", padding: "0px 8px" }}
                      >
                        Last Checked In
                      </th>
                      <th
                        scope="col"
                        style={{ width: "20px", padding: "0px 8px" }}
                      >
                        CheckBox
                      </th>
                      <th
                        scope="col"
                        style={{ width: "50px", padding: "0px 8px" }}
                      >
                        Remove SalesGrop and Sub-Sales Group
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {advisorList &&
                      advisorList.map((data, idx) => {
                        return (
                          <tr className="ld_table_center" key={idx}>
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              <div>{data.name}</div>
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => viewAllCheckInStatus(data)}
                              >
                                {data.email}
                              </div>
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              <div>
                                {salesGroupName(data.salesgroup, salesGroup) ||
                                  "---"}
                              </div>
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              <div>
                                {subSalesGroupName(
                                  data.subSalesgroup,
                                  subSalesGroup
                                ) || "---"}
                              </div>
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              <div>{data.isChecked ? "Yes" : "No"}</div>
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              <div>
                                {convertUtcToIst(data?.lastCheckedOn) || "---"}
                              </div>
                            </th>
                            <th
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                                height: "100%",
                              }}
                            >
                              <div>
                                <input
                                  onClick={handlecheckbox}
                                  style={{ width: "15px" }}
                                  className="check_box"
                                  type="checkbox"
                                  id=""
                                  name="advisor"
                                  value={data._id}
                                />
                              </div>
                            </th>

                            <th>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  data.salesgroup
                                    ? removeSubSalesAngSalesGroup(data)
                                    : ""
                                }
                              >
                                <img
                                  src="https://webcdn.imarticus.org/Demo/material-symbols_delete-outline.svg"
                                  height={25}
                                  width={25}
                                />
                              </div>
                            </th>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              {checkbox && checkbox.length >= 2 ? (
                <div className="floater_edit">
                  <p>{checkbox.length - 1} Advisor Selected</p>
                  <div
                    onClick={() => setModelShow(true)}
                    //   state={{ checkbox }}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="floater_button">
                      <p>Assign Group and Sub Group</p>
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.login.permissions,
  resources: state.login.resources,
});

export default connect(mapStateToProps)(LeadDistributionPanelAdvisors);
