import React from "react";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "../Loader";
import { assignSalesAndSubSalesGroup } from "../helper";

function AdvisorSalesAndSubSalesGroupAssign(props) {
  const [loading, setLoading] = useState(false);
  const [salesGroup, setSalesGroup] = useState();
  const [subSalesGroup, setSubSalesGroup] = useState();
  const [dropdownDataSalesGroup, setDropdownDataSalesGroup] = useState(
    props.salesGroup
  );
  const [dropdownDataSubSalesGroup, setDropdownDataSubSalesGroup] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let d = {
      advisorArray: props.data,
      salesGroupId: salesGroup,
      subSalesGroupId: subSalesGroup,
    };

    let response = await assignSalesAndSubSalesGroup(d);
    if (response.status === 200) {
      alert(response.data.data);
      props.setModelShow(false);
      window.location.reload();
    } else if (response.status === 420) {
      alert(response.data.message);
    } else alert("Something went wrong");
    setLoading(false);
  };

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < props.subSalesGroup.length; i++) {
      if (salesGroup === props.subSalesGroup[i].reporting_manager) {
        temp.push(props.subSalesGroup[i]);
      }
    }
    setDropdownDataSubSalesGroup(temp);
  }, [salesGroup]);

  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="AdvisorSalesAndSubSalesGroupAssign"
      >
        <Modal.Header closeButton className="leadmodelheaderheading">
          {props.show}
        </Modal.Header>

        <div>
          <form onSubmit={submitHandler}>
            <div className="addprogram2container">
              <div>
                <h3 className="addprogramheading">Select Sales Group</h3>
                <div>
                  <select
                    value={salesGroup}
                    onChange={(e) => setSalesGroup(e.target.value)}
                    required
                    className="addadvisorinput"
                    name="cars"
                    id="cars"
                  >
                    <option disabled selected value="">
                      Select Sales Group
                    </option>
                    {dropdownDataSalesGroup &&
                      dropdownDataSalesGroup.map((data) => {
                        return <option value={data._id}>{data.email}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div>
                <h3 className="addprogramheading">Select Sub Sales Group</h3>
                <div>
                  <select
                    value={subSalesGroup}
                    onChange={(e) => setSubSalesGroup(e.target.value)}
                    required
                    className="addadvisorinput"
                    name="cars"
                    id="cars"
                    disabled={!salesGroup ? true : false}
                  >
                    <option disabled selected value="">
                      Select Sub-sales Group
                    </option>
                    {dropdownDataSubSalesGroup &&
                      dropdownDataSubSalesGroup.map((data) => {
                        return <option value={data._id}>{data.email}</option>;
                      })}
                  </select>
                </div>
              </div>
            </div>

            <div className="addprogrambtns">
              <button
                onClick={() => props.setModelShow(false)}
                className="addprogramcancelbtn"
              >
                Cancel
              </button>
              <button
                onClick={() => submitHandler}
                className="addprogramsubmitbtn"
              >
                Done
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AdvisorSalesAndSubSalesGroupAssign;
