import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import {
  getPgLsKeyAndCost,
  getSubSalesGroup,
  addAdmissionTarget,
  getOtp,
  getCurrentMonthTarget,
  sortObjectByValues,
  removeAdmissionTarget,
  getEditTargetOtp,
  editAdmissionTarget,
} from "../helper";
import TargetApprovalModel from "./TargetApprovalModel";

function AddAdmissionTarget(props) {
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState("");
  const [subSalesGroup, setSubSalesGroup] = useState();
  const [programAndCost, setProgramAndCost] = useState();
  const [mainTarget, setMainTarget] = useState(null);
  const [show, setShow] = React.useState(false);
  const [approvalData, setApprovalData] = useState();
  const handleClose = () => setShow(false);
  const [currentTarget, setCurrentTarget] = useState();
  const [disabled, setDisabled] = useState(false);
  const [apiCall, setAPICall] = useState(false);
  const [removalData, setRemovalData] = useState();
  const [removalModel, setRemovalModel] = useState(false);
  const [editBtnClicked, setEditBtnClicked] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editApprovalData, setEditApprovalData] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(month, mainTarget);
    let sortedTarget = sortObjectByValues(mainTarget);
    console.log({ sortedTarget });
    setLoading(true);
    let d = {
      month: month,
      target: sortedTarget,
    };

    console.log(d);

    let otp = await getOtp(d, props.businessHead);
    console.log({ otp });
    if (otp.status === 200) {
      setApprovalData(d);
      setShow(true);
      setLoading(false);
    } else {
      alert("Something went wrong");
      setLoading(false);
    }
  };

  const editSubmitHandler = async (e) => {
    e.preventDefault();
    console.log(month, mainTarget);
    let sortedTarget = sortObjectByValues(mainTarget);
    console.log({ sortedTarget });
    setLoading(true);
    let d = {
      month: month,
      target: sortedTarget,
    };

    console.log(d);
    let otp = await getEditTargetOtp(d, props.businessHead);
    console.log({ otp });
    if (otp.status === 200) {
      setEditApprovalData(d);
      setEditModel(true);
      setLoading(false);
    } else {
      alert("Something went wrong");
      setLoading(false);
    }
  };

  const removeTargetHandler = async (e) => {
    e.preventDefault();
    console.log(month, mainTarget);
    setLoading(true);
    let d = {
      month: month,
      target: mainTarget,
    };

    console.log(d);
    let otp = await getOtp(d, props.businessHead);
    console.log({ otp });
    if (otp.status === 200) {
      setRemovalData(d);
      setRemovalModel(true);
      setLoading(false);
    } else {
      alert("Something went wrong");
      setLoading(false);
    }
  };

  const editHandler = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to Edit the Target")) {
      setEditBtnClicked(true);
      setDisabled(false);
    }
  };

  useEffect(() => {
    GetLatestTarget().then((data) => {
      setAPICall(true);
      setCurrentTarget(data.data.target);
      setMonth(data.data.month);
      setLoading(false);
    });

    async function GetLatestTarget() {
      setLoading(true);
      return await getCurrentMonthTarget("", props.businessHead);
    }
  }, []);

  useEffect(() => {
    console.log({ apiCall });
    console.log({ currentTarget });
    if (apiCall) {
      SalesGroup().then((data) => {
        setSubSalesGroup(data.data);
        GetProgramAndCost().then((data2) => {
          setProgramAndCost(data2.data);
          let tempArr = {};
          console.log({ data2, data });
          for (let i = 0; i < data2.data.length; i++) {
            let temp2 = {};
            for (let j = 0; j < data.data.length; j++) {
              console.log({ currentTarget });
              temp2[data.data[j]._id] =
                (Object.keys(currentTarget).length > 0 &&
                  currentTarget[data2.data[i].leadsquareProgramKey][
                    data.data[j]._id
                  ]) ||
                0;
            }
            tempArr[data2.data[i].leadsquareProgramKey] = temp2;
          }

          if (Object.keys(currentTarget).length != 0) {
            setDisabled(true);
          }

          setMainTarget(tempArr);
          setLoading(false);
        });

        async function GetProgramAndCost() {
          setLoading(true);
          return await getPgLsKeyAndCost(props.businessHead);
        }

        setLoading(false);
      });

      async function SalesGroup() {
        setLoading(true);
        return await getSubSalesGroup(props.businessHead);
      }
    }
  }, [currentTarget]);

  const setTargetForSles = (lskey, salesGroup, e) => {
    console.log(lskey, salesGroup, e.target.value);
    let tempMainTarget = { ...mainTarget };
    tempMainTarget[lskey][salesGroup] = e.target.value;
    setMainTarget(tempMainTarget);
  };

  useEffect(() => {
    console.log({ apiCall });
    console.log({ month });
    if (apiCall) {
      GetLatestTarget().then((data) => {
        setCurrentTarget(data.data.target);
        setMonth(data.data.month);
        setLoading(false);

        if (Object.keys(data.data.target).length <= 0) {
          setDisabled(false);
        }
      });

      async function GetLatestTarget() {
        setLoading(true);
        return await getCurrentMonthTarget(month, props.businessHead);
      }
    }
  }, [month]);

  return (
    <>
      {loading && <Loader />}
      {show && (
        <TargetApprovalModel
          setLoading={setLoading}
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          data={approvalData}
          approvalApi={addAdmissionTarget}
          getOtp={getOtp}
          close={props.close}
          setDisabled={setDisabled}
          businessHead={props.businessHead}
        />
      )}

      {removalModel && (
        <TargetApprovalModel
          setLoading={setLoading}
          show={removalModel}
          setShow={setRemovalModel}
          handleClose={handleClose}
          data={removalData}
          approvalApi={removeAdmissionTarget}
          getOtp={getOtp}
          setDisabled={setDisabled}
          businessHead={props.businessHead}
          // close={props.close}
        />
      )}

      {editModel && (
        <TargetApprovalModel
          setLoading={setLoading}
          show={editModel}
          setShow={setEditModel}
          handleClose={handleClose}
          data={editApprovalData}
          approvalApi={editAdmissionTarget}
          getOtp={getEditTargetOtp}
          setDisabled={setDisabled}
          businessHead={props.businessHead}
          close={props.close}
        />
      )}

      <div>
        <form>
          <div className="addprogram2container">
            <div>
              <p className="addadmissiontargetmonth">Select Month</p>
              <div>
                <input
                  className="addadmissiontargetmonthdropdown"
                  type="month"
                  id="birthday"
                  name="birthday"
                  value={month}
                  required
                  onChange={(e) => setMonth(e.target.value)}
                />
              </div>
            </div>

            {/* table matrix */}
            <div
              className="addadmissiontargettablemain"
              style={{ height: "auto" }}
            >
              <div
                className=""
                style={{ overflow: "scroll", width: "100%", height: "100%" }}
              >
                <div style={{ width: "auto" }}>
                  <table className="table table-striped groupleadtable">
                    <thead>
                      <tr className="">
                        <th
                          scope="col"
                          style={{
                            width: "300px",
                            padding: "0px 8px",
                            height: "48px",
                          }}
                        >
                          Sub Sales Group
                        </th>
                        {programAndCost &&
                          programAndCost.map((data) => {
                            return (
                              <th scope="col" style={{ width: "184px" }}>
                                {data.leadsquareProgramKey}
                              </th>
                            );
                          })}
                      </tr>
                      {subSalesGroup &&
                        subSalesGroup
                          .sort((a, b) => a.email.localeCompare(b.email))
                          .map((data) => {
                            return (
                              <tr style={{ backgroundColor: "#ffff" }}>
                                <th
                                  scope="col"
                                  style={{ width: "300px", textWrap: "norap" }}
                                >
                                  {data.email}
                                </th>
                                {programAndCost &&
                                  programAndCost.map((data2) => {
                                    return (
                                      <td
                                        style={{
                                          borderRight: "1px solid #ddd",
                                        }}
                                      >
                                        <div style={{ padding: "0px 8px" }}>
                                          <div style={{ paddingTop: "8px" }}>
                                            <p style={{ paddingBottom: "4px" }}>
                                              Admission Target
                                            </p>
                                            <input
                                              type="number"
                                              disabled={disabled}
                                              onChange={(e) =>
                                                setTargetForSles(
                                                  data2.leadsquareProgramKey,
                                                  data._id,
                                                  e
                                                )
                                              }
                                              value={
                                                mainTarget &&
                                                mainTarget[
                                                  data2.leadsquareProgramKey
                                                ][data._id]
                                              }
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    );
                                  })}
                              </tr>
                            );
                          })}
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className="addprogrambtns"
            style={{ justifyContent: "space-between" }}
          >
            <p style={{ paddingLeft: "10px" }}>
              Note: Once the target is set for the month, you cannot modify it.
              Please contact Tech Team for any changes to the same.
            </p>

            <div style={{ display: "flex", gap: "16px" }}>
              {currentTarget && Object.keys(currentTarget).length > 0 ? (
                <button
                  onClick={(e) => removeTargetHandler(e)}
                  className="removeTargetBtn"
                >
                  Remove Target
                </button>
              ) : (
                ""
              )}

              {disabled ? (
                <button
                  onClick={(e) => editHandler(e)}
                  className="addprogramsubmitbtn"
                >
                  Edit
                </button>
              ) : editBtnClicked ? (
                <button
                  onClick={(e) => editSubmitHandler(e)}
                  className="addprogramsubmitbtn"
                  disabled={disabled}
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={(e) => submitHandler(e)}
                  className="addprogramsubmitbtn"
                  disabled={disabled}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddAdmissionTarget;
