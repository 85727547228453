export default function reducer(
  state = {
    data: {
      group: {},
      plugins: {},
      ready: false,
      success: false,
      promos: [],
      currentPromo: {},
      spsavedpromo: {},
    },
    fgd_loading: false,
    lp_loading: false,
    loading: false,
    error: null,
    logout: false,
    isCreateReviews: null,
    isUpdateReviews: null,
    isViewReviews: null,
    isDeleteReviews: null,
    isCreatePromo: null,
    isUpdatePromo: null,
    isViewPromo: null,
    isDeletePromo: null,
    isCreateCompaign: null,
    isDeleteCompaign: null,
    isUpdateCompaign: null,
    isViewCompaign: null,
    showOtp: false,
    savedSpecialPromo: false,
  },
  action
) {
  switch (action.type) {
    case "FETCH_GROUP_DETAILS": {
      return { ...state, loading: true };
    }
    case "FETCH_GROUP_DETAILS_REJECTED": {
      return { ...state, loading: false, error: action.payload };
    }
    case "FETCH_GROUP_DETAILS_FULFILLED": {
      return {
        ...state,
        data: {
          ...state.data,
          group: action.payload.data.group,
          plugins: action.payload.data.plugins,
          ready: true,
        },
        loading: false,
      };
    }
    case "CREATE_PROMO": {
      return { ...state, loading: true, savedSpecialPromo: false };
    }
    case "CREATE_PROMO_FULFILLED_LOADING": {
      return {
        ...state,
        data: { ...state.data, success: true },
        loading: false,
      };
    }
    case "CREATE_SPECIAL_PROMO_FULFILLED": {
      var result = action.payload.data.promo_obj;
      if (action.payload.data && action.payload.data.more) {
        result["more"] = action.payload.data.more;
      }

      return {
        ...state,
        data: { ...state.data, success: true, showsavedpromo: true },
        showsavedpromo: true,
        spsavedpromo: result,
        loading: false,
        showOtp: false,
        savedSpecialPromo: true,
      };
    }
    case "CREATE_PROMO_OTP_SENT_SUCCESS": {
      return {
        ...state,
        showOtp: true,
      };
    }
    case "HIDE_OTP_FIELD": {
      return {
        ...state,
        showOtp: false,
      };
    }
    case "CREATE_PROMO_REJECTED": {
      return { ...state, loading: false };
    }
    case "EDIT_PROMO": {
      return { ...state, loading: true };
    }
    case "EDIT_PROMO_FULFILLED": {
      if (
        (action.payload &&
          action.payload.data &&
          action.payload.data.status == true) ||
        (action.payload && !action.payload.data)
      ) {
        return {
          ...state,
          data: { ...state.data, success: true },
          loading: false,
        };
      } else if (
        action.payload &&
        action.payload.data &&
        action.payload.data.status == false
      ) {
        return {
          ...state,
          data: { ...state.data, success: false },
          loading: false,
        };
      }
      return {
        ...state,
        data: { ...state.data, success: true },
        loading: false,
      };
    }
    case "EDIT_PROMO_REJECTED": {
      return { ...state, loading: false };
    }
    case "LIST_PROMO": {
      return { ...state, lp_loading: true };
    }
    case "LIST_SPECIAL_PROMO": {
      return { ...state, lp_loading: true };
    }
    case "DELETE_PROMOCODE_FULFILLED": {
      var _promo_id = action.promo_id ? action.promo_id : "";
      var _promos = state.data && state.data.promos ? state.data.promos : [];
      for (var i = 0; i < _promos.length; i++) {
        if (
          _promos[i] &&
          (_promos[i].id === _promo_id || _promos[i]._id === _promo_id)
        ) {
          _promos.splice(i, 1);
        }
      }
      return {
        ...state,
        data: { ...state.data, promos: _promos },
        lp_loading: false,
      };
    }
    case "DELETE_PROMOCODE_REJECTED": {
      return { ...state, loading: false };
    }
    case "LIST_PROMO_FULFILLED": {
      return {
        ...state,
        data: {
          ...state.data,
          promos: action.payload.data.promo_codes,
          group: action.payload.data.group,
          plugins: action.payload.data.plugins,
        },
        lp_loading: false,
      };
    }
    case "LIST_SPECIALPROMO_FULFILLED": {
      return {
        ...state,
        data: { ...state.data, promos: action.payload.data.promo_codes },
        lp_loading: false,
      };
    }
    case "LIST_PROMO_REJECTED": {
      return { ...state, lp_loading: false };
    }
    case "LIST_SPECIALPROMO_REJECTED": {
      return { ...state, lp_loading: false };
    }
    case "FETCH_PROMO": {
      return { ...state, loading: true };
    }
    case "FETCH_PROMO_FULFILLED": {
      return {
        ...state,
        data: {
          ...state.data,
          plugins: action.payload.data.plugins,
          currentPromo: action.payload.data.promo_obj,
        },
        loading: false,
      };
    }
    case "FETCH_PROMO_REJECTED": {
      return { ...state, loading: false };
    }
    case "RESET_PROMO_STATE": {
      return {
        ...state,
        data: { ...state.data, success: false, ready: false },
        loading: false,
        spsavedpromo: null,
        promo: null,
      };
    }
    case "RESET_GENREALPROMO_STATE": {
      return {
        ...state,
        data: { ...state.data, success: false, ready: false },
        lp_loading: false,
        spsavedpromo: null,
        promo: null,
      };
    }
    case "RESET_SPECIALPROMO_STATE": {
      return {
        ...state,
        data: { ...state.data, success: false },
        showsavedpromo: false,
        spsavedpromo: null,
        promo: null,
        loading: true,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        data: {
          group: {},
          plugins: {},
          ready: false,
          success: false,
          promos: [],
          currentPromo: {},
        },
        fgd_loading: false,
        lp_loading: false,
        loading: false,
        isCreateReviews: null,
        isUpdateReviews: null,
        isViewReviews: null,
        isDeleteReviews: null,
        isCreatePromo: null,
        isUpdatePromo: null,
        isViewPromo: null,
        isDeletePromo: null,
        isCreateCompaign: null,
        isDeleteCompaign: null,
        isUpdateCompaign: null,
        isViewCompaign: null,
      };
    }
    case "SAVE_PERMISSION_VIEW": {
      console.log(action.payload);
      return {
        ...state,
        isView: action.payload.data.isview,
      };
    }
    case "SAVE_PERMISSION_CREATE": {
      console.log(action.payload);
      return {
        ...state,
        isCreate: action.payload.data.iscreate,
      };
    }
    case "SAVE_PERMISSION_UPDATE": {
      console.log(action.payload);
      return {
        ...state,
        isUpdate: action.payload.data.isupdate,
      };
    }
    case "SAVE_PERMISSION_DELETE": {
      console.log(action.payload);
      console.log("here");
      return {
        ...state,
        isDelete: action.payload.data.isdelete,
      };
    }
    case "SAVE_PERMISSION_VIEW_PROMO": {
      console.log(action.payload);
      return {
        ...state,
        isViewPromo: action.payload.data.isview,
      };
    }
    case "SAVE_PERMISSION_CREATE_PROMO": {
      console.log(action.payload);
      return {
        ...state,
        isCreatePromo: action.payload.data.iscreate,
      };
    }
    case "SAVE_PERMISSION_UPDATE_PROMO": {
      console.log(action.payload);
      return {
        ...state,
        isUpdatePromo: action.payload.data.isupdate,
      };
    }
    case "SAVE_PERMISSION_DELETE_PROMO": {
      console.log(action.payload);
      console.log("here");
      return {
        ...state,
        isDeletePromo: action.payload.data.isdelete,
      };
    }
    case "SAVE_PERMISSION_VIEW_REVIEWS": {
      console.log(action.payload);
      return {
        ...state,
        isViewReviews: action.payload.data.isview,
      };
    }
    case "SAVE_PERMISSION_CREATE_REVIEWS": {
      console.log(action.payload);
      return {
        ...state,
        isCreateReviews: action.payload.data.iscreate,
      };
    }
    case "SAVE_PERMISSION_UPDATE_REVIEWS": {
      console.log(action.payload);
      return {
        ...state,
        isUpdateReviews: action.payload.data.isupdate,
      };
    }
    case "SAVE_PERMISSION_DELETE_REVIEWS": {
      console.log(action.payload);
      console.log("here");
      return {
        ...state,
        isDeleteReviews: action.payload.data.isdelete,
      };
    }
    case "SAVE_PERMISSION_VIEW_COMPAIGN": {
      console.log(action.payload);
      return {
        ...state,
        isViewCompaign: action.payload.data.isview,
      };
    }
    case "SAVE_PERMISSION_CREATE_COMPAIGN": {
      console.log(action.payload);
      return {
        ...state,
        isCreateCompaign: action.payload.data.iscreate,
      };
    }
    case "SAVE_PERMISSION_UPDATE_COMPAIGN": {
      console.log(action.payload);
      return {
        ...state,
        isUpdateCompaign: action.payload.data.isupdate,
      };
    }
    case "SAVE_PERMISSION_DELETE_COMPAIGN": {
      console.log(action.payload);
      console.log("here");
      return {
        ...state,
        isDeleteCompaign: action.payload.data.isdelete,
      };
    }
  }
  return state;
}
