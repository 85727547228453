import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";
import { renewAccessToken } from "./loginActions";

const cookies = new Cookies();

export const GET_S3_CREDENTIALS = "GET_S3_CREDENTIALS";
export const GET_S3_CREDENTIALS_FULFILLED = "GET_S3_CREDENTIALS_FULFILLED";
export const GET_S3_CREDENTIALS_REJECTED = "GET_S3_CREDENTIALS_REJECTED";

export const S3_UPLOAD = "S3_UPLOAD";
export const S3_UPLOAD_FULFILLED = "S3_UPLOAD_FULFILLED";
export const S3_UPLOAD_REJECTED = "S3_UPLOAD_REJECTED";
export const S3_UPLOAD_PROGRESS = "S3_UPLOAD_PROGRESS";

export const CLEAN_PROPS = "CLEAN_PROPS";

// axios.interceptors.response.use(
//   function (response) {
//     // Do something with response data
//     console.log(response);
//     return response;
//   },
//   function (error) {
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

export function logout() {
  renewATorLogout(dispatch);
  return function (dispatch) {
    dispatch({ type: "LOGOUT" });
  };
}

export function getS3Credentials(mimetype) {
  console.log(mimetype);
  var getS3CredentialsConfig = {
    method: "GET",
    url: constants.API_ENDPOINT + "/media/webcpolicy-opsmedia",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    params: { mimetype },
    data: { mimetype },
  };
  return function (dispatch) {
    dispatch({ type: "GET_S3_CREDENTIALS" });
    axios(getS3CredentialsConfig)
      .then((response) => {
        dispatch({
          type: "GET_S3_CREDENTIALS_FULFILLED",
          payload: response.data,
        });
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "GET_S3_CREDENTIALS_REJECTED", payload: err });
      });
  };
}

export function s3Upload(bucket, s3Params, file, blob) {
  console.log(bucket, s3Params, file, blob);

  let data = new FormData();
  let fileName = new Date().getTime() + file.name;
  // fileName = encodeURIComponent(fileName.trim())

  data.set("key", fileName);
  data.set("AWSAccessKeyId", s3Params.AWSAccessKeyId);
  data.set("acl", "public-read");
  data.set("Policy", s3Params.s3Policy);
  data.set("Content-Type", file.type);
  data.set("Signature", s3Params.s3Signature);
  data.set("success_action_status", "201");
  data.set("file", blob);

  return function (dispatch) {
    var s3UploadConfig = {
      method: "POST",
      url: "https://" + bucket + ".s3.amazonaws.com",
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progress) {
        console.log(progress);
        dispatch({ type: S3_UPLOAD_PROGRESS, payload: progress });
      },
      data,
    };
    dispatch({ type: S3_UPLOAD });
    let request = axios(s3UploadConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: S3_UPLOAD_FULFILLED,
          payload: {
            uid: file.uid,
            name: fileName,
            status: "done",
            abort: function () {
              console.log("abort");
            },
            url:
              "https://" +
              bucket +
              ".s3.amazonaws.com/" +
              encodeURIComponent(fileName),
          },
        });
      })
      .catch((err) => {
        dispatch({ type: S3_UPLOAD_REJECTED, payload: err });
        console.log(err);
      });

    request.then((completed) => console.log("upload completed"));
  };
}

export function clean() {
  return function (dispatch) {
    dispatch({ type: CLEAN_PROPS });
  };
}
