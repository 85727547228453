import React, { Component, useState, useEffect } from "react";
import "./App.css";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import Sidebar from "../scenes/sidebar/index";
import { clearNotification } from "../actions/notifierActions";
import { logout } from "../actions/loginActions";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, notification } from "antd";
import { removeAuthCookies } from "../actions/general";
const { Sider } = Layout;
const cookies = new Cookies();

// @connect((store) => {
//   return {
//     user : store.login.user,
//     notification : store.notifier.data
//   };
// })

let App = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [api, contextHolder] = notification.useNotification();
  let [collapsed, setCollapsed] = useState(false);

  let toggle = () => {
    setCollapsed((state) => !state);
  };

  let logout = () => {
    console.log("logging out user");
    props.dispatch(logout());
  };

  useEffect(() => {
    if (window.location.href.includes("applicationsreview")) {
      document.getElementById("sidebar").style.display = "none";
    } else {
      const element = document.getElementById("sidebar");
      if (element) {
        element.style.display = "inherit";
      }
    }
    if (
      // props.user.logout ||
      !cookies.get("isLoggedIn")
    ) {
      removeAuthCookies();
      if (
        location.pathname &&
        (location.pathname.includes("/applicationdoc/") ||
          location.pathname.includes("/certificate/"))
      ) {
        navigate(
          "/login" + "?redirect=" + encodeURIComponent(location.pathname)
        );
      } else {
        navigate("/login");
      }
    }
    if (props.notification.type) {
      Notify(
        props.notification.type,
        props.notification.title,
        props.notification.message
      );
      props.dispatch(clearNotification());
    }
  });
  useEffect(() => {
    //console.log({cookies:cookies.get("aid"), user: props.user })
    if (
      props.user.logout
      // !cookies.get("ops_aid") ||
      // !cookies.get("ops_at")
    ) {
      if (
        location.pathname &&
        (location.pathname.includes("/applicationdoc/") ||
          location.pathname.includes("/certificate/"))
      ) {
        navigate(
          "/login" + "?redirect=" + encodeURIComponent(location.pathname)
        );
      } else {
        navigate("/login");
      }
    }
  }, []);

  let Notify = (type, message, desc) => {
    console.log({ type, message, desc });
    // api.info({
    //   message: message,
    //   description: desc,
    //   placement:'topRight',
    // });
    notification[type]({
      message: message,
      description: desc,
    });
  };

  return (
    <div>
      {/* {contextHolder} */}
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          id="sidebar"
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="sm-sc-sidebar"
          style={{ backgroundColor: "#055646", width: 249 }}
        >
          {location.pathname &&
          (location.pathname.includes("/applicationdoc/") ||
            location.pathname.includes("/certificate/")) ? (
            <div className="logo">
              <img src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg" />
            </div>
          ) : (
            <Sidebar id="sidebar" logout={logout} router={props.router} />
          )}
        </Sider>
        <Layout style={{ maxWidth: "calc(100vw - 249px)", marginLeft: "auto" }}>
          <Outlet />
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log({state})
  return {
    user: state.login.user,
    notification: state.notifier.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissions: (permissions, resources) => (dispatch) => {
      return dispatch({
        type: "SET_PERMISSIONS_AND_RESOURCES",
        payload: { permissions, resources },
      });
    },
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default App;
