import { combineReducers } from "redux";

import login from "./loginReducer";
import promo from "./promoReducer";
import notifier from "./notifier";
import cas from "./campaignActions";
import cas1 from "./campaignActionsS1";
import cas2 from "./campaignActionsS2";
import cas3 from "./campaignActionsS3";
import appreview from "./applicationFormReducer";
import editfee from "./editFeeReducer";

export default combineReducers({
  login,
  promo,
  notifier,
  cas,
  cas1,
  cas2,
  cas3,
  appreview,
  editfee,
});
