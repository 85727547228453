import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import {
  fetchApplications,
  requestApplicationRevertOTP,
  verifyApplicationRevertOTP,
  fetchApplicationDetail,
} from "../../actions/applicationFormAction";
import { BTN_APPLICATION_STATUS } from "../../constants";
import callicon from "../../images/call.svg";
import envelopeicon from "../../images/envelope.svg";
import Loader from "../applicationform/Loader";
import { Select } from "antd";
const { Option } = Select;

const APPROVAL_NAMES = [
  "Advisor", //21
  "Sales Head", //22
  "Business Head", //23
  "Finance Head", //24
];

const APPROVAL_TYPES = ["Revert Application"];

const ApprovalModal = (props) => {
  console.log({ props });
  const [approvalType, setApprovalType] = useState(props.approvalType);
  const [headIndex, setHeadIndex] = useState(props.headIndex);
  const [currentPackage, setCurrentPackage] = useState(
    props.packages.filter((pkg) => pkg._id == props.packageId)[0]
  );
  const [head_email, set_head_email] = useState("");
  const [comment, setComment] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpCode, setOtpCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.handleApprovalModalToggle(false);
  };

  const revertApplicationStatus = () => {
    props
      .verifyApplicationRevertOTP({
        app_id: props.user_data.app_id,
        crs_pkg_id: currentPackage._id,
        otp_code: otpCode ? otpCode : undefined,
      })
      .then((res) => {
        if (res !== undefined && res.data && res.data.success) {
          // props.fetchApplications({
          //   crs_pg_id: props.appreview.crs_pg_id._id,
          //   search_val: props.appreview.searchVal,
          //   search_type: props.appreview.searchType,
          //   app_status: props.appreview.app_status
          //     ? props.appreview.app_status.status
          //     : undefined,
          //   appcurrent_page: 1,
          // });
          props.handlModalToggle(false);
        } else {
          alert("Invalid OTP, Please try again");
        }
      });
  };

  const requestApplicationRevertOTP = () => {
    if (!head_email) {
      return alert("Please select a Head Email");
    }
    if (!comment) {
      return alert("Please enter remarks");
    }
    setLoading(true);
    props
      .requestApplicationRevertOTP({
        app_id: props.user_data.app_id,
        crs_pkg_id: props.packageId,
        head_email: head_email,
        comment: comment,
      })
      .then((res) => {
        if (res !== undefined && res.data && res.data.success) {
        }
      });
    setOtpSent(true);
    setLoading(false);
  };

  const handleOtpChange = (otp) => {
    if (!otp) otp = "";
    console.log(otp, otp.replace(/[^0-9]/g, ""));
    otp = otp.replace(/[^0-9]/g, "");
    otp = otp.slice(0, 6);
    setOtpCode(otp);
  };

  return (
    <div>
      <Modal
        title="Are you sure?"
        visible={props.open}
        onCancel={closeModal}
        width="38vw"
        className="applicationmodal"
      >
        <div style={{ padding: 24 }}>
          <Loader loading={loading} />
          <div className="ar-sm-u-name">
            {props.user_data && props.user_data.u_name
              ? props.user_data.u_name
              : null}
          </div>
          <div style={{ marginTop: "8px", color: "#055646" }}>
            <span style={{ padding: "0px 16px 0px 0px" }}>
              UID : {props.appreview.userinfo.uid}
            </span>
          </div>
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <img src={callicon} />
            <span style={{ padding: "0px 16px 0px 8px", color: "#055646" }}>
              {props.appreview.userinfo.phone_no}
            </span>
          </div>
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <img src={envelopeicon} />
            <span style={{ padding: "0px 16px 0px 8px", color: "#055646" }}>
              {props.appreview.userinfo.email[0]} {`(Pegasus Email)`}
            </span>
          </div>
          {props.appreview.userinfo.applicationEmail && (
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <img src={envelopeicon} />
              <span style={{ padding: "0px 16px 0px 8px", color: "#055646" }}>
                {props.appreview.userinfo.applicationEmail}{" "}
                {`(Application Email)`}
              </span>
            </div>
          )}

          {props.otpRequired ? (
            <>
              {" "}
              {!otpSent ? (
                <div style={{ marginTop: "20px" }}>
                  <div className="error-msg">
                    You need to get approval from the{" "}
                    {APPROVAL_NAMES[headIndex]}{" "}
                  </div>
                  <Select
                    style={{ width: "100%" }}
                    className="app-dropdown"
                    value={head_email}
                    onChange={(value) => {
                      set_head_email(value);
                    }}
                    placeholder="Select head"
                  >
                    {currentPackage.scholarship_tiers
                      .at(headIndex)
                      .email.map((email) => {
                        return (
                          <Option key={email} value={email}>
                            {email}
                          </Option>
                        );
                      })}
                  </Select>
                  <div style={{ marginTop: "16px" }}>Remarks</div>
                  <textarea
                    style={{
                      border: "1px solid rgba(33, 42, 57, 0.25)",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      color: "#3c4852",
                      opacity: "0.9",
                      marginBottom: "16px",
                      width: "100%",
                    }}
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  ></textarea>
                </div>
              ) : null}
              {otpSent ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      minWidth: "150px",
                      color: "#3c4852",
                      opacity: "0.9",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    Enter OTP:
                  </div>
                  <div
                    style={{
                      color: "#3c4852",
                      opacity: "0.9",
                      fontWeight: "300",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    <input
                      style={{
                        border: "1px solid rgba(33, 42, 57, 0.25)",
                        borderRadius: "4px",
                        padding: "4px 8px",
                        color: "#3c4852",
                        opacity: "0.9",
                        marginBottom: "16px",
                      }}
                      value={otpCode}
                      onChange={(e) => {
                        handleOtpChange(e.target.value);
                      }}
                    />
                    <div>
                      An OTP is sent to the {APPROVAL_NAMES[headIndex]} (
                      {head_email}
                      ). OTP is valid upto 30 minutes.
                    </div>
                    <div>
                      Didn't recieve any code?
                      <button
                        style={{
                          color: "#035642",
                          background: "transparent",
                          borderRadius: "8px",
                          fontWeight: "600",
                          border: "none",
                          cursor: "pointer",
                          height: "fit-content",
                        }}
                        onClick={() => {
                          if (approvalType == 0) {
                            requestApplicationRevertOTP();
                          }
                        }}
                      >
                        Resend OTP
                      </button>
                    </div>
                    <button
                      className="btn-approve-cand"
                      onClick={() => {
                        if (approvalType == 0) {
                          revertApplicationStatus();
                        }
                      }}
                    >
                      {APPROVAL_TYPES[approvalType]}
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="center-all"
                  style={{
                    alignItems: "flex-end",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <button
                    style={{ marginTop: 0, float: "none" }}
                    className="btn-approve-cand"
                    onClick={() => {
                      if (
                        window.confirm("Are you sure you want to continue?")
                      ) {
                        if (approvalType == 0) {
                          requestApplicationRevertOTP();
                        }
                      }
                    }}
                  >
                    Request OTP
                  </button>
                </div>
              )}
            </>
          ) : (
            <div
              className="center-all"
              style={{
                alignItems: "flex-end",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <button
                style={{ marginTop: 0, float: "none" }}
                className="btn-approve-cand"
                onClick={() => {
                  if (window.confirm("Are you sure you want to continue?")) {
                    if (approvalType == 0) {
                      revertApplicationStatus();
                    }
                  }
                }}
              >
                {APPROVAL_TYPES[approvalType]}
              </button>
            </div>
          )}

          <div></div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appreview: state.appreview,
  };
};

const mapDispatchToProps = {
  requestApplicationRevertOTP,
  fetchApplications,
  verifyApplicationRevertOTP,
  fetchApplicationDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalModal);
