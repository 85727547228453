import { Select } from "antd";
const { Option } = Select;
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "./editregistrationfee.scss";
import currency from "../../currency";

import {
  fetchCourses,
  selectedCourse,
  selectedCoursePackage,
  showErrorNotify,
  fetchFeeStructure,
  requestRegistrationFeeOTP,
  verifyRegistrationFeeOTP,
} from "../../actions/editFeeAction";
import countryCodes from "../../countryCodes";
import Alert from "./alert";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Slider,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "./Loader";

const approvalNames = [
  "Advisor", //21
  "Sales Head", //22
  "Business Head", //23
  "Finance Head", //24
];

let EditRegistrationFee = (props) => {
  const dispatch = useDispatch();
  console.log({ props });
  const [searchParams, setSearchParams] = useSearchParams();

  const [coursePackages, setCoursePackages] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [countryCode, setCountryCode] = useState({
    code: "IN",
    value: "91",
    display: "India (+91)",
  });
  const [course, setCourse] = useState();
  const [coursePackage, setCoursePackage] = useState();
  const [regPrice, setRegPrice] = useState();
  const [otpRequested, setOtpRequested] = useState(false);
  const [otpCode, setOtpCode] = useState();
  const [scholarship_tiers, setScholarship_tiers] = useState([]);
  const [reg_tiers, setReg_tiers] = useState([]);
  const [profile, setProfile] = useState(null);
  const [packageFound, setPackageFound] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [overrideFound, setOverrideFound] = useState({});
  const [editfeereq, setEditfeereq] = useState({});
  const [head_email, set_head_email] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    console.log("props.fetchCourses();");
    props.fetchCourses();
  }, []);

  useEffect(() => {
    if (props.editfee.courses && coursePackage == undefined) {
      let m = searchParams.get("m");
      let ccod = searchParams.get("ccod");
      let crs_pg_id = searchParams.get("crs_pg_id");
      let crs_pkg_id = searchParams.get("crs_pkg_id");
      if (m) handleMobileNumberChange(m);
      if (ccod) {
        const ccodObj = countryCodes.find((cc) => cc.value == ccod);
        setCountryCode(ccodObj);
      }
      if (crs_pg_id) {
        let crs;
        for (let i = 0; i < props.editfee.courses.length; i++) {
          if (props.editfee.courses[i]._id == crs_pg_id) {
            crs = crs_pg_id;
            handleCourseChange("", props.editfee.courses[i]);
            break;
          }
        }
        if (crs && crs_pkg_id) {
          let tempPackages = props.editfee.coursePackages.filter(
            (pkg) => pkg.crs_pg_id == crs
          );
          for (let i = 0; i < tempPackages.length; i++) {
            if (tempPackages[i]._id == crs_pkg_id) {
              handleCoursePackageChange("", tempPackages[i]);
            }
          }
        }
      }
    }
  }, [props.editfee.courses]);

  // useEffect(() => {
  //   if (props.editfee.feestructure) {
  //     handleUpdateFee("_" + (packageFound?.reg_price || 0));
  //   }
  // }, [props.editfee.feestructure]);

  useEffect(() => {
    if (props.editfee.editfeereq != undefined) {
      setOtpRequested(props.editfee.editfeereq);
    }
  }, [props.editfee.editfeereq]);

  const isValidMobile = (num) => {
    num = mobileNumber || num;
    return num && num.length >= 7 && num.length <= 15;
  };
  const validateMobileNum = (num) => {
    const isValid = isValidMobile(num);
    if (!isValid) props.showErrorNotify("Invalid Mobile Number Please Verify");
    return isValid;
  };

  const isValidCourse = (crs) => {
    crs = course || crs;
    return crs != undefined;
  };

  const validateCourse = (crs) => {
    const isValid = isValidCourse(crs);
    if (!isValid) props.showErrorNotify("Please Select a Valid Course");
    return isValid;
  };

  const isValidCoursePackage = (crs_pkg) => {
    crs_pkg = coursePackage || crs_pkg;
    return crs_pkg != undefined;
  };

  const validateCoursePackage = (crs_pkg) => {
    const isValid = isValidCoursePackage(crs_pkg);
    if (!isValid) props.showErrorNotify("Please Select a Valid Currency");
    return isValid;
  };

  const handleCountryCode = (e, val) => {
    setCountryCode(val);
    setOtpRequested(false);
    setRegPrice(undefined);
    handleOtpChange("");
  };

  const handleMobileNumberChange = (num) => {
    if (!num) num = "";
    console.log(num, num.replace(/[^0-9]/g, ""));
    num = num.replace(/[^0-9]/g, "");
    num = num.slice(0, 15);
    setMobileNumber(num);
    setOtpRequested(false);
    setRegPrice(undefined);
    handleOtpChange("");
  };

  const handleCourseChange = (e, val) => {
    console.log({ crs: val });
    // props.selectedCourse(val._id);
    setCourse(val);
    let tempPackages = props.editfee.coursePackages
      ? props.editfee.coursePackages.filter((pkg) => pkg.crs_pg_id == val._id)
      : [];
    setCoursePackages(tempPackages);
    setOtpRequested(false);
    setRegPrice(undefined);
    handleOtpChange("");
  };

  const handleCoursePackageChange = (e, val) => {
    console.log({ crs_pkg: val });
    props.selectedCoursePackage(val._id);
    setCoursePackage(val);
    setRegPrice(val.reg_price);
    setOtpRequested(false);
    setRegPrice(undefined);
    handleOtpChange("");
  };

  const formatDate = (date) => {
    var d = new Date(date);
    return d.toLocaleDateString("en-US", dateOptions);
  };

  const handleUpdateFee = (fee) => {
    if (!coursePackage) return;
    console.log({ coursePackage, fee, "typeof fee": typeof fee });
    let symbol = currency[coursePackage.curr].symbol;
    if (typeof fee == "number") fee = symbol + fee;
    console.log({ fee, symbol });
    if (
      !fee ||
      (typeof fee == "string" && fee.includes(symbol) && fee.length < 2)
    )
      fee = 0;
    else {
      if (fee.includes(symbol)) fee = fee.slice(1);
      console.log({ fee });
      fee = fee.replace(/[^0-9]/g, "");
      fee = fee.replaceAll("^0+(?!$)", "").replace(/^0+/g, "");
      if (coursePackage.reg_price < Number(fee)) return;
    }
    if (!fee) fee = 0;
    fee = symbol + fee;
    console.log({ fee });
    setRegPrice(fee);
  };

  const handleOtpChange = (otp) => {
    if (!otp) otp = "";
    console.log(otp, otp.replace(/[^0-9]/g, ""));
    otp = otp.replace(/[^0-9]/g, "");
    otp = otp.slice(0, 6);
    setOtpCode(otp);
  };

  let dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const validateSearch = async () => {
    if (!validateMobileNum() || !validateCourse() || !validateCoursePackage())
      return;
    console.log({ crs_pkg_id: coursePackage, mobileNumber, countryCode });
    let { success, message, ...data } = await fetchFeeStructure(
      coursePackage._id,
      mobileNumber,
      countryCode.value
    );
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: response.data.message,
          dispatch: dispatch,
        },
      });
      return;
    }
    setPackageFound(data.feestructure.packageFound);
    setRegPrice(
      data.feestructure.amountoverride?.overrides.reg_price ||
        data.feestructure.packageFound.reg_price
    );
    setTransaction(data.feestructure.transaction);
    setOverrideFound(data.feestructure.amountoverride);
    setScholarship_tiers(data.feestructure.packageFound.scholarship_tiers);
    setReg_tiers(data.feestructure.packageFound.reg_tiers);
    setProfile(data.prof);
    console.log({ data });
    navigate(
      `?${new URLSearchParams({
        m: mobileNumber,
        ccod: countryCode.value,
        crs_pg_id: course._id,
        crs_pkg_id: coursePackage._id,
      })}`
    );
  };

  let requestEditReqOTP = async () => {
    let price = regPrice;
    console.log({ regPrice });
    if (transaction?.isRegistrationPaid)
      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: "The student has already paid the registration fees",
          dispatch: dispatch,
        },
      });
    if (!price || price.length < 2) price = 0;
    else if (typeof price == "string") price = Number(price.slice(1));
    // price = Math.min(price, packageFound?.reg_price);
    price = Math.max(price, 0);
    if (price && price < 1)
      return alert(
        `Amount needs to be at least ${currency[coursePackage.curr].symbol}1`
      );
    let maxRegPrice =
      Number(reg_tiers[coursePackage.reg_tiers_approver || 1].end / 100) *
      Number(coursePackage.price);
    console.log({ maxRegPrice });
    if (price > maxRegPrice)
      return alert(
        `Amount needs to be less than ${
          currency[coursePackage.curr].symbol
        }${maxRegPrice}`
      );
    if (!head_email) {
      return alert(
        `Please select ${
          approvalNames[coursePackage.reg_tiers_approver || 1]
        } for approval`
      );
    }
    let { success, message, ...data } = await requestRegistrationFeeOTP(
      course?._id,
      price,
      profile?.aid,
      searchParams.get("crs_pkg_id"),
      head_email
    );
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: response.data.message,
          dispatch: dispatch,
        },
      });
      return;
    }
    setOtpRequested(true);
    setEditfeereq(true);
    dispatch({
      type: "SHOW_NOTIFY",
      payload: {
        type: "success",
        message: "OTP Requested Successfully",
        dispatch: dispatch,
      },
    });
  };

  let verifyEditReqOTP = async () => {
    let otp = otpCode;
    if (!otp) return;
    let { success, message, ...data } = await verifyRegistrationFeeOTP(
      course?._id,
      otp,
      profile?.aid,
      searchParams.get("crs_pkg_id")
    );
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: response.data.message,
          dispatch: dispatch,
        },
      });
      return;
    }
    dispatch({
      type: "SHOW_NOTIFY",
      payload: {
        type: "success",
        message: "OTP Verified Successfully",
        dispatch: dispatch,
      },
    });
    alert("Amount set successfully");
    dispatch({
      type: "VERIFY_REG_FEE_OTP",
      payload: {
        editfeereq: false,
        feestructure: data.feestructure,
      },
    });
    setEditfeereq(false);
    setOtpRequested(false);
    //     dispatch({
    //       type: "VERIFY_REG_FEE_OTP",
    //       payload: {
    //         editfeereq: false,
    //         feestructure: response.data.data.feestructure,
    //       },
    //     });
    //     dispatch({
    //       type: "SHOW_NOTIFY",
    //       payload: {
    //         type: "success",
    //         message: "OTP Verified Successfully",
    //         dispatch: dispatch,
    //       },
    //     });
    handleOtpChange("");
  };

  return (
    <div className="edit-fee">
      <Alert />
      <Loader loading={props.editfee.efloading} />
      <div className="search-container">
        <div className="input-box" style={{ margin: "32px 0" }}>
          <div className="input-label">Country Code</div>
          <Autocomplete
            disablePortal
            disableClearable
            autoHighlight
            onChange={handleCountryCode}
            value={countryCode}
            options={countryCodes || []}
            getOptionLabel={(crs) => {
              return crs.display;
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  className="input-field"
                  style={{ padding: 0 }}
                />
              );
            }}
          />
        </div>
        <div className="input-box">
          <div className="input-label">Mobile Number</div>
          <input
            className="input-field"
            placeholder="Enter Mobile Number"
            onChange={(e) => {
              handleMobileNumberChange(e.target.value);
            }}
            name="mobile"
            value={mobileNumber || ""}
          />
        </div>

        {mobileNumber && (
          <div className="input-box">
            <div className="input-label">Course Name</div>
            <Autocomplete
              disablePortal
              disableClearable
              autoHighlight
              onChange={handleCourseChange}
              value={course}
              options={props.editfee.courses || []}
              getOptionLabel={(crs) => {
                console.log({ crs });
                return crs.name;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="input-field"
                    style={{ padding: 0 }}
                  />
                );
              }}
            />
          </div>
        )}
        {course && (
          <div className="input-box">
            <div className="input-label">Currency</div>
            <Autocomplete
              disablePortal
              disableClearable
              autoHighlight
              onChange={handleCoursePackageChange}
              value={coursePackage}
              options={coursePackages || []}
              getOptionLabel={(crs) => {
                return crs.curr;
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="input-field"
                    style={{ padding: 0 }}
                  />
                );
              }}
            />
          </div>
        )}
        <button
          className={`edit-fee-btn ${
            isValidMobile() && isValidCourse() ? "" : "disabled-btn"
          }`}
          onClick={validateSearch}
        >
          Search
        </button>
      </div>

      {reg_tiers && reg_tiers.length > 0 && profile && (
        <div className="application-info">
          <div className="info-block">
            <div className="info-label">Learner Name:</div>
            <div className="info-data">{profile.nam}</div>
          </div>
          {/* <div className="info-block">
              <div className="info-label">Submision Date:</div>
              <div className="info-data">
                {formatDate(props.editfee.feestructure.updatedAt)}
              </div>
            </div> */}
          {!otpRequested && (
            <div className="info-block">
              {/* {coursePackage?.reg_price && (
                  <div className="info-label">
                    Registration Fee(Max {coursePackage.reg_price}):
                  </div>
                )} */}
              <div className="input-container">
                <FormControl variant="standard">
                  <InputLabel htmlFor="applicationFees">
                    Registration Fees(Max {coursePackage?.regPrice})
                  </InputLabel>
                  <Input
                    id="applicationFees"
                    label="Registration Fees"
                    variant="filled"
                    sx={{ m: 1, width: "25ch" }}
                    value={regPrice || 0}
                    onChange={(e) => {
                      setRegPrice(
                        !isNaN(Number(e.target.value))
                          ? Number(e.target.value)
                          : 0
                      );
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        {currency[coursePackage.curr].symbol}
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* <input
                    className="input-field"
                    value={regPrice?regPrice : ""}
                    onChange={(e) => {
                      setRegPrice(e.target.value);
                    }}
                  /> */}
                <div className="error-msg">
                  You need to get approval to edit reg fees from the{" "}
                  {approvalNames[coursePackage.reg_tiers_approver || 1]}{" "}
                  {/* <b>{scholarship_tiers.at(-1).email}</b> */}
                </div>
                <Select
                  style={{ width: "100%" }}
                  className="app-dropdown"
                  value={head_email}
                  onChange={(value) => {
                    set_head_email(value);
                  }}
                  placeholder="Select head"
                >
                  {reg_tiers
                    .at(coursePackage.reg_tiers_approver || 1)
                    .email.map((email) => {
                      return (
                        <Option key={email} value={email}>
                          {email}
                        </Option>
                      );
                    })}
                </Select>
                <button className="edit-fee-btn" onClick={requestEditReqOTP}>
                  Request Approval
                </button>
              </div>
            </div>
          )}
          {otpRequested && (
            <div className="info-block">
              <div className="info-label">Enter OTP:</div>
              <div className="info-container">
                <input
                  className="input-field2"
                  value={otpCode}
                  onChange={(e) => {
                    handleOtpChange(e.target.value);
                  }}
                />
                <div>
                  An OTP is sent to the{" "}
                  {approvalNames[coursePackage.reg_tiers_approver || 1]} (
                  {head_email}). OTP is valid upto 30 minutes.
                </div>
                <div>
                  Didn't recieve any code?{" "}
                  <button
                    className="edit-fee-btn-2"
                    onClick={requestEditReqOTP}
                  >
                    Resend OTP
                  </button>
                </div>
                <button className="edit-fee-btn" onClick={verifyEditReqOTP}>
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
  selectedCourse,
  selectedCoursePackage,
  showErrorNotify,
  verifyRegistrationFeeOTP,
  // dispatch:dispatch,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRegistrationFee);
