import React, { Component, useState, useEffect } from "react";
import "./CashPayment.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import moment from "moment";
import {
  Layout,
  Popconfirm,
  Menu,
  Breadcrumb,
  // Icon,
  PageHeader,
} from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import logo from "../../images/new-logo.png";
import { logout } from "../../actions/loginActions";
import { countryCode, country_code_with_ISO } from "../../constants";
import countryCodes from "../../countryCodes";
import ApplyPayment from "./applypayment/ApplyPayment";
import StartMposPayment from "./startmpospayment/StartMposPayment";
import PosConfigure from "./PosConfigure/PosConfigure";
import CashPaymentsCollected from "./cashPaymentsCollected/cashPaymentsCollected";
const { SubMenu } = Menu;
import {
  LogoutOutlined,
  UserOutlined,
  SolutionOutlined,
  NotificationOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";
import { fetchUserbyMobile } from "../../actions/general";

const cookies = new Cookies();

let CashPayment = (props) => {
  console.log({ props });
  const navigate = useNavigate();
  const [ccod, setCcod] = useState({
    code: "IN",
    value: "91",
    display: "India (+91)",
  });
  const [warningText, setWarningText] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [userFound, setUserFound] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [applyPaymentModal, setApplyPaymentModal] = useState(false);
  const [mposPaymentModal, setMposPaymentModal] = useState(false);
  const [configPosModal, setConfigPosModal] = useState(false);
  const [myCashPaymentsModal, setMyCashPaymentsModal] = useState(false);
  const [paymentMethodTypeMap, setPaymentMethodTypeMap] = useState();

  let fetchUser = async () => {
    if (!mobile) return;
    setUserFound(null);
    setTransactions([]);
    setLoading(true);
    let { success, message, ...data } = await fetchUserbyMobile({
      ccod: Number(ccod.value),
      mobile,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    let {
      user,
      profile,
      userTransactions: transactionsFound,
      paymentMethodTypeMap,
    } = data;
    setPaymentMethodTypeMap(paymentMethodTypeMap);
    setUserFound({ ...user, name: profile.nam });
    console.log({ transactionsFound });
    transactionsFound = transactionsFound.sort((a, b) => {
      let time1 = new Date(a.updatedAt).getTime();
      let time2 = new Date(b.updatedAt).getTime();
      return time2 - time1;
    });
    console.log({ transactionsFound });
    setTransactions(transactionsFound);
    if (setSelectedTransaction) {
      for (let transaction of transactionsFound) {
        if (transaction?._id == selectedTransaction?._id)
          setSelectedTransaction(transaction);
      }
    }
  };
  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };
  let applyPaymentToggle = () => {
    console.log("applyPaymentToggle invoked::::;");
    setApplyPaymentModal((open) => !open);
    if (applyPaymentModal) fetchUser();
  };
  let myCashPaymentsToggle = () => {
    console.log("myCashPaymentsToggle invoked::::;");
    setMyCashPaymentsModal((open) => !open);
  };
  let configPosToggle = () => {
    console.log("configPosToggle invoked::::;");
    setConfigPosModal((open) => !open);
    if (configPosModal) fetchUser();
  };
  let mposPaymentToggle = () => {
    console.log("mposPaymentToggle invoked::::;");
    setMposPaymentModal((open) => !open);
    if (mposPaymentModal) fetchUser();
  };
  let selectCcod = (ev, ccod) => {
    console.log({ ev, ccod });
    setCcod({ ...ccod });
  };
  useEffect(() => console.log({ transactions }), [transactions]);
  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="cashpayment">
      <h2>Record a cash payment by the student, generate and send Invoice</h2>
      <hr style={{ width: "100%", margin: "-1rem 0 2rem" }} />
      <div className="row" style={{ justifyContent: "space-between" }}>
        <h5>Enter the user's mobile details to identify the account</h5>
        <div>
          <Button
            style={{ marginRight: "24px" }}
            variant="contained"
            onClick={myCashPaymentsToggle}
            color="success"
          >
            My Cash Collected Payments
          </Button>
          <Button variant="contained" onClick={configPosToggle} color="success">
            Add Mpos Device
          </Button>
        </div>
      </div>
      <div className="row">
        <Autocomplete
          disablePortal
          disableClearable
          sx={{ width: 150 }}
          autoHighlight
          value={ccod}
          onChange={selectCcod}
          getOptionLabel={(ccode) => {
            return ccode.display || ccode || "";
          }}
          id="ccodeSelector"
          options={countryCodes}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a country code"
                {...params}
                variant="outlined"
              />
            );
          }}
        />
        <TextField
          id="mobile"
          value={mobile}
          onChange={(e) =>
            e.target.value.length < 15 ? setMobile(e.target.value) : null
          }
          label="Mobile Number"
          variant="outlined"
        />
        {warningText && <p className="warning">{warningText}</p>}
      </div>
      <Button variant="contained" onClick={fetchUser}>
        Fetch User
      </Button>
      {userFound ? (
        <>
          <TextField
            id="username"
            value={userFound.name}
            disabled
            label="User's Name"
            variant="outlined"
          />
        </>
      ) : (
        <p className="warning">
          Please find an existing user to apply payment manually
        </p>
      )}
      {transactions && transactions.length > 0 && (
        <TableContainer className="mainTable" component={Paper}>
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Course Name
                </TableCell>
                <TableCell component="th" scope="row">
                  Transaction Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Type
                </TableCell>
                <TableCell>Date</TableCell>
                {/* <TableCell>Tax (?)</TableCell> */}
                <TableCell>
                  <span>Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <React.Fragment>
              <TableBody>
                {transactions.map(
                  (
                    row,
                    index //name, location, l_date
                  ) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.courseName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row._id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.installmentPlanId
                          ? "Installments"
                          : row.emiPlanId
                          ? "Loan"
                          : "Full Payment"}
                      </TableCell>
                      <TableCell>{getDate(row.createdAt)}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSelectedTransaction(row);
                            setApplyPaymentModal(true);
                          }}
                        >
                          Apply Payment
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setSelectedTransaction(row);
                            setMposPaymentModal(true);
                          }}
                          color="info"
                        >
                          Initiate Ezetap Payment
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </React.Fragment>
          </Table>
        </TableContainer>
      )}

      {applyPaymentModal && (
        <ApplyPayment
          open={applyPaymentModal}
          closeModal={applyPaymentToggle}
          transaction={selectedTransaction}
          user={userFound}
          paymentMethodTypeMap={paymentMethodTypeMap}
        />
      )}
      {myCashPaymentsModal && (
        <CashPaymentsCollected
          open={myCashPaymentsModal}
          closeModal={myCashPaymentsToggle}
        />
      )}
      {configPosModal && (
        <PosConfigure
          open={configPosModal}
          closeModal={configPosToggle}
          user={userFound}
        />
      )}
      {mposPaymentModal && (
        <StartMposPayment
          open={mposPaymentModal}
          closeModal={mposPaymentToggle}
          transaction={selectedTransaction}
          user={userFound}
          refresh={fetchUser}
          paymentMethodTypeMap={paymentMethodTypeMap}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CashPayment);
