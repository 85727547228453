import React, { Component, useEffect } from "react";
import { Card, Breadcrumb, Button } from "antd";
import { connect } from "react-redux";
import {
  getPermissionsUpdate,
  getPermissionsCreate,
  getPermissionsDelete,
  getPermissionsView,
} from "../../actions/promoActions";
import {
  getPermissionsCreatePromo,
  getPermissionsDeletePromo,
  getPermissionsUpdatePromo,
} from "../../actions/promoActions";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

// @connect((store) => {
//   console.log(store);
//   return {
//     promo: store.promo.data,
//     lp_loading : store.promo.lp_loading,
//     prmsn:store.promo,
//   };
// })

let PromocodeClass = (props) => {
  const navigate = useNavigate();
  console.log(props);

  let specialPromocode = () => {
    console.log(props);
    navigate("/promocode/specialpromocode");
  };

  let generalPromocode = () => {
    console.log(props);
    navigate("/promocode/generalpromocode");
  };

  useEffect(() => {
    console.log(props.promo);
    if (props.prmsn.isCreatePromo == null) {
      props.dispatch(getPermissionsUpdatePromo());
      props.dispatch(getPermissionsCreatePromo());
      props.dispatch(getPermissionsDeletePromo());
    }
  }, []);

  const checkVisibility = (resource) => {
    if (!props.permissions) {
      return true;
    } else {
      if (!props.permissions[resource]) {
        return false;
      } else if (props.permissions[resource].indexOf(1) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "12px" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Promocode</Breadcrumb.Item>
      </Breadcrumb>

      <div className="pr-padding-main-cont" style={{ background: "#FFFFFF" }}>
        <center>
          <Card
            className="promocode-home-card"
            title="Create Promocode"
            style={{ maxWidth: 600 }}
          >
            {checkVisibility("30") && (
              <Button
                type="primary"
                style={{
                  backgroundColor: "#055646",
                  color: "#fff",
                  fontFamily: "Archivo Black",
                  marginRight: "8px",
                }}
                className="prmcd-btn"
                onClick={generalPromocode}
              >
                General Promocode
              </Button>
            )}

            <Button
              type="primary"
              style={{
                backgroundColor: "#055646",
                color: "#fff",
                fontFamily: "Archivo Black",
              }}
              className="prmcd-btn"
              onClick={specialPromocode}
            >
              Special Promocode
            </Button>
          </Card>
        </center>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    promo: state.promo.data,
    lp_loading: state.promo.lp_loading,
    prmsn: state.promo,
    permissions: state.login.permissions,
    resources: state.login.resources,
  };
};
export default connect(mapStateToProps)(PromocodeClass);
// export default PromocodeClass;
