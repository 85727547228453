import React, { Component, useState, useEffect } from "react";
import {
  Form,
  notification,
  Divider,
  Card,
  Statistic,
  Input,
  Spin,
  Layout,
  FloatButton,
  Breadcrumb,
  Menu,
  //  Icon,
  Select,
  Button,
  Popconfirm,
} from "antd";
// import { Form} from '@ant-design/compatible';
import { connect } from "react-redux";
import {
  listSpecialPromos,
  resetSpecialPromoState,
  deletePromocode,
  getPermissionsUpdate,
  getPermissionsView,
  getPermissionsCreate,
  getPermissionsDelete,
  getPermissionsCreatePromo,
  getPermissionsDeletePromo,
  getPermissionsUpdatePromo,
  getPermissionsViewPromo,
} from "../../actions/promoActions";
import { countryCode } from "../../constants";
import {
  CloseCircleOutlined,
  PlusOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Countdown = Statistic.Countdown;
const FormItem = Form.Item;
const { Option } = Select;

// @connect((store) => {
//   console.log(store);
//   return {
//     promo: store.promo.data,
//     lp_loading : store.promo.lp_loading,
//     prmsn:store.promo,
//   };
// })

let PromocodeClass = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const [pno, setPnp] = useState(null);

  let add = () => {
    console.log({ props });
    navigate("/promocode/specialpromocode/add");
  };

  let handleNegetiveNumber = (e) => {
    if (e && e.target && e.target.value) {
      e.target.value = Math.floor(e.target.value);
      e.target.value = Math.abs(e.target.value);
    }
  };

  let handleSubmit = (values) => {
    // e.preventDefault();
    // props.form.validateFieldsAndScroll((err, values) => {
    console.log({ values });
    var data = {};
    // if (!err) {
    data["p_no"] = values.pno;
    data["c_code"] = values.c_code;
    props.dispatch(listSpecialPromos(data));
    // }
    // });
  };

  let checkPhoneNumber = (rule, value, callback) => {
    if (isNaN(value) === true) {
      callback("Invalid phone number");
    } else if (value.toString().length === 10) {
      callback();
    } else {
      callback("Phone number must be of 10 digits");
    }
  };

  let copyToClipboard = (e) => {
    var textField = document.createElement("textarea");
    var code = e.target.innerHTML;
    textField.innerText = code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    e.target.focus();
    copiedToClipboardNotify(code);
  };

  let copiedToClipboardNotify = (code) => {
    var msg = code + " Successfully Copied to your Clipboard";
    notification["success"]({
      message: "Successfully Copied",
      description: msg,
    });
  };
  useEffect(() => {
    console.log(props.prmsn);
    if (props.prmsn.isCreatePromo == null) {
      props.dispatch(getPermissionsUpdatePromo());
      props.dispatch(getPermissionsCreatePromo());
      props.dispatch(getPermissionsDeletePromo());
    }
  }, []);

  useEffect(() => {
    props.promo.promos = [];
    props.dispatch(resetSpecialPromoState());
  }, [location]);

  let deletePromocodeFunc = (_promo_id) => {
    console.log("hi");
    props.dispatch(deletePromocode(_promo_id, 2));
  };
  let onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const { SubMenu } = Menu;
  const { Header, Content, Sider } = Layout;
  // const { getFieldDecorator} = props.form;

  console.log(props);

  const countryCodesOption = countryCode.map((c, key) => {
    return (
      <Option value={c} key={key}>
        +{c}
      </Option>
    );
  });
  var specialpromo_arr =
    props && props.promo && props.promo.promos ? props.promo.promos : [];
  const promos = specialpromo_arr.map((promo, id) => {
    var deletePromoMsg = "Do you really want to delete this promocode?";
    var CardTitle = (
      <div className="promocode-head-elem-container">
        <Countdown
          className="CountDownBadge"
          value={promo.expirydate}
          format="HH:mm:ss"
        />
        {props.prmsn.isDeletePromo ? (
          <Popconfirm
            placement="leftTop"
            title={deletePromoMsg}
            onConfirm={() => deletePromocodeFunc(promo.id)}
            okText="Yes"
            cancelText="No"
          >
            {/* <Icon className="remove-icon" type="close" /> */}
            <CloseCircleOutlined />
          </Popconfirm>
        ) : (
          <div></div>
        )}
      </div>
    );
    return (
      <div
        className={["col-md-12", "col-lg-4", "col-sm-12", "col-xs-12"].join(
          " "
        )}
        key={id}
      >
        <div className="special-promocode-container">
          <Card
            className="special-promocode-show-card"
            title={CardTitle}
            bordered={true}
          >
            <div className="center">
              <p className={["specialpromocode-text", promo.status].join(" ")}>
                <span onClick={copyToClipboard}>{promo.code}</span>
              </p>
            </div>
            <p className="special-promocode-detail">
              Phone No: <strong>{promo.p_no}</strong>
            </p>
            <p className="special-promocode-detail">
              Discount: <strong>{promo.value}</strong>
            </p>
            <p className="special-promocode-detail">
              Status: <strong>{promo.status}</strong>
            </p>
            {(promo.apply_type == 2 || promo.apply_type == "2") &&
            promo.course_name ? (
              <div>
                <p className="special-promocode-detail">
                  Type: <strong>Course Specific</strong>
                </p>
                <p className="special-promocode-detail specialpromo-course-name">
                  Course Name: <strong>{promo.course_name}</strong>
                </p>
              </div>
            ) : (
              <p className="special-promocode-detail">
                Type: <strong>All Course</strong>
              </p>
            )}
            <p className="special-promocode-detail">
              Payment Type:{" "}
              <strong>
                {(promo.payment_type == 0 || promo.payment_type == "0") &&
                  "All Payments"}
                {(promo.payment_type == 1 || promo.payment_type == "1") &&
                  "Full Payment"}
                {(promo.payment_type == 2 || promo.payment_type == "2") &&
                  "Installments"}
                {(promo.payment_type == 3 || promo.payment_type == "3") &&
                  "Loan"}
              </strong>
            </p>
          </Card>
        </div>
      </div>
    );
  });
  return (
    // for phone number
    <div className="specialpromocode-main-container">
      <FloatButton.BackTop />
      <Breadcrumb style={{ margin: "12px" }}>
        <Breadcrumb.Item onClick={() => navigate("/")}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate("/promocode")}>
          Promocode
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate("/promocode/specialpromocode")}
        >
          Special Promocode
        </Breadcrumb.Item>
      </Breadcrumb>

      <Content className="content-contriner">
        <Form
          layout="inline"
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          initialValues={{
            c_code: 91,
          }}
        >
          <FormItem
            name="pno"
            rules={[
              { required: true, message: "Please input phone number" },
              {
                validator: checkPhoneNumber,
              },
            ]}
          >
            <Input
              addonBefore={
                <FormItem
                  name="c_code"
                  rules={[
                    { required: true, message: "Please input country code" },
                  ]}
                >
                  <Select style={{ width: 80 }}>{countryCodesOption}</Select>
                </FormItem>
              }
              type="number"
              prefix={
                // <Icon type="phone" style={{ fontSize: 13 }} />
                <PhoneOutlined style={{ fontSize: 13 }} />
              }
              onChange={handleNegetiveNumber}
              placeholder="Enter user phone no."
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              loading={false}
              className="mt_f"
            >
              Get Promocodes
            </Button>
          </FormItem>
        </Form>

        <hr className="myhr" />
        <div className="center">
          {props.prmsn.isCreatePromo ? (
            <Button className="add-promocode-btn" onClick={add}>
              {/* <Icon type="plus" />  */}
              <PlusOutlined />
              Add Promocode
            </Button>
          ) : (
            <Button className="add-promocode-btn">
              No Permission To Add Promocode
            </Button>
          )}
        </div>
        <div className="promos">
          <Spin size="large" spinning={props.lp_loading}>
            <Divider />
            <div
              className={["special-promocode-outer-container", "row"].join(" ")}
            >
              {promos}
            </div>
          </Spin>
        </div>
      </Content>
    </div>
  );
};

// const SpecialPromocode = Form.create()(PromocodeClass)

const mapStateToProps = (state) => {
  console.log(state);
  return {
    promo: state.promo.data,
    lp_loading: state.promo.lp_loading,
    prmsn: state.promo,
  };
};
export default connect(mapStateToProps)(PromocodeClass);
// export default SpecialPromocode;
