import { FETCH_GROUP_INFO,
         FETCH_GROUP_INFO_FULFILLED,
         FETCH_GROUP_INFO_REJECTED,
         FETCH_COURSE_INFO,
         FETCH_COURSE_INFO_FULFILLED,
         FETCH_COURSE_INFO_REJECTED,
        } from "../actions/campaignActionsS1"
export default function reducer(state={
    group:{
      data : {},
      status : "",
      help : "",
    },
    course : {
      data : {},
      status : "",
      help : ""
    },
    plugins : [],
    logout : false,
  }, action) {
    switch (action.type) {
      case FETCH_GROUP_INFO: {
        console.log("fetching group information");
        return {...state,
            group : {
              ...state.group,
              status : "validating"
            }
          }
      }
      case FETCH_GROUP_INFO_FULFILLED: {
        console.log(action.payload);
        return {...state, 
          group : {
            ...state.group,
            data : action.payload,
            status : "success",
            help : ""
          },
          plugins : action.payload.data.plugins
        }
      }
      case FETCH_GROUP_INFO_REJECTED: {
        console.log(action.payload);
        let help = "",err = "";
        if(!action.payload){
            help = "Server Error! Please try again later."
        } else {
          let err = action.payload.response
          if(err && err.status === 449){
             help = action.payload.response.data.message;
          }
        }
        return {...state, 
          group : {
            ...state.group,
            status : "error",
            help : help
          },
          plugins : []
        }
      }
      case FETCH_COURSE_INFO: {
        console.log("fetching course information");
        return{...state,
          course : {
            ...state.course,
            status : "validating"
          }
        }
      }
      case FETCH_COURSE_INFO_FULFILLED: {
        console.log(action.payload);
        return {...state, 
          course : {
            ...state.course,
            data : action.payload,
            status : "success",
            help : ""
          },
          plugins : action.payload.data.plugins
        }
      }
      case FETCH_COURSE_INFO_REJECTED: {
        console.log(action.payload);
        let help = "",err = "";
        if(!action.payload){
            help = "Server Error! Please try again later."
        } else {
          let err = action.payload.response
          if(err && err.status === 449 ){
             help = action.payload.response.data.message;
          }
        }
        return {...state, 
          course : {
            ...state.course,
            status : "error",
            help : help
          }
        }
      }
      case "LOGOUT":{
        return{...state,
          data: {
            group : {},
            plugins : {},
            ready : false,
            success : false,
            promos : [],
            currentPromo : {}
          },
          fgd_loading: false,
          lp_loading : false,
          loading : false,
        }
      }
    }
    return state
}
