import React, { Component, useState, useEffect } from "react";
import "./PartnerCollectedAllocation.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Cookies from "universal-cookie";
const Cookies = require("universal-cookie");
import momentjs from "moment-timezone";
import moment from "moment";
import {
  Layout,
  Popconfirm,
  Menu,
  Breadcrumb,
  // Icon,
  // PageHeader,
} from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Icon,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
// import logo from "../../images/new-logo.png";
import { logout } from "../../actions/loginActions";
import { countryCode, country_code_with_ISO } from "../../constants";
import countryCodes from "../../countryCodes";
import ApplyPayment from "./applypayment/ApplyPayment";
import AddPayment from "./addpayment/AddPayment";
const { SubMenu } = Menu;
import {
  LogoutOutlined,
  UserOutlined,
  SolutionOutlined,
  NotificationOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";
import {
  fetchUserbyMobile,
  startFetchCourse,
  getUnallocatedPartnerTransactions,
} from "../../actions/general";

const cookies = new Cookies();

let PartnerCollectedAllocation = (props: object) => {
  console.log({ props });
  const navigate = useNavigate();
  const [warningText, setWarningText] = useState("");
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState<any | null>(null);
  const [bankTransactions, setBankTransactions] = useState<
    {
      _id: string;
      courseName: string;
      installment_trxn_ids: string[];
      createdAt: string;
    }[]
  >([]);
  const [selectedTransaction, setSelectedTransaction] = useState<object | null>(
    null
  );
  const [allocatePaymentModal, setAllocatePaymentModal] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    fetchCourses();
    fetchUnallocatedTransactions();
  }, []);
  // useEffect(() => {
  //   fetchUnallocatedTransactions();
  // }, [page, rowsPerPage]);
  useEffect(() => {
    if (selectedTransaction) setAllocatePaymentModal(true);
  }, [selectedTransaction]);
  let fetchCourses = async () => {
    setLoading(true);
    let { success, message, ...data } = await startFetchCourse();
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    let { courses: coursesTemp } = data;
    coursesTemp = coursesTemp.filter((el) => el.paymentCollectionType == 2);
    console.log({ coursesTemp });
    setCourses(coursesTemp);
  };
  let fetchUnallocatedTransactions = async () => {
    setLoading(true);
    let { success, message, ...data } = await getUnallocatedPartnerTransactions(
      {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      }
    );
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    let { transactions } = data;
    setBankTransactions(transactions);
  };
  let getDate = (date: string) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };
  let addNewPaymentToggle = () => {
    console.log("addNewPaymentToggle invoked::::;");
    setAddPaymentModal((open) => !open);
    if (addPaymentModal) fetchUnallocatedTransactions();
  };
  let allocatePaymentToggle = () => {
    console.log("allocatePaymentToggle invoked::::;");
    setAllocatePaymentModal((open) => !open);
    if (allocatePaymentModal) fetchUnallocatedTransactions();
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="partnercollectedallocation">
      <div className="row">
        <h3>Undeposited Partner Collected Funds</h3>
        <Button
          variant="contained"
          color="success"
          size="medium"
          disabled={courses.length == 0}
          onClick={addNewPaymentToggle}
        >
          <span className="material-icons"> add </span>
          &nbsp;Create&nbsp;Payment
        </Button>
      </div>
      <h6>For reference number please contact finance office</h6>
      <hr style={{ width: "100%", margin: "-1rem 0 2rem" }} />
      {/* <div className="row">
        <Autocomplete
          disablePortal
          disableClearable
          fullWidth
          autoHighlight
          value={selectedCourse}
          onChange={(event, selectedCourse) => {
            setSelectedCourse({ ...selectedCourse });
          }}
          getOptionLabel={(selectedCourse) => {
            return selectedCourse.name;
          }}
          id="courseSelectorModal"
          options={courses}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a Course"
                {...params}
                // onChange={(event, selectedCourse) => {
                //   setSelectedCourse({ ...selectedCourse });
                // }}
                variant="outlined"
              />
            );
          }}
        />

        {warningText && <p className="warning">{warningText}</p>}
      </div> */}

      <TableContainer className="mainTable" component={Paper}>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row">
                Date
              </TableCell>
              <TableCell component="th" scope="row">
                Invoice Number
              </TableCell>
              <TableCell component="th" scope="row">
                Total Amount
              </TableCell>
              <TableCell>Unallocated Amount</TableCell>
              {/* <TableCell>Tax (?)</TableCell> */}
              <TableCell>
                <span>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : bankTransactions?.length > 0 ? (
            <React.Fragment>
              <TableBody>
                {/* {bankTransactions.map( */}
                {bankTransactions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(
                    (
                      row: any,
                      index //name, location, l_date
                    ) => (
                      <TableRow key={row["_id"]}>
                        <TableCell component="th" scope="row">
                          {getDate(row["createdAt"])}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["zoho_invoice_number"]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["amount"]}
                        </TableCell>
                        <TableCell>
                          {row["amount"] - row.alreadyUsedAmount}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setSelectedTransaction(row);
                              setAllocatePaymentModal(true);
                            }}
                          >
                            Allocate Payment
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </React.Fragment>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                  align="center"
                >
                  <span style={{ textAlign: "center", fontSize: "18spanx" }}>
                    No entries fetched.
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 30, 50]}
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          colSpan={8}
        />
      </TableContainer>

      {allocatePaymentModal && (
        <ApplyPayment
          open={allocatePaymentModal}
          onClose={allocatePaymentToggle}
          closeModal={allocatePaymentToggle}
          transaction={selectedTransaction}
        />
      )}
      {addPaymentModal && (
        <AddPayment open={addPaymentModal} closeModal={addNewPaymentToggle} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  courses: state.courses,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerCollectedAllocation);
