import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";
import { renewAccessToken } from "./loginActions";

const cookies = new Cookies();

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

const initiateLoading = (dispatch) => {
  return dispatch({ type: "INITITATE_EF_LOADING" });
};

const stopLoading = (dispatch) => {
  return dispatch({ type: "STOP_EF_LOADING" });
};

export const showErrorNotify = (err_msg) => (dispatch) => {
  return dispatch({
    type: "SHOW_NOTIFY",
    payload: {
      type: "error",
      message: err_msg,
      dispatch: dispatch,
    },
  });
};

export const selectedCourse = (crs_pg_id) => (dispatch) => {
  dispatch({
    type: "SELECTED_COURSE",
    payload: {
      crs_pg_id: crs_pg_id,
    },
  });
};

export const selectedCoursePackage = (crs_pkg_id) => (dispatch) => {
  dispatch({
    type: "SELECTED_COURSE_PACKAGE",
    payload: {
      crs_pkg_id: crs_pkg_id,
    },
  });
};

export const fetchCourses = () => (dispatch) => {
  console.log("props.fetchCourses();");
  var configs = {
    method: "GET",
    url: constants.API_ENDPOINT + "/ops/get_all_courses",
    headers: {
      "x-access-token": cookies.get("at"),
    },
  };
  initiateLoading(dispatch);
  hideAlert(dispatch);
  axios(configs)
    .then((response) => {
      console.log(response);
      stopLoading(dispatch);
      console.log("dispatching:: UPDATE_COURSES");
      dispatch({
        type: "UPDATE_COURSES",
        payload: {
          courses: response.data.data.courses,
          coursePackages: response.data.data.coursePackages,
          locations: response.data.data.locations,
        },
      });
    })
    .catch((err) => {
      stopLoading(dispatch);
      if (err.response && err.response.status == 498) {
        renewATorLogout(dispatch);
        dispatch({ type: "LOGOUT" });
        return;
      }
      if (err.response && err.response.status == 403) {
        alert("You are not authorized in this panel");
        window.location.href = "/";
      }
      var err_msg = "Something went wrong";
      if (err.response && err.response.data && err.response.data.message) {
        err_msg = err.response.data.message;
      }

      return dispatch({
        type: "SHOW_NOTIFY",
        payload: {
          type: "error",
          message: err_msg,
          dispatch: dispatch,
        },
      });
    });
};

export const fetchFeeStructure = (crs_pkg_id, mobile_num, countryCode) =>
  new Promise((resolve, reject) => {
    var configs = {
      method: "POST",
      url: constants.API_ENDPOINT + "/ops/fetch_fee_structure",
      headers: {
        "x-access-token": cookies.get("at"),
      },
      data: {
        crs_pkg_id,
        mobile_num,
        country_code: countryCode,
      },
    };
    let statusCode;
    // hideAlert(dispatch);
    // initiateLoading(dispatch);
    axios(configs)
      .then((res) => {
        console.log({ res });
        if (!res) {
          return reject(res);
        }
        console.log({ data: res.data });
        statusCode = res.status;
        // stopLoading(dispatch);
        if (res.data.success) {
          console.log("fetchFeeStructure data!!", res.data);
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 403) {
          alert("You are not authorized in this panel");
          window.location.href = "/";
        }
        var err_msg = "Something went wrong";
        if (err.response && err.response.data && err.response.data.message) {
          err_msg = err.response.data.message;
        }
        // stopLoading(dispatch);
        // return dispatch({
        //   type: "SHOW_NOTIFY",
        //   payload: {
        //     type: "error",
        //     message: err_msg,
        //     dispatch: dispatch,
        //   },
        // });
        return reject(err);
      });
    // .then((response) => {
    //   console.log(response);
    // if (response.status == 200) {
    //   dispatch({
    //     type: "INDIVIDUAL_FEE_STRUCTURE",
    //     payload: {
    //       feestructure: response.data.data.feestructure,
    //       prof: response.data.data.prof,
    //       editfeereq: false,
    //     },
    //   });
    // } else {
    //   dispatch({
    //     type: "SHOW_NOTIFY",
    //     payload: {
    //       type: "error",
    //       message: response.data.message,
    //       dispatch: dispatch,
    //     },
    //   });
    // }
    // stopLoading(dispatch);
    // })
    // .catch((err) => {
    //   console.log({ err });
    //   if (err.response && err.response.status == 498) {
    //     renewATorLogout(dispatch);
    //     dispatch({ type: "LOGOUT" });
    //     return;
    //   }
    //   if (err.response && err.response.status == 403) {
    //     alert("You are not authorized in this panel");
    //     window.location.href = "/";
    //   }
    //   var err_msg = "Something went wrong";
    //   if (err.response && err.response.data && err.response.data.message) {
    //     err_msg = err.response.data.message;
    //   }
    //   stopLoading(dispatch);
    //   return dispatch({
    //     type: "SHOW_NOTIFY",
    //     payload: {
    //       type: "error",
    //       message: err_msg,
    //       dispatch: dispatch,
    //     },
    //   });
    // });
  });

export const requestRegistrationFeeOTP = (
  crs_pg_id,
  reg_price,
  aid,
  crs_pkg_id,
  head_email
) =>
  new Promise((resolve, reject) => {
    var configs = {
      method: "POST",
      url: constants.API_ENDPOINT + "/ops/req_reg_fee_edit",
      headers: {
        "x-access-token": cookies.get("at"),
      },
      data: {
        crs_pg_id,
        reg_price,
        aid,
        crs_pkg_id,
        head_email,
      },
    };
    let statusCode;
    // hideAlert(dispatch);
    // initiateLoading(dispatch);
    axios(configs)
      .then((res) => {
        console.log({ res });
        if (!res) {
          return reject(res);
        }
        console.log({ data: res.data });
        statusCode = res.status;
        if (res.data.success) {
          console.log("requestRegistrationFeeOTP data!!", res.data);
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 403) {
          alert("You are not authorized in this panel");
          window.location.href = "/";
        }
        var err_msg = "Something went wrong";
        if (err.response && err.response.data && err.response.data.message) {
          err_msg = err.response.data.message;
        }
        return reject(err);
      });
    //     .then((response) => {
    //       console.log(response);
    //       if (response.status == 200) {
    //         dispatch({
    //           type: "REQUEST_REG_FEE_OTP",
    //           payload: {
    //             editfeereq: true,
    //           },
    //         });
    //         dispatch({
    //           type: "SHOW_NOTIFY",
    //           payload: {
    //             type: "success",
    //             message: "OTP Requested Successfully",
    //             dispatch: dispatch,
    //           },
    //         });
    //       } else {
    //         dispatch({
    //           type: "SHOW_NOTIFY",
    //           payload: {
    //             type: "error",
    //             message: response.data.message,
    //             dispatch: dispatch,
    //           },
    //         });
    //       }
    //       stopLoading(dispatch);
    //     })
    //     .catch((err) => {
    //       console.log({ err });
    //       stopLoading(dispatch);
    //       if (err.response && err.response.status == 498) {
    //         renewATorLogout(dispatch);
    //         dispatch({ type: "LOGOUT" });
    //         return;
    //       }
    //       if (err.response && err.response.status == 403) {
    //         alert("You are not authorized in this panel");
    //         window.location.href = "/";
    //       }
    //       var err_msg = "Something went wrong";
    //       if (err.response && err.response.data && err.response.data.message) {
    //         err_msg = err.response.data.message;
    //       }
    //       return dispatch({
    //         type: "SHOW_NOTIFY",
    //         payload: {
    //           type: "error",
    //           message: err_msg,
    //           dispatch: dispatch,
    //         },
    //       });
    //     });
  });

export const verifyRegistrationFeeOTP = (crs_pg_id, otp_code, aid, pkg_id) =>
  new Promise((resolve, reject) => {
    var configs = {
      method: "POST",
      url: constants.API_ENDPOINT + "/ops/verify_reg_fee_edit",
      headers: {
        "x-access-token": cookies.get("at"),
      },
      data: {
        crs_pg_id,
        otp_code,
        aid,
        pkg_id,
      },
    };
    // hideAlert(dispatch);
    // initiateLoading(dispatch);
    axios(configs)
      .then((res) => {
        console.log({ res });
        if (!res) {
          return reject(res);
        }
        console.log({ data: res.data });
        let statusCode = res.status;
        if (res.data.success) {
          console.log("requestRegistrationFeeOTP data!!", res.data);
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 403) {
          alert("You are not authorized in this panel");
          window.location.href = "/";
        }
        var err_msg = "Something went wrong";
        if (err.response && err.response.data && err.response.data.message) {
          err_msg = err.response.data.message;
        }
        return reject(err);
      });
    // .then((response) => {
    //   console.log(response);
    //   if (response.status == 200) {
    //     dispatch({
    //       type: "VERIFY_REG_FEE_OTP",
    //       payload: {
    //         editfeereq: false,
    //         feestructure: response.data.data.feestructure,
    //       },
    //     });
    //     dispatch({
    //       type: "SHOW_NOTIFY",
    //       payload: {
    //         type: "success",
    //         message: "OTP Verified Successfully",
    //         dispatch: dispatch,
    //       },
    //     });
    //   } else {
    //     dispatch({
    //       type: "SHOW_NOTIFY",
    //       payload: {
    //         type: "error",
    //         message: response.data.message,
    //         dispatch: dispatch,
    //       },
    //     });
    //   }
    //   stopLoading(dispatch);
    // })
    // .catch((err) => {
    //   console.log({ err });
    //   stopLoading(dispatch);
    //   if (err.response && err.response.status == 498) {
    //     renewATorLogout(dispatch);
    //     dispatch({ type: "LOGOUT" });
    //     return;
    //   }
    //   if (err.response && err.response.status == 403) {
    //     alert("You are not authorized in this panel");
    //     window.location.href = "/";
    //   }
    //   var err_msg = "Something went wrong";
    //   if (err.response && err.response.data && err.response.data.message) {
    //     err_msg = err.response.data.message;
    //   }
    //   return dispatch({
    //     type: "SHOW_NOTIFY",
    //     payload: {
    //       type: "error",
    //       message: err_msg,
    //       dispatch: dispatch,
    //     },
    //   });
    // });
  });

export const requestApplicationFeeOTP = (
  crs_pg_id,
  app_fees,
  aid,
  crs_pkg_id,
  head_email
) =>
  new Promise((resolve, reject) => {
    var configs = {
      method: "POST",
      url: constants.API_ENDPOINT + "/ops/req_app_fee_edit",
      headers: {
        "x-access-token": cookies.get("at"),
      },
      data: {
        crs_pg_id,
        app_fees,
        aid,
        crs_pkg_id,
        head_email,
      },
    };
    // hideAlert(dispatch);
    // initiateLoading(dispatch);
    axios(configs)
      .then((res) => {
        console.log({ res });
        if (!res) {
          return reject(res);
        }
        console.log({ data: res.data });
        let statusCode = res.status;
        if (res.data.success) {
          console.log("requestApplicationFeeOTP data!!", res.data);
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 403) {
          alert("You are not authorized in this panel");
          window.location.href = "/";
        }
        var err_msg = "Something went wrong";
        if (err.response && err.response.data && err.response.data.message) {
          err_msg = err.response.data.message;
        }
        return reject(err);
      });
    // .then((response) => {
    //   console.log(response);
    //   if (response.status == 200) {
    //     dispatch({
    //       type: "REQUEST_APP_FEE_OTP",
    //       payload: {
    //         editfeereq: true,
    //       },
    //     });
    //     dispatch({
    //       type: "SHOW_NOTIFY",
    //       payload: {
    //         type: "success",
    //         message: "OTP Requested Successfully",
    //         dispatch: dispatch,
    //       },
    //     });
    //   } else {
    //     dispatch({
    //       type: "SHOW_NOTIFY",
    //       payload: {
    //         type: "error",
    //         message: response.data.message,
    //         dispatch: dispatch,
    //       },
    //     });
    //   }
    //   stopLoading(dispatch);
    // })
    // .catch((err) => {
    //   console.log({ err });
    //   stopLoading(dispatch);
    //   if (err.response && err.response.status == 498) {
    //     renewATorLogout(dispatch);
    //     dispatch({ type: "LOGOUT" });
    //     return;
    //   }
    //   if (err.response && err.response.status == 403) {
    //     alert("You are not authorized in this panel");
    //     window.location.href = "/";
    //   }
    //   var err_msg = "Something went wrong";
    //   if (err.response && err.response.data && err.response.data.message) {
    //     err_msg = err.response.data.message;
    //   }
    //   return dispatch({
    //     type: "SHOW_NOTIFY",
    //     payload: {
    //       type: "error",
    //       message: err_msg,
    //       dispatch: dispatch,
    //     },
    //   });
    // });
  });

export const verifyApplicationFeeOTP = (crs_pg_id, otp_code, aid, pkg_id) =>
  new Promise((resolve, reject) => {
    var configs = {
      method: "POST",
      url: constants.API_ENDPOINT + "/ops/verify_app_fee_edit",
      headers: {
        "x-access-token": cookies.get("at"),
      },
      data: {
        crs_pg_id,
        otp_code,
        aid,
        pkg_id,
      },
    };
    // hideAlert(dispatch);
    // initiateLoading(dispatch);
    axios(configs).then((res) => {
      console.log({ res });
      if (!res) {
        return reject(res);
      }
      console.log({ data: res.data });
      let statusCode = res.status;
      if (res.data.success) {
        console.log("verifyApplicationFeeOTP data!!", res.data);
        return resolve({
          ...res.data.data,
          status: statusCode,
          success: true,
        });
      } else {
        console.log("API call failed!", res.data);
        return resolve({ ...res.data, status: statusCode });
      }
    });
    // .catch((err) => {
    //   console.log(err)
    //   if (err.response && err.response.status == 403) {
    //     alert("You are not authorized in this panel");
    //     window.location.href = "/";
    //   }
    //   var err_msg = "Something went wrong";
    //   if (err.response && err.response.data && err.response.data.message) {
    //     err_msg = err.response.data.message;
    //   }
    //   return reject(err)
    // })
    //   .then((response) => {
    //     console.log(response);
    //     if (response.status == 200) {
    //       dispatch({
    //         type: "VERIFY_APP_FEE_OTP",
    //         payload: {
    //           editfeereq: false,
    //           feestructure: response.data.data.feestructure,
    //         },
    //       });
    //       dispatch({
    //         type: "SHOW_NOTIFY",
    //         payload: {
    //           type: "success",
    //           message: "OTP Verified Successfully",
    //           dispatch: dispatch,
    //         },
    //       });
    //     } else {
    //       dispatch({
    //         type: "SHOW_NOTIFY",
    //         payload: {
    //           type: "error",
    //           message: response.data.message,
    //           dispatch: dispatch,
    //         },
    //       });
    //     }
    //     stopLoading(dispatch);
    //   })
    //   .catch((err) => {
    //     console.log({ err });
    //     stopLoading(dispatch);
    //     if (err.response && err.response.status == 498) {
    //       renewATorLogout(dispatch);
    //       dispatch({ type: "LOGOUT" });
    //       return;
    //     }
    //     if (err.response && err.response.status == 403) {
    //       alert("You are not authorized in this panel");
    //       window.location.href = "/";
    //     }
    //     var err_msg = "Something went wrong";
    //     if (err.response && err.response.data && err.response.data.message) {
    //       err_msg = err.response.data.message;
    //     }
    //     return dispatch({
    //       type: "SHOW_NOTIFY",
    //       payload: {
    //         type: "error",
    //         message: err_msg,
    //         dispatch: dispatch,
    //       },
    //     });
    //   });
  });

export const hideAlert = (dispatch) => {
  dispatch({
    type: "HIDE_NOTIFY",
    payload: {},
  });
};
