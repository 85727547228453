import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";
import { renewAccessToken } from "./loginActions";

const cookies = new Cookies();

export const FETCH_GROUP_INFO = "FETCH_GROUP_INFO";
export const FETCH_GROUP_INFO_FULFILLED = "FETCH_GROUP_INFO_FULFILLED";
export const FETCH_GROUP_INFO_REJECTED = "FETCH_GROUP_INFO_REJECTED";

export const FETCH_COURSE_INFO = "FETCH_COURSE_INFO";
export const FETCH_COURSE_INFO_FULFILLED = "FETCH_COURSE_INFO_FULFILLED";
export const FETCH_COURSE_INFO_REJECTED = "FETCH_COURSE_INFO_REJECTED";

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

export function logout() {
  renewATorLogout(dispatch);
  return function (dispatch) {
    dispatch({ type: "LOGOUT" });
  };
}

export function fetchGroupInfo(gcode) {
  var fetchGroupInfoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/grp_info",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      gcode,
    },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_GROUP_INFO" });
    axios(fetchGroupInfoConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "FETCH_GROUP_INFO_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "FETCH_GROUP_INFO_REJECTED", payload: err });
      });
  };
}

export function fetchCourseInfo(u_tag) {
  var fetchCourseInfoConfig = {
    method: "POST",
    url: constants.API_ENDPOINT + "/ops/crs_info",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: {
      u_tag,
    },
  };
  return function (dispatch) {
    dispatch({ type: FETCH_COURSE_INFO });
    axios(fetchCourseInfoConfig)
      .then((response) => {
        console.log(response);
        dispatch({ type: FETCH_COURSE_INFO_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: FETCH_COURSE_INFO_REJECTED, payload: err });
      });
  };
}
