import axios from "axios";
import Cookies from "universal-cookie";
import * as constants from "../constants";
import { renewAccessToken } from "./loginActions";

const cookies = new Cookies();

export const FETCH_QUIZ_CATEGORIES = "FETCH_QUIZ_CATEGORIES";
export const FETCH_QUIZ_CATEGORIES_FULFILLED =
  "FETCH_QUIZ_CATEGORIES_FULFILLED";
export const FETCH_QUIZ_CATEGORIES_REJECTED = "FETCH_QUIZ_CATEGORIES_REJECTED";

export const FETCH_QUIZ_TESTS = "FETCH_QUIZ_TESTS";
export const FETCH_QUIZ_TESTS_FULFILLED = "FETCH_QUIZ_TESTS_FULFILLED";
export const FETCH_QUIZ_TESTS_REJECTED = "FETCH_QUIZ_TESTS_REJECTED";

export const FETCH_VIDEO_CATEGORIES = "FETCH_VIDEO_CATEGORIES";
export const FETCH_VIDEO_CATEGORIES_FULFILLED =
  "FETCH_VIDEO_CATEGORIES_FULFILLED";
export const FETCH_VIDEO_CATEGORIES_REJECTED =
  "FETCH_VIDEO_CATEGORIES_REJECTED";

export const FETCH_VIDEO_TOPICS = "FETCH_VIDEO_TOPICS";
export const FETCH_VIDEO_TOPICS_FULFILLED = "FETCH_VIDEO_TOPICS_FULFILLED";
export const FETCH_VIDEO_TOPICS_REJECTED = "FETCH_VIDEO_TOPICS_REJECTED";

export const GET_S3_CREDENTIALS = "GET_S3_CREDENTIALS";
export const GET_S3_CREDENTIALS_FULFILLED = "GET_S3_CREDENTIALS_FULFILLED";
export const GET_S3_CREDENTIALS_REJECTED = "GET_S3_CREDENTIALS_REJECTED";

const renewATorLogout = (dispatch) => {
  return dispatch(renewAccessToken());
};

export function logout() {
  renewATorLogout(dispatch);
  return function (dispatch) {
    dispatch({ type: "LOGOUT" });
  };
}

export function fetchQuizCategories(gcode, pl_id) {
  var fetchQuizCategoriesConfig = {
    method: "POST",
    url: constants.QUIZ_API_ENDPOINT + "/ops/grp_catg",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { gcode, pl_id },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_QUIZ_CATEGORIES" });
    axios(fetchQuizCategoriesConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "FETCH_QUIZ_CATEGORIES_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({
          type: "FETCH_QUIZ_CATEGORIES_REJECTED",
          payload: err.response,
        });
      });
  };
}

export function fetchQuizTests(catg_id) {
  var fetchQuizCategoriesConfig = {
    method: "POST",
    url: constants.QUIZ_API_ENDPOINT + "/ops/catg_test",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { catg_id },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_QUIZ_TESTS" });
    axios(fetchQuizCategoriesConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "FETCH_QUIZ_TESTS_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({ type: "FETCH_QUIZ_TESTS_REJECTED", payload: err.response });
      });
  };
}

export function fetchVideoCategories(g_id, pl_id) {
  var fetchVideoCategoriesConfig = {
    method: "POST",
    url: constants.VIDEO_API_ENDPOINT + "/ops/grp_chap",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { g_id, pl_id },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_VIDEO_CATEGORIES" });
    axios(fetchVideoCategoriesConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "FETCH_VIDEO_CATEGORIES_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({
          type: "FETCH_VIDEO_CATEGORIES_REJECTED",
          payload: err.response,
        });
      });
  };
}

export function fetchVideoTopics(g_id, pl_id, chap_id) {
  var fetchVideoTopicsConfig = {
    method: "POST",
    url: constants.VIDEO_API_ENDPOINT + "/ops/chap_tpc",
    headers: {
      "x-access-token": cookies.get("at"),
    },
    data: { g_id, pl_id, chap_id },
  };
  return function (dispatch) {
    dispatch({ type: "FETCH_VIDEO_TOPICS" });
    axios(fetchVideoTopicsConfig)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "FETCH_VIDEO_TOPICS_FULFILLED",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status == 498) {
          renewATorLogout(dispatch);
          dispatch({ type: "LOGOUT" });
          return;
        }
        dispatch({
          type: "FETCH_VIDEO_TOPICS_REJECTED",
          payload: err.response,
        });
      });
  };
}
