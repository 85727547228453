import React from "react";
import { Modal } from "react-bootstrap";
import AddAdmissionTarget from "./AddAdmissionTarget";
import ManageAdvisors from "./ManageAdvisor";
import ViewProgramAllocation from "./ViewProgramAllocation";

function LeadDistributionModel(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="leaddistributionmodel"
      >
        <Modal.Header
          closeButton
          className="leadmodelheaderheading"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {props.show.String}

          <button
            onClick={props.handleClose}
            style={{ marginLeft: "auto", fontSize: "18px" }}
          >
            x
          </button>
        </Modal.Header>

        {props.show.String == "Add Admission Targets" && (
          <AddAdmissionTarget
            close={props.handleClose}
            businessHead={props.businessHead}
          />
        )}

        {props.show.String == "Manage Advisors" && (
          <ManageAdvisors
            close={props.handleClose}
            businessHead={props.businessHead}
          />
        )}

        {props.show.String == "View Program Allocation" && (
          <ViewProgramAllocation
            close={props.handleClose}
            businessHead={props.businessHead}
          />
        )}
      </Modal>
    </>
  );
}

export default LeadDistributionModel;
