import { GET_S3_CREDENTIALS,   
         GET_S3_CREDENTIALS_FULFILLED,   
         GET_S3_CREDENTIALS_REJECTED,
         S3_UPLOAD,
         S3_UPLOAD_PROGRESS,
         S3_UPLOAD_FULFILLED,
         S3_UPLOAD_REJECTED,
         CLEAN_PROPS,   
        } from "../actions/campaignActionsS3"
export default function reducer(state={
    status : {
      uploadReady:false,
      uploading : false,
      progress : {},
    },
    file : {},
    s3 : {},
    logout : false,
  }, action) {
    switch (action.type) {
      case GET_S3_CREDENTIALS : {
        return state
      }
      case GET_S3_CREDENTIALS_FULFILLED : {
        console.log(action.payload);
        return {
          ...state,
          s3 : action.payload.data,
          status : {
            ...state.status,
            uploadReady : true
          } 
        }
      }
      case GET_S3_CREDENTIALS_REJECTED : {
        console.log(action.payload);
        return {
          ...state,
          s3 : {}
        }
      }
      case S3_UPLOAD : {
        console.log(action.payload);
        return {
          ...state,
          status : {
            ...state.status,
            uploading : true,
            uploadReady : false
          }
        }
      }
      case S3_UPLOAD_FULFILLED : {
        console.log(action.payload);
        return {
          ...state,
          status : {
            ...state.status,
            uploading : false,
          },
          file : action.payload
        }
      }
      case S3_UPLOAD_REJECTED : {
        console.log(action.payload);
        return {
          ...state,
          status : {
            ...state.status,
            uploading : false,
          }
        }
      }
      case S3_UPLOAD_PROGRESS : {
        console.log(action.payload);
        return {
          ...state,
          status : {
            ...state.status,
            progress : action.payload
          }
        }
      }
      case CLEAN_PROPS : {
        return {
          ...state,
          s3 : {},
          file : {},
          status : {},
        }
      }
      case "LOGOUT":{
        return {
          uploadReady:false,
          s3 : {},
          logout : true,
        }
      }
    }
    return state
}
