import "./CreateCustomerPreferences.scss";
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import countryCodes from "../../countryCodes";
import { PAYMENT_ENDPOINT } from "../../constants";
import {
  fetchUserbyMobile,
  checkAndCreateUser,
  getCourses,
} from "../../actions/general";
import { display } from "@mui/system";

let payModes = [
  "PayTm",
  "RazorPay",
  "Paypal",
  "Net Banking",
  "Card",
  "Cash",
  "Other",
];

let CreateCustomerPreferences = (props: {}) => {
  const [ccod, setCcod] = useState({
    code: "IN",
    value: "91",
    display: "India (+91)",
  });
  const [warningText, setWarningText] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [aid, setAid] = useState("");
  const [emailFetched, setEmailFetched] = useState(false);
  const [paidCourses, setPaidCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    (async function () {
      var { success, message, ...data } = await getCourses();
      if (!success) {
        if (message) {
          console.log({ message });
        } else {
          console.log("Something Went Wrong. Please try again later.");
        }
      } else {
        console.log({ ...data });
        // if (data['courses']) emptyProps['courses'] = data['courses']
        setPaidCourses(data["courses"]);
      }
    })();
  }, []);
  let fetchUser = async () => {
    setName("");
    setAid("");
    setEmail("");
    setSelectedCourse(null);
    setEmailFetched(false);
    setLoading(true);
    setWarningText("");
    let { success, message, ...data } = await fetchUserbyMobile({
      ccod: Number(ccod.value),
      mobile,
    });
    setLoading(false);
    console.log({ success, message });
    if (!success) {
      if (message) {
        window.alert(message);
        setWarningText(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    let { profile, user } = data;
    if (profile) {
      setName(profile.nam);
    }
    if (user) {
      setAid(user._id);
      setEmail(user.ep || user.e[0]);
    }
    setEmailFetched(true);
  };
  let selectCcod = (
    // ev: React.SyntheticEvent<Element, Event>,
    // ccod: { code: string; value: string; display: string }
    ev,
    ccod
  ) => {
    console.log({ ev, ccod });
    setCcod({ ...ccod });
  };
  let createUser = async () => {
    if (!aid && !(ccod && mobile && name && email)) {
      return alert("Please specify all details!");
    }
    setLoading(true);
    let { success, message, ...data } = await checkAndCreateUser({
      aid,
      ccod: ccod.value,
      mobile,
      email,
      name,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("User Account Created Successfully!");
    fetchUser();
  };
  let redirectToSetPreferencesPage = () => {
    let url = `${PAYMENT_ENDPOINT}/student_preferences/?c_cod=${ccod?.value}&aid=${aid}`;
    if (selectedCourse?._id) url += `&crs_pg_id=${selectedCourse._id}`;
    console.log({ url });
    window.open(url);
  };
  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="mainContainer">
      <h1>Set User's Payment Preferences</h1>
      <span>Please provide the following details:</span>
      <div className="row">
        <Autocomplete
          multiple={false}
          disablePortal
          disableClearable
          sx={{ width: 150 }}
          autoHighlight
          value={ccod}
          onChange={selectCcod}
          getOptionLabel={(ccode) => {
            // if (typeof ccode == 'string') return ccode
            return ccode?.display || "";
          }}
          id="ccodeSelector"
          options={countryCodes}
          renderInput={(params) => {
            return (
              <TextField
                label="Select a country code"
                {...params}
                // onChange={selectCcod}
                variant="outlined"
              />
            );
          }}
        />
        <TextField
          id="mobile"
          value={mobile}
          onChange={(e) =>
            e.target.value.length < 15 ? setMobile(e.target.value) : null
          }
          label="Mobile Number"
          variant="outlined"
        />
      </div>
      {warningText && <span className="warning">{warningText}</span>}
      <Button variant="outlined" color="info" onClick={fetchUser}>
        Check User
      </Button>
      {emailFetched ? (
        <>
          <TextField
            id="email"
            value={email}
            disabled={aid ? true : false}
            onChange={(e) => (!aid ? setEmail(e.target.value) : null)}
            label="User's Email Id"
            variant="outlined"
          />
          <TextField
            id="name"
            value={name}
            disabled={aid ? true : false}
            onChange={(e) => (!aid ? setName(e.target.value.trim()) : null)}
            label="User's Name"
            variant="outlined"
          />
          {aid ? (
            <>
              <Autocomplete
                multiple={false}
                disablePortal
                disableClearable
                sx={{ width: 350 }}
                autoHighlight
                value={selectedCourse}
                onChange={(ev, course) => setSelectedCourse(course)}
                getOptionLabel={(course) => {
                  return course?.name || "";
                }}
                id="courseSelector"
                options={paidCourses}
                renderInput={(params) => {
                  return (
                    <TextField
                      label="Select a Course"
                      {...params}
                      // onChange={selectCcod}
                      variant="outlined"
                    />
                  );
                }}
              />
              <Button
                variant="contained"
                color="success"
                onClick={redirectToSetPreferencesPage}
                disabled={!selectedCourse}
              >
                Set Preferences
              </Button>
            </>
          ) : (
            <Button variant="contained" color="success" onClick={createUser}>
              Create User
            </Button>
          )}
        </>
      ) : null}
    </div>
  );
};

export default CreateCustomerPreferences;
