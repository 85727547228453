import React, { Component, useState, useEffect } from "react";
import "./AddTokenPayment.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchNextCustomerPaymentAmount,
  getManagerOtp,
  applyCustomerCashPayment,
  addStudentTokenNumber,
} from "../../../actions/general";
import {
  fetchCourses,
  fetchFeeStructure,
} from "../../../actions/editFeeAction";

const style = {
  // position: "absolute",
  // top: "10%",
  // left: "20%",
  // transform: "translate(0%, -8%)",
  // width: "60%",
  // height: "40%",
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  // p: 4,
  position: "absolute",
  top: "20%",
  left: "25%",
  transform: "translate(0%, -8%)",
  maxWidth: "70%",
  height: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const OPS_LEVEL_ONE = 21,
  OPS_LEVEL_TWO = 22,
  OPS_LEVEL_THREE = 23,
  OPS_LEVEL_FOUR = 24;
let AddTokenPayment = ({ open, closeModal, ...props }) => {
  console.log({ props });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [paymentsCreated, setPaymentsCreated] = useState([]);

  useEffect(() => {
    console.log("componentDidMount");
  }, []);
  let addStudentToken = async () => {
    console.log("addStudentToken");
    setLoading(true);
    let { success, message, ...data } = await addStudentTokenNumber({
      tokenId,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Success! The payment has been recorded.");
    setTokenId("");
    setPaymentsCreated((arr) => {
      arr.push(data.paymentCreated);
      return arr;
    });
    // return closeModal();
  };
  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };
  let convertPriceToLocalString = function (amount) {
    return parseFloat(String(amount)).toLocaleString("hi");
  };

  return (
    <Modal
      open={open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="AddTokenPayment">
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row">
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Add new Token payment
              </Typography>
            </div>

            <TextField
              id="outlined-basic"
              value={tokenId}
              onChange={(e) => setTokenId(e.target.value)}
              label="Token Transaction Id"
              variant="outlined"
            />
            {paymentsCreated && paymentsCreated.length > 0 && (
              <TableContainer className="mainTable" component={Paper}>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Course Name
                      </TableCell>
                      <TableCell>Student Name</TableCell>
                      <TableCell component="th" scope="row">
                        Amount
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Transaction Id
                      </TableCell>
                      <TableCell>Date</TableCell>
                      {/* <TableCell>Tax (?)</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <React.Fragment>
                    <TableBody>
                      {paymentsCreated.map(
                        (
                          row,
                          index //name, location, l_date
                        ) => (
                          <TableRow key={row._id}>
                            <TableCell component="th" scope="row">
                              {row.courseName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {`${row.currency} ${convertPriceToLocalString(
                                row.amount
                              )}`}
                            </TableCell>
                            <TableCell> {row.reference_number}</TableCell>
                            <TableCell>{getDate(row.date)}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </React.Fragment>
                </Table>
              </TableContainer>
            )}
            <div className="buttonRow">
              <Button
                variant="contained"
                onClick={addStudentToken}
                disableRipple
                color="primary"
                disabled={!tokenId}
              >
                {" "}
                Add{" "}
              </Button>
              <Button
                variant="contained"
                onClick={closeModal}
                disableRipple
                color="secondary"
              >
                {" "}
                Close{" "}
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTokenPayment);
